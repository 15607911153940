import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import {
  Card,
  CardHeader,
  Container,
  Grid,
  Typography,
  IconButton,
  CardMedia,
  CardContent,
  Button,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import Page from "../components/Page.js";
import Permisos from "../utils/Permisos.js";
import { AlertaModal, AlertaToast } from "../components/AlertaSwall.js";
import { Titulo } from "src/components/Titulo";
import { db, storage } from "../utils/firebaseInit.js";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
} from "firebase/firestore";
import { ID_PADEL } from "src/utils/urlBack_End.js";
import {
  Delete as DeleteIcon,
  Newspaper as NewspaperIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { Stack } from "@mui/system";
import { ValidarPermisos } from "src/components/ValidarPermisos.js";
import DialogCrearNoticia from "src/components/noticias/DialogCrearNoticia.js";
import { deleteObject, ref } from "firebase/storage";

export default function Noticias() {
  const [openCrear, setOpenCrear] = useState(false);

  const [noticias, setNoticias] = useState([]);
  const [isLoadingNoticias, setIsLoadingNoticias] = useState(false);

  useEffect(() => {
    setIsLoadingNoticias(true);
    const q = query(collection(db, `padel/${ID_PADEL}/noticias`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const fecha = new Date(data.fecha.toDate().toISOString());
        cities.push({
          id: doc.id,
          ...data,
          fecha: `${fecha.getDate()}/${
            fecha.getMonth() + 1
          }/${fecha.getFullYear()}`,
        });
      });
      setNoticias(cities);
      setIsLoadingNoticias(false);
    });
    return () => unsubscribe();
  }, []);

  const eliminarNoticia = (e) => {
    AlertaModal({
      title: "Eliminar noticia",
      confirmButtonText: "Eliminar",
    })
      .fire()
      .then((response) => {
        if (response.isConfirmed) {
          try {
            const fileRef = ref(storage, e.img.src);
            deleteObject(fileRef)
              .then(() => {
                deleteDoc(doc(db, `padel/${ID_PADEL}/noticias`, e.id))
                  .then(() => {
                    AlertaToast({
                      action: "actualizada",
                      element: "noticia",
                    }).fire();
                  })
                  .catch(() => {
                    AlertaToast({
                      action: "Error en el servidor",
                      fullText: true,
                      type: "error",
                    }).fire();
                  });
              })
              .catch((error) => {
                deleteDoc(doc(db, `padel/${ID_PADEL}/noticias`, e.id))
                  .then(() => {
                    AlertaToast({
                      action: "actualizado",
                      element: "noticia",
                    }).fire();
                  })
                  .catch(() => {
                    AlertaToast({
                      action: "Error en el servidor",
                      fullText: true,
                      type: "error",
                    }).fire();
                  });
              });
          } catch {
            AlertaToast({
              action: "Error en el servidor",
              fullText: true,
              type: "error",
            }).fire();
          }
        }
      });
  };

  return (
    <Page title="Padel Club Rengo | Noticias">
      <Container>
        <Titulo>
          <Typography variant="h4">Noticias</Typography>
        </Titulo>

        <Stack direction={"row-reverse"} padding="20px">
          <ValidarPermisos permission={Permisos.agregarNoticias}>
            <Button
              onClick={() => {
                setOpenCrear(true);
              }}
              variant="contained"
            >
              <AddIcon /> Agregar Noticia
            </Button>
          </ValidarPermisos>
        </Stack>

        <Stack padding="20px">
          <Alert severity="info">
            Solo se pueden agregar o eliminar noticias.
          </Alert>
        </Stack>

        {!isLoadingNoticias && noticias ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {noticias.length >= 1 ? (
                noticias.map((e, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <Card sx={{ maxWidth: 345, height: 400 }}>
                      <ValidarPermisos permission={Permisos.eliminarNoticias}>
                        <Stack direction={"row-reverse"}>
                          <IconButton
                            onClick={() => {
                              eliminarNoticia(e);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      </ValidarPermisos>
                      <CardHeader
                        avatar={<NewspaperIcon />}
                        title={e.titulo}
                        subheader={e.fecha}
                      />
                      <br></br>
                      <CardMedia
                        component="img"
                        height="194"
                        image={e.img.src}
                        alt={e.img.alt}
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          {`${String(e.cuerpo[0]).slice(0, 100)} ...`}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Stack
                  alignContent="center"
                  justifyItems="center"
                  justifyContent="center"
                  mt={10}
                  ml="40%"
                >
                  <Typography gutterBottom align="center" variant="subtitle1">
                    Sin resultados
                  </Typography>
                  <Typography variant="body2" align="center">
                    No se han encontrado elementos.
                  </Typography>
                </Stack>
              )}
            </Grid>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" mt={10}>
            {isLoadingNoticias && (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </Box>
        )}
        {openCrear && (
          <DialogCrearNoticia open={openCrear} setOpen={setOpenCrear} />
        )}
      </Container>
    </Page>
  );
}
