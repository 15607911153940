import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BotonAgregar from "../tabla/BotonAgregar";
import DialogAgregarPermisos from "./dialog/DialogAgregarPermisos";
import Permisos from "src/utils/Permisos";
import Tabla from "../tabla/Tabla";
import { AlertaModal } from "../AlertaSwall";
import { ValidarPermisos } from "src/components/ValidarPermisos";
import { splitCamelCase } from "src/utils/converter";
import { Skeleton, Stack } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { updatePermisosUsuarios } from "src/reducers/authReducers";

export const PermisosUsuarios = (props) => {
  const [openAgregar, setOpenAgregar] = useState(false);
  const { selectedUser } = props;

  const dispatch = useDispatch();

  const [permisos, setPermisos] = useState([]);

  const eliminarPermiso = (idsPermisos) => {
    AlertaModal({
      title: "eliminar este permiso",
      confirmButtonText: "Sí, eliminar",
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          addOrDeletePermisos(
            permisos.filter((item) => item !== permisos[idsPermisos])
          );
          dispatch(
            updatePermisosUsuarios(
              permisos.filter((item) => item !== permisos[idsPermisos])
            )
          );
        }
      });
  };

  useEffect(() => {
    if (
      selectedUser &&
      selectedUser.permisos &&
      Array.isArray(selectedUser.permisos)
    ) {
      const permisosAux = selectedUser.permisos;
      setPermisos(permisosAux);
    }
  }, [selectedUser]);

  const addOrDeletePermisos = async (permisos) => {
    await updateDoc(doc(db, "usuarios", selectedUser.id), {
      permisos: permisos,
    });
  };

  return (
    <>
      <ValidarPermisos permission={Permisos.visualizarPermisos}>
        {permisos ? (
          <Stack marginTop={5} spacing={2}>
            <Tabla
              encabezados={[
                {
                  id: "nombre",
                  label: "Nombre",
                  numeric: false,
                  disablePadding: false,
                },
                { id: "", label: "Acciones" },
              ]}
              buscarPor={["nombre"]}
              data={permisos.map((p, idx) => ({
                id: idx,
                nombre: splitCamelCase(p),
              }))}
              eliminarElemento={eliminarPermiso}
              permisosEliminar={Permisos.eliminarPermisos}
              etiquetaBusqueda={"Buscar permisos"}
              botones={[
                <BotonAgregar
                  onClick={() => setOpenAgregar(true)}
                  permission={Permisos.editarPermisos}
                />,
              ]}
            />
          </Stack>
        ) : (
          <Skeleton variant="rectangular" width="100%" height="500px" />
        )}
      </ValidarPermisos>
      {openAgregar && (
        <DialogAgregarPermisos
          open={openAgregar}
          setOpen={setOpenAgregar}
          permisos={permisos}
          addOrDeletePermisos={addOrDeletePermisos}
        />
      )}
    </>
  );
};
