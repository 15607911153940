import { createSlice } from "@reduxjs/toolkit";
import { auth } from "src/utils/firebaseInit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    id: "",
    rol: "",
    permisos: [],
    user: {},
  },
  reducers: {
    login: (state, param) => {
      const { payload } = param;
      state.user = payload.user;
    },
    logout: (state) => {
      auth.signOut().then(() => {
        window.location.reload();
      });
    },
    updatePermisosUsuarios: (state, param) => {
      const { payload } = param;
      state.user = { ...state.user, permisos: payload };
    },
  },
});

export const { login, logout, updatePermisosUsuarios } = authSlice.actions;
export default authSlice.reducer;
