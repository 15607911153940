import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const conveniosApi = createApi({
  reducerPath: "conveniosApi",
  baseQuery: baseQuery(`${API_SERVER}/convenios`),
  tagTypes: ["conveniosData"],
  endpoints: (builder) => ({
    indexConvenios: builder.query({
      query: ({ id }) => ({ url: `/${id}` }),
    }),
    firmarConvenio: builder.mutation({
      query: (body) => ({
        url: `/firmar-convenio`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyIndexConveniosQuery,
  useIndexConveniosQuery,
  useFirmarConvenioMutation,
} = conveniosApi;
