import { experimentalStyled as styled } from "@mui/material/styles";
import {
  Typography,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { useState } from "react";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import { usuariosApi } from "src/services/usuariosApi";
import { LoadingButton } from "@mui/lab";
import { formatAsCLP } from "src/utils/formatNumber";
import dayjs from "dayjs";
import Cookies from "universal-cookie";
import { getnetApi } from "src/services/getnetApi";
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

export default function Pagos() {
  const cookies = new Cookies();
  const [pagoPendiente, setPagoPendiente] = useState([]);
  const [correo, setCorreo] = useState("");

  const urlHost = `${window.location.protocol}//${window.location.host}`;

  const [sendCodePayment, { isLoading: isLoadingSendCodePayment }] =
    usuariosApi.useSendCodePaymentMutation();

  const [validateCodePayment, { isLoading: isLoadingValidateCodePayment }] =
    usuariosApi.useValidateCodePaymentMutation();

  const [codigoEnviado, setCodigoEnviado] = useState(false);

  const [codigo, setCodigo] = useState("");

  const [createSessionGetnet] = getnetApi.useCreateMembresiaMutation();

  const enviarCodigo = () => {
    if (correo === "") {
      AlertaToast({
        action: "Se debe ingresar un correo.",
        fullText: true,
        type: "error",
      }).fire();
    } else {
      AlertaModal({
        title: "enviar el código",
        confirmButtonText: "Sí, enviar",
      })
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            sendCodePayment({
              email: correo,
            })
              .unwrap()
              .then((data) => {
                setCodigoEnviado(true);
                AlertaToast({
                  action: data.message,
                  fullText: true,
                }).fire();
              })
              .catch(() => {
                AlertaToast({
                  action: "Error al enviar el código. Vuelva a intentar",
                  fullText: true,
                  type: "error",
                }).fire();
              });
          }
        });
    }
  };

  const validarCodigo = () => {
    if (correo === "" || codigo === "") {
      AlertaToast({
        action: "Se debe ingresar un correo y código.",
        fullText: true,
        type: "error",
      }).fire();
    } else {
      AlertaModal({
        title: "válidar el código",
        confirmButtonText: "Sí, válidar",
      })
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            validateCodePayment({
              email: correo,
              code: Number(codigo),
            })
              .unwrap()
              .then((data) => {
                setPagoPendiente(data.pagosPendientes);
                AlertaToast({
                  action: "Código válidado con éxito",
                  fullText: true,
                }).fire();
              })
              .catch(() => {
                AlertaToast({
                  action: "Error al enviar el código. Vuelva a intentar",
                  fullText: true,
                  type: "error",
                }).fire();
              });
          }
        });
    }
  };

  return (
    <RootStyle title="Padel Club Rengo">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Bienvenid@
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}></Stack>
          <Stack spacing={2}>
            {pagoPendiente && pagoPendiente.length >= 1 ? (
              <Stack spacing={2}>
                <Typography variant="h4" fontWeight="bold" mb={2}>
                  Deuda Pendiente
                </Typography>
                {pagoPendiente.map((e) => {
                  return (
                    <Card>
                      <CardContent>
                        <Stack spacing={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>
                              <Typography variant="body1" color="textSecondary">
                                Estado:
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <Typography variant="body1" fontWeight="medium">
                                {e.estado}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>
                              <Typography variant="body1" color="textSecondary">
                                Inicio Período:
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <Typography variant="body1" fontWeight="medium">
                                {dayjs(e.fechaInicioPeriodo).format(
                                  "DD/MM/YYYY"
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>
                              <Typography variant="body1" color="textSecondary">
                                Fin Período:
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <Typography variant="body1" fontWeight="medium">
                                {dayjs(e.fechaTerminoPeriodo).format(
                                  "DD/MM/YYYY"
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>
                              <Typography variant="body1" color="textSecondary">
                                Total:
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <Typography variant="h3" fontWeight="bold">
                                {formatAsCLP(e.total)}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                              cookies.set("uuiPago", e.id, {
                                path: "/",
                              });
                              cookies.set("uuidUser", e.user, {
                                path: "/",
                              });

                              createSessionGetnet({
                                amount: Number(e.total),
                                returnUrl: `${urlHost}/pagos-membresia`,
                                uuiPago: e.id,
                                uuidUser: e.user,
                                fromFront: true,
                              })
                                .unwrap()
                                .then((resp) => {
                                  if (resp.processUrl && resp.requestId) {
                                    cookies.set("requestId", resp.requestId, {
                                      path: "/",
                                    });
                                    window.location.href = `${resp.processUrl}`;
                                  }
                                })
                                .catch(() => {
                                  AlertaToast({
                                    action: "Error en el servidor",
                                    fullText: true,
                                    type: "error",
                                  }).fire();
                                });
                            }}
                          >
                            Pagar Ahora
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            fullWidth
                            onClick={() => {
                              setPagoPendiente([]);
                              setCorreo("");
                              setCodigoEnviado(false);
                              setCodigo("");
                            }}
                          >
                            Cancelar
                          </Button>
                        </Stack>
                      </CardContent>
                    </Card>
                  );
                })}
              </Stack>
            ) : (
              <Stack spacing={2}>
                <Typography variant="h4" gutterBottom>
                  Pago Membresía
                </Typography>
                <TextField
                  label="Correo"
                  value={correo}
                  onChange={(e) => setCorreo(e.target.value)}
                />
                {codigoEnviado ? (
                  <Stack spacing={2}>
                    <TextField
                      label="Código Verificación"
                      value={codigo}
                      type="number"
                      onChange={(e) => setCodigo(e.target.value)}
                    />

                    <LoadingButton
                      loading={isLoadingValidateCodePayment}
                      onClick={validarCodigo}
                      variant="contained"
                    >
                      Validar Código
                    </LoadingButton>
                  </Stack>
                ) : (
                  <Stack spacing={2}>
                    <LoadingButton
                      loading={isLoadingSendCodePayment}
                      onClick={enviarCodigo}
                      variant="contained"
                    >
                      Enviar Código
                    </LoadingButton>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
