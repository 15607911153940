import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { API_SERVER } from "../../utils/urlBack_End";
import saveFill from "@iconify/icons-eva/save-outline";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import axios from "axios";
import { Alerta } from "../Alerta";
import { useSelector } from "react-redux";

export default function ConfigurarCuentaCliente(props) {
  const { open, setOpen } = props;
  const [showAlert, setShowAlert] = useState(false);
  const { cliente: user } = useSelector((state) => state.auth.user);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const validar = (value, size, required) => {
    if (required) {
      if (value?.length === size) return true;
      else return false;
    } else {
      if (value?.length >= size || value?.length === 0 || value === undefined)
        return true;
      else return false;
    }
  };

  const validarConfirmarContrasena = (value) => {
    if (
      getFieldProps("nuevaContrasena").value === "" ||
      getFieldProps("nuevaContrasena").value === undefined ||
      getFieldProps("nuevaContrasena").value.length < 8
    ) {
      if (value === "" || value === undefined) return true;
      else return false;
    }
    if (getFieldProps("nuevaContrasena").value.length >= 8) {
      if (value === getFieldProps("nuevaContrasena").value) return true;
      else return false;
    }
  };

  const LoginSchema = Yup.object().shape({
    nuevaContrasena: Yup.string().test(
      "nuevaContrasena test",
      "La contraseña debe tener un mínimo de 8 caracteres",
      (value) => validar(value, 8, false)
    ),
    confirmarNuevaContrasena: Yup.string().test(
      "confirmarNuevaContrasena test",
      "Las contraseñas no coinciden",
      (value) => validarConfirmarContrasena(value)
    ),
  });

  const formik = useFormik({
    initialValues: {
      nuevaContrasena: "",
      confirmarNuevaContrasena: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  const guardarDatos = () => {
    axios
      .put(
        `${API_SERVER}/accesoClientes/claves/sitax/${user.rut}`,
        {
          contrasena: getFieldProps("nuevaContrasena").value,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        if (response.data === -2) window.location.reload();
        setShowAlert(true);
        setSubmitting(false);
      });
  };

  return (
    <>
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Cuenta</DialogTitle>
          <DialogContent>
            <DialogContentText>Configuración de la cuenta</DialogContentText>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  style={{ marginTop: ".5rem" }}
                  fullWidth
                  autoComplete="new-password"
                  type={showPassword ? "text" : "password"}
                  label="Nueva Contraseña"
                  {...getFieldProps("nuevaContrasena")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                          edge="end">
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(
                    touched.nuevaContrasena && errors.nuevaContrasena
                  )}
                  helperText={touched.nuevaContrasena && errors.nuevaContrasena}
                />

                <TextField
                  style={{ marginTop: ".5rem" }}
                  fullWidth
                  autoComplete="new-password"
                  type={showConfirmPassword ? "text" : "password"}
                  label="Confirmar Nueva Contraseña"
                  {...getFieldProps("confirmarNuevaContrasena")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setConfirmShowPassword(
                              (showConfirmPassword) => !showConfirmPassword
                            )
                          }
                          edge="end">
                          <Icon
                            icon={showConfirmPassword ? eyeFill : eyeOffFill}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(
                    touched.confirmarNuevaContrasena &&
                      errors.confirmarNuevaContrasena
                  )}
                  helperText={
                    touched.confirmarNuevaContrasena &&
                    errors.confirmarNuevaContrasena
                  }
                />
                <DialogActions>
                  <LoadingButton
                    onClick={handleClose}
                    color="error"
                    disabled={isSubmitting}>
                    Cerrar
                  </LoadingButton>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    startIcon={<Icon icon={saveFill} />}
                    loading={isSubmitting}>
                    Guardar Cambios
                  </LoadingButton>
                  <Alerta
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                    message="Datos Actualizados"
                    color="success"
                  />
                </DialogActions>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
