import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { TextField, Stack } from "@mui/material";
import Alert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import ForgotPassword from "./ForgotPassword";
import { Alerta } from "../../Alerta";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { deconstructRut, validateRut } from "@fdograph/rut-utilities";
import {
  AlertaModalInformativa,
  AlertaToast,
} from "src/components/AlertaSwall";
import { usuariosApi } from "src/services/usuariosApi";

export default function RegisterForm() {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const [serverError, setServerError] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordMsg, setForgotPasswordMsg] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [crearUsuario] = usuariosApi.useAddMutation();

  const LoginSchema = Yup.object().shape({
    rut: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
    nombre: Yup.string().required("Contraseña requerida"),
    direccion: Yup.string().required("Dirección requerida"),
    email: Yup.string().email("Correo no válido").required("Correo requerido"),
    telefono: Yup.string().test(
      "telefono test",
      "Teléfono no válido",
      (value) => matchIsValidTel(value)
    ),
  });

  const formik = useFormik({
    initialValues: {
      rut: "",
      nombre: "",
      direccion: "",
      email: "",
      telefono: "+56",
    },
    validationSchema: LoginSchema,
    onSubmit: handleLogin,
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setTouched,
    setValues,
    values,
  } = formik;

  async function handleLogin(e, { props, setSubmitting, setErrors }) {
    setServerError("");
    setLoginError("");
    setSubmitting(true);

    try {
      const newUser = {
        rut:
          deconstructRut(getFieldProps("rut").value.toUpperCase()).digits +
          deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier,
        displayName: getFieldProps("nombre").value,
        email: getFieldProps("email").value,
        phoneNumber: getFieldProps("telefono").value,
        direccion: getFieldProps("direccion").value,
        type_user: "Cliente",
      };

      try {
        const resp = await crearUsuario(newUser).unwrap();
        try {
          AlertaModalInformativa({
            title: "Usuario creado con éxito.",
            text: `Su clave es: ${resp.password}`,
            width: 474,
          })
            .fire()
            .then(() => navigate("/login", { replace: true }));
        } catch (error) {
          setSubmitting(false);
          AlertaToast({
            action: "Error en el servidor o el usuario ya existe",
            fullText: true,
            type: "error",
          }).fire();
        }
      } catch (error) {
        setSubmitting(false);
        AlertaToast({
          action: "Error en el servidor o el usuario ya existe",
          fullText: true,
          type: "error",
        }).fire();
      }
    } catch (ex) {
      setSubmitting(false);
      setServerError(
        "Ha ocurrido un error en el servidor. " +
          "Por favor reintente más tarde."
      );
    }
  }
  const handleCloseForgotPassword = (msg) => {
    setForgotPassword(false);
    setForgotPasswordMsg(msg);
    setShowSnackbar(true);
  };

  const handlePhoneNumber = (v) => {
    setValues(
      {
        ...values,
        telefono: v,
      },
      true
    );
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {loginError && <Alert severity="error">{loginError}</Alert>}
            {serverError && <Alert severity="error">{serverError}</Alert>}
            <TextField
              fullWidth
              id="rut"
              label="Rut"
              {...getFieldProps("rut")}
              error={Boolean(touched.rut && errors.rut)}
              helperText={touched.rut && errors.rut}
            />
            <TextField
              fullWidth
              id="nombre"
              label="Nombre"
              {...getFieldProps("nombre")}
              error={Boolean(touched.nombre && errors.nombre)}
              helperText={touched.nombre && errors.nombre}
            />
            <TextField
              fullWidth
              id="direccion"
              label="Dirección"
              {...getFieldProps("direccion")}
              error={Boolean(touched.direccion && errors.direccion)}
              helperText={touched.direccion && errors.direccion}
            />
            <MuiTelInput
              required
              margin="dense"
              id="telefono"
              label="Teléfono"
              forceCallingCode
              defaultCountry="CL"
              preferredCountries={["CL"]}
              fullWidth
              onFocus={() => setTouched({ ...touched, telefono: true }, true)}
              value={getFieldProps("telefono").value}
              onChange={(e) => handlePhoneNumber(e)}
              onBlur={getFieldProps("telefono").onBlur}
              error={Boolean(touched.telefono && errors.telefono)}
              helperText={touched.telefono && errors.telefono}
            />
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              id="email"
              label="Correo"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>

          <Stack spacing={2} marginTop={2}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Quiero ser parte
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      {forgotPassword && (
        <ForgotPassword
          open={forgotPassword}
          onClose={handleCloseForgotPassword}
        />
      )}

      {forgotPasswordMsg && (
        <Alerta
          showAlert={showSnackbar}
          setShowAlert={setShowSnackbar}
          color="success"
          message={forgotPasswordMsg}
        />
      )}
    </>
  );
}
