import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Chip,
  Skeleton,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { doc, getDoc } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { updatePermisosUsuarios } from "src/reducers/authReducers";

export default function DialogAgregarPermisos(props) {
  const { open, setOpen, permisos, addOrDeletePermisos } = props;
  const dispatch = useDispatch();

  const [permisosDisponibles, setPermisosDisponibles] = useState([]);
  const [permisosSelect, setPermisosSelect] = useState([]);

  useEffect(() => {
    if (Array.isArray(permisos) && permisos.length) {
      setPermisosSelect(permisos);
    }
  }, [permisos]);

  useEffect(() => {
    const docRef = doc(db, "ajustes", "JWvGlX2HeWTo4hyIxVtv");
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data && data.permisos && Array.isArray(data.permisos)) {
          setPermisosDisponibles(data.permisos);
        }
      } else {
      }
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">{"Nuevos Permisos"}</DialogTitle>
        <DialogContent>
          {permisos ? (
            <Autocomplete
              style={{ marginTop: 10, marginBottom: 10 }}
              multiple
              options={permisosDisponibles}
              value={permisosSelect}
              onChange={(e, newValue) => {
                addOrDeletePermisos(newValue);
                dispatch(updatePermisosUsuarios(newValue));
                setPermisosSelect(newValue);
              }}
              freeSolo
              fullWidth
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Permisos"
                  placeholder="Seleccione Permisos"
                />
              )}
            />
          ) : (
            <Skeleton variant="rectangular" width="100%" height="500px" />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
