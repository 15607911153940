import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  Check as CheckIcon,
  Cancel as CancelIcon,
  LockClock as LockClockIcon,
} from "@mui/icons-material";
import dayjs from "dayjs";
import DialogLeerQR from "./dialog/DialogLeerQR";
import DialogReservar from "./dialog/DialogReservar";
import DialogVerReserva from "./dialog/DialogVerReserva";
import { AlertaToast } from "../AlertaSwall";
import { formatAsCLP } from "src/utils/formatNumber";

export const ReservaCanchas = ({
  cancha,
  fechaSelect,
  reservas,
  setFechaSelect,
}) => {
  const [openQr, setOperQr] = useState(false);
  const [openReserva, setOperReserva] = useState(false);
  const [openDatosReserva, setOpenDatosReserva] = useState(false);
  const [reservaSelect, setReservaSelect] = useState(null);

  return (
    <Box>
      <Typography variant="h6" color="primary" textAlign="center">
        {cancha.nombre}
      </Typography>

      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {reservas.map((value, idx) => {
          return (
            <ListItem
              key={`${cancha.id}_${idx}`}
              disablePadding
              onClick={() => {
                if (!value.reservada && !value.porHora) {
                  // Reservar
                  setReservaSelect({
                    uuidCancha: cancha.id,
                    horaReserva: value.hora,
                    fechaReserva: dayjs(fechaSelect).format("YYYY-MM-DD"),
                    nombreCancha: cancha.nombre,
                  });
                  setOperReserva(true);
                } else if (value.reservada && !value.porHora) {
                  if (value.reserva) {
                    const reservaAux = value.reserva;

                    if (
                      reservaAux.estadoActual === "FINALIZADO" ||
                      reservaAux.estadoActual === "CANCELADO" ||
                      reservaAux.estadoActual === "INGRESADO"
                    ) {
                      setReservaSelect(reservaAux);
                      setOpenDatosReserva(true);
                    } else {
                      setReservaSelect(reservaAux);
                      setOperQr(true);
                    }
                  }
                } else {
                  AlertaToast({
                    action: "No se ha reservado",
                    fullText: true,
                    type: "warning",
                  }).fire();
                }
              }}
            >
              <ListItemButton
                disabled={
                  (value.porHora || value.reservada) === true ? true : false
                }
              >
                <ListItemIcon>
                  {value.porHora ? (
                    <LockClockIcon color="warning" />
                  ) : value.reservada ? (
                    <CancelIcon color="error" />
                  ) : (
                    <CheckIcon color="primary" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={value.hora}
                  secondary={
                    <Stack>
                      {" "}
                      <Typography variant="body1">
                        {value.nombreUsuario}
                      </Typography>
                      {value.reserva && (
                        <Typography
                          variant="body2"
                          color="#084BB5"
                          fontWeight="bold"
                        >
                          {`Total a Pagar: ${
                            value.reserva.estadoActual === "CREADO"
                              ? formatAsCLP(
                                  value.reserva.totalConsumos +
                                    value.reserva.valor
                                )
                              : value.reserva.estadoActual === "PAGADO"
                              ? formatAsCLP(value.reserva.totalConsumos)
                              : 0
                          }`}
                        </Typography>
                      )}
                      {value.reserva && (
                        <Typography
                          variant="body2"
                          color="#084BB5"
                          fontWeight="bold"
                        >
                          {value.reserva.estadoActual}
                        </Typography>
                      )}
                    </Stack>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      {openQr && (
        <DialogLeerQR
          open={openQr}
          setOpen={setOperQr}
          cancha={cancha}
          reserva={reservaSelect}
          canchaName={cancha.nombre}
          setOpenDatosReserva={setOpenDatosReserva}
        />
      )}
      {openReserva && (
        <DialogReservar
          open={openReserva}
          setOpen={setOperReserva}
          reserva={reservaSelect}
          setFechaSelect={setFechaSelect}
        />
      )}
      {openDatosReserva && (
        <DialogVerReserva
          open={openDatosReserva}
          setOpen={setOpenDatosReserva}
          reserva={reservaSelect}
          canchaName={cancha.nombre}
          cancha={cancha}
          setReserva={setReservaSelect}
        />
      )}
    </Box>
  );
};
