import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const reconocimientoApi = createApi({
  reducerPath: "reconocimientoApi",
  baseQuery: baseQuery(`${API_SERVER}/reconocimiento`),
  tagTypes: ["Reconocimiento"],
  endpoints: (builder) => ({
    create: builder.mutation({
      query: (body) => ({
        url: `/create`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useCreateMutation } = reconocimientoApi;
