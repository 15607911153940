import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const reservasApi = createApi({
  reducerPath: "reservasApi",
  baseQuery: baseQuery(`${API_SERVER}/reservas`),
  tagTypes: ["reservasData"],
  endpoints: (builder) => ({
    getReservasAll: builder.query({
      query: (fecha) => {
        return { url: `?fecha=${fecha}` };
      },
      transformResponse: (response) => {
        return response.results;
      },
    }),
    getReservasAll2: builder.query({
      query: () => {
        return { url: `/all` };
      },
      providesTags: (result, error, id) => [
        { type: "reservasData", id: "LIST" },
      ],
    }),
    validateReserva: builder.mutation({
      query: (body) => ({
        url: `/validate`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetReservasAllQuery,
  useLazyGetReservasAllQuery,
  useGetReservasAll2Query,
  useLazyGetReservasAll2Query,
  useValidateReservaMutation,
} = reservasApi;
