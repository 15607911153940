import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DialogCrearUsuario from "src/components/gestionUsuarios/dialog/DialogCrearUsuario";
import Page from "../components/Page";
import Permisos from "../utils/Permisos.js";
import Tabla from "src/components/tabla/Tabla";
import { AlertaModal, AlertaToast } from "../components/AlertaSwall.js";
import { RutFormat, formatRut } from "@fdograph/rut-utilities";
import { Titulo } from "src/components/Titulo";
import BotonAgregar from "src/components/tabla/BotonAgregar.js";
import { db } from "../utils/firebaseInit.js";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { ID_EMPRESA } from "src/utils/urlBack_End.js";
import { usuariosApi } from "src/services/usuariosApi.js";

export default function Usuarios() {
  const navigate = useNavigate();
  const [deleteUser] = usuariosApi.useDeleteMutation();
  const [openCrear, setOpenCrear] = useState(false);

  const [usuarios, setUsuarios] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  useEffect(() => {
    setIsLoadingUsers(true);
    const q = query(
      collection(db, "usuarios"),
      where("empresas", "array-contains", {
        tipoUsuario: "Empresa",
        uuidEmpresa: ID_EMPRESA,
      })
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        if (data && data.estado) {
          cities.push({
            id: doc.id,
            ...data,
          });
        }
      });
      setUsuarios(cities);
      setIsLoadingUsers(false);
    });
    return () => unsubscribe();
  }, []);

  const handleViewUser = (row) => {
    navigate(`${row.id}`);
  };

  const eliminarUsuario = (idUsuario) => {
    AlertaModal({
      title: "eliminar usuario",
      confirmButtonText: "Sí, eliminar",
    })
      .fire()
      .then((response) => {
        if (response.isConfirmed) {
          deleteUser(idUsuario)
            .unwrap()
            .then(() => {
              AlertaToast({
                element: "usuario",
                action: "eliminado",
              }).fire();
            })
            .catch(() => {
              AlertaToast({
                action: "Error no determinado",
                fullText: true,
                type: "error",
              }).fire();
            });
        }
      });
  };

  return (
    <Page title="Padel Club Rengo | Usuarios">
      <Container>
        <Titulo>
          <Typography variant="h4">Usuarios</Typography>
        </Titulo>

        {!isLoadingUsers && usuarios ? (
          <Stack marginTop={5} spacing={2}>
            <Tabla
              encabezados={[
                {
                  id: "rut",
                  label: "Rut",
                },
                {
                  id: "nombre",
                  label: "Nombre",
                },
                {
                  id: "telefono",
                  label: "Teléfono",
                },
                {
                  id: "correo",
                  label: "Correo",
                },
                { id: "", label: "Acciones" },
              ]}
              buscarPor={["nombre", "rut"]}
              etiquetaBusqueda="Buscar usuarios"
              eliminarElemento={(e) => eliminarUsuario(e)}
              permisosEliminar={Permisos.eliminarUsuarios}
              editarElemento={(e) => {
                handleViewUser(e);
              }}
              permisosEditar={Permisos.editarUsuarios}
              data={usuarios.map((u) => ({
                ...u,
                rut: formatRut(u.rut, RutFormat.DOTS_DASH),
              }))}
              botones={[
                <BotonAgregar
                  onClick={() => setOpenCrear(true)}
                  permission={Permisos.agregarUsuarios}
                />,
              ]}
            />
          </Stack>
        ) : (
          <Box display="flex" justifyContent="center" mt={10}>
            {isLoadingUsers && (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </Box>
        )}
        {openCrear && (
          <DialogCrearUsuario
            open={openCrear}
            setOpen={setOpenCrear}
            selectDefinidoUser={false}
          />
        )}
      </Container>
    </Page>
  );
}
