import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";

import DashboardApp from "./pages/DashboardApp";
import DashboardLayout from "./layouts/dashboard";
import Login from "./pages/Login";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import NotFound from "./pages/Page404";
import { login } from "./reducers/authReducers";
import { auth, db } from "./utils/firebaseInit";
import { useDispatch } from "react-redux";
import { ID_EMPRESA } from "./utils/urlBack_End";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import Permisos from "./utils/Permisos";
import Clientes from "./pages/Clientes";
import Usuarios from "./pages/Usuarios";
import Noticias from "./pages/Noticias";
import Usuario from "./pages/Usuario";
import Cliente from "./pages/Cliente";
import Reservas from "./pages/Reservas";
import Tarifas from "./pages/Tarifas";
import Canchas from "./pages/Canchas";
import ReservasCliente from "./pages/ReservasCliente";
import PagoReserva from "./pages/PagoReserva";
import MisReservas from "./pages/MisReservas";
import Registro from "./pages/Registro";
import Socio from "./pages/Socio";
import Convenios from "./pages/Convenios";
import CrearConvenio from "./pages/CrearConvenio";
import Convenio from "./pages/Convenio";
import ReservasAll from "./pages/ReservasAll";
import ControlPadel from "./pages/ControlPadel";
import IngresoReserva from "./pages/IngresoReserva";
import Productos from "./pages/Productos";
import Pagos from "./pages/Pagos";
import PagoMembresia from "./pages/PagoMembresia";
import Imagenes from "./pages/Imagenes";

const getAdminRoutes = (permisos, usuario) => {
  return [
    { path: "inicio", element: <DashboardApp /> },
    permisos.includes(Permisos.visualizarReservas) && {
      path: "reservas",
      element: <Reservas />,
    },
    permisos.includes(Permisos.visualizarReservas) && {
      path: "reservas-all",
      element: <ReservasAll />,
    },
    permisos.includes(Permisos.visualizarClientes) && {
      path: "clientes",
      element: <Clientes />,
    },
    permisos.includes(Permisos.visualizarClientes) && {
      path: "clientes/nuevo-socio",
      element: <Socio />,
    },
    permisos.includes(Permisos.visualizarClientes) && {
      path: "clientes/:id",
      element: <Cliente />,
    },
    permisos.includes(Permisos.visualizarConvenios) && {
      path: "convenios",
      element: <Convenios />,
    },
    permisos.includes(Permisos.visualizarConvenios) && {
      path: "convenios/nuevo-convenio",
      element: <CrearConvenio />,
    },
    permisos.includes(Permisos.visualizarConvenios) && {
      path: "convenios/:id",
      element: <Convenio />,
    },
    permisos.includes(Permisos.visualizarUsuarios) && {
      path: "usuarios",
      element: <Usuarios />,
    },
    permisos.includes(Permisos.visualizarUsuarios) && {
      path: "usuarios/:id",
      element: <Usuario />,
    },
    permisos.includes(Permisos.visualizarTarifas) && {
      path: "tarifas",
      element: <Tarifas />,
    },
    permisos.includes(Permisos.visualizarNoticias) && {
      path: "noticias",
      element: <Noticias />,
    },
    permisos.includes(Permisos.visualizarCanchas) && {
      path: "canchas",
      element: <Canchas />,
    },
    permisos.includes(Permisos.visualizarProductos) && {
      path: "productos",
      element: <Productos />,
    },
    permisos.includes(Permisos.visualizarImagenes) && {
      path: "imagenes",
      element: <Imagenes />,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];
};

const getUsuarioRoutes = () => {
  return [
    { path: "inicio", element: <ReservasCliente /> },
    { path: "mis-reservas", element: <MisReservas /> },
    { path: "reserva-pago", element: <PagoReserva /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];
};

const getReservasRoutes = () => {
  return [
    { path: "inicio", element: <IngresoReserva /> },
    { path: "reserva-pago", element: <PagoReserva /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];
};

const getNotLoggedInRoutes = () => {
  return [{ path: "*", element: <Navigate to="/login" replace /> }];
};

export default function Router() {
  const userInfo = useSelector((state) => state.auth?.user);
  const dispatch = useDispatch();
  const [cargando, setCargando] = useState(false);
  const verifyFirebaseAuth = useCallback(() => {
    onAuthStateChanged(auth, async (user) => {
      setCargando(false);
      if (user?.uid) {
        const docRef = doc(db, "usuarios", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const dataUsuario = docSnap.data();

          if (dataUsuario && dataUsuario.permisos) {
            const existEmpresa = dataUsuario.empresas.filter(
              (e) => e.uuidEmpresa === ID_EMPRESA
            )[0];

            if (existEmpresa) {
              dispatch(
                login({
                  user: {
                    id: user.uid,
                    rol: existEmpresa.tipoUsuario,
                    permisos: dataUsuario.permisos,
                    user: docSnap.data(),
                  },
                })
              );
              setCargando(true);
            } else {
              dispatch(
                login({
                  user: {
                    id: "",
                    rol: "",
                    permisos: [],
                    user: {},
                  },
                })
              );
              setCargando(true);
            }
          } else if (dataUsuario) {
            const existEmpresa = dataUsuario.empresas.filter(
              (e) => e.uuidEmpresa === ID_EMPRESA
            )[0];

            if (existEmpresa) {
              dispatch(
                login({
                  user: {
                    id: user.uid,
                    rol: existEmpresa.tipoUsuario,
                    permisos: dataUsuario.permisos,
                    user: docSnap.data(),
                  },
                })
              );
              setCargando(true);
            } else {
              dispatch(
                login({
                  user: {
                    id: "",
                    rol: "",
                    permisos: [],
                    user: {},
                  },
                })
              );
              setCargando(true);
            }
          } else {
            dispatch(
              login({
                user: {
                  id: "",
                  rol: "",
                  permisos: [],
                  user: {},
                },
              })
            );
            setCargando(true);
          }
        } else {
          dispatch(
            login({
              user: {
                id: "",
                rol: "",
                permisos: [],
                user: {},
              },
            })
          );
          setCargando(true);
        }
      } else {
        dispatch(
          login({
            user: {
              id: "",
              rol: "",
              permisos: [],
              user: {},
            },
          })
        );
        setCargando(true);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    verifyFirebaseAuth();
  }, [verifyFirebaseAuth]);

  const selectChildren = () => {
    const userData = auth.currentUser;
    if (!userData) return getNotLoggedInRoutes();
    if (userData && userInfo) {
      if (userInfo?.rol && userInfo.rol === "Empresa") {
        return getAdminRoutes(userInfo?.permisos, userInfo?.usuario);
      } else if (
        userInfo?.rol &&
        (userInfo.rol === "Cliente" || userInfo.rol === "Soci@")
      ) {
        return getUsuarioRoutes();
      } else if (userInfo?.rol && userInfo.rol === "Reservas") {
        return getReservasRoutes();
      }
    }
  };

  const selectAppRoutesChildren = () => {
    const children = [
      { path: "404", element: <NotFound /> },
      { path: "/", element: <Navigate to="/dashboard/inicio" /> },
      { path: "*", element: <Navigate to="/404" /> },
      { path: "register", element: <Registro /> },
      { path: "padel", element: <ControlPadel /> },
      { path: "pagos", element: <Pagos /> },
      { path: "pagos-membresia", element: <PagoMembresia /> },
    ];
    if (userInfo?.rol) {
      children.unshift({
        path: "login",
        element: <Navigate to="/dashboard/inicio" />,
      });
    } else {
      children.unshift({ path: "login", element: <Login /> });
    }
    return children;
  };

  return useRoutes([
    {
      path: "/dashboard",
      element: cargando ? (
        <DashboardLayout />
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={cargando}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      ),
      children: cargando && selectChildren(),
    },
    {
      path: "/",
      element: cargando ? (
        <LogoOnlyLayout />
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={cargando}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      ),
      children: selectAppRoutesChildren(),
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

export { getAdminRoutes, getUsuarioRoutes, getReservasRoutes };
