import { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  CheckCircle as CheckCircleIcon,
  Error as XCircleIcon,
  FileUpload as FileIcon,
} from "@mui/icons-material";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export function DropZone(props) {
  const {
    handleSubmit,
    isLoadingFile,
    isUploadFailed,
    isUploadSuccess,
    messageDefault,
    messageFormatError,
    messageError,
    messageSuccess,
    acceptedFileFormats,
    maxFilesAccepted = 1,
  } = props;

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        handleSubmit(file);
      });
    },
    [handleSubmit]
  );

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    // acceptedFiles,
    fileRejections,
    isDragActive,
    // isFileDialogActive
  } = useDropzone({
    accept: acceptedFileFormats,
    maxFiles: maxFilesAccepted,
    noDragEventsBubbling: true,
    onDrop: onDrop,
  });

  const style = useMemo(
    () => ({
      ...(isFocused ? "" : {}),
      ...(isDragAccept ? "" : {}),
      ...(isDragReject ? "" : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Box
      sx={{
        py: 6,
        px: 8,
        outline: "none",
        border: "transparent",
        transition: "none",
        backgroundColor: isDragActive ? "#E9F3F5" : "#fafafa",
        borderRadius: "8px",
      }}
      alignItems="center"
    >
      <input {...getInputProps()} />
      <Box
        {...getRootProps({ style })}
        sx={{
          borderStyle: "dashed",
          borderColor: "#A1A8A9",
        }}
      >
        {isLoadingFile && (
          <Stack alignItems="center" padding="5%">
            <CircularProgress color="primary" />
            <Typography
              variant="h6"
              color={isDragActive ? "secondary" : "primary"}
            >
              Cargando archivo
            </Typography>
            <Typography
              variant="h6"
              color={isDragActive ? "secondary" : "primary"}
            >
              Esto puede tardar más de lo que esperamos
            </Typography>
          </Stack>
        )}

        {/* Mensaje para archivo que no cumple con formato. */}
        {fileRejections.length > 0 && !isUploadFailed && (
          <Stack alignItems="center" padding="5%">
            <XCircleIcon
              fontSize="large"
              color={isDragActive ? "secondary" : "primary"}
            />
            <Typography
              variant="h6"
              color={isDragActive ? "secondary" : "primary"}
            >
              {messageFormatError}
            </Typography>
            <Button
              variant="contained"
              color={isDragActive ? "secondary" : "primary"}
            >
              <Typography>Buscar Archivo</Typography>
            </Button>
          </Stack>
        )}

        {/* Mensaje de error general al intentar cargar un archivo. */}
        {isUploadFailed && (
          <Stack alignItems="center" padding="5%">
            <XCircleIcon
              fontSize="large"
              color={isDragActive ? "secondary" : "primary"}
            />
            <Typography
              variant="h6"
              color={isDragActive ? "secondary" : "primary"}
            >
              {messageError}
            </Typography>
            <Button
              variant="contained"
              color={isDragActive ? "secondary" : "primary"}
            >
              <Typography>Buscar Archivo</Typography>
            </Button>
          </Stack>
        )}

        {/* State inicial para cargar archivo. */}
        {!isUploadFailed &&
          !isUploadSuccess &&
          !isLoadingFile &&
          fileRejections.length === 0 && (
            <Stack alignItems="center" padding="5%">
              <FileIcon
                fontSize="large"
                color={isDragActive ? "secondary" : "primary"}
              />
              <Typography
                variant="h6"
                color={isDragActive ? "secondary" : "primary"}
              >
                {messageDefault}
              </Typography>
              <Button
                variant="contained"
                color={isDragActive ? "secondary" : "primary"}
              >
                <Typography>Buscar Archivo</Typography>
              </Button>
            </Stack>
          )}

        {isUploadSuccess && (
          <Stack alignItems="center" padding="5%">
            <CheckCircleIcon fontSize="large" />
            <Typography variant="h6">{messageSuccess}</Typography>
          </Stack>
        )}
      </Box>
    </Box>
  );
}
