import { useEffect, useState } from "react";
import {
  DialogActions,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Skeleton,
  Divider,
} from "@mui/material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { deconstructRut, validateRut } from "@fdograph/rut-utilities";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import { LoadingButton } from "@mui/lab";
import { usuariosApi } from "src/services/usuariosApi";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { useSelector } from "react-redux";
import { ID_PADEL } from "src/utils/urlBack_End";
import { generateID } from "src/utils/generateID";
import dayjs from "dayjs";
import { correosApi } from "src/services/correosApi";
import { reservasApi } from "src/services/reservasApi";
export default function DialogReservar(props) {
  const { open, setOpen, reserva, setFechaSelect } = props;
  const { id: uuidUsuarioCreador } = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [usuario, setUsuario] = useState(null);
  const [valor, setValor] = useState(0);
  const [crearUsuario] = usuariosApi.useAddMutation();

  const [validateReserva] = reservasApi.useValidateReservaMutation();

  const [cargandoUser, setCargandoUser] = useState(false);

  const [getUsuario] = usuariosApi.useLazyGetUsuarioQuery();

  const [sendCorreoContacto] = correosApi.useSendContactoMutation();
  const [sendCorreo] = correosApi.useSendCorreoMutation();

  const [tipoPago, setTipoPago] = useState("EFECTIVO");
  const [duracion, setDuracion] = useState("90");
  const [numeroComprobante, setNumeroComprobante] = useState("");

  useEffect(() => {
    const docRef = doc(db, "padel", ID_PADEL);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data && data.valores) {
          const valoresAux = data.valores;

          const duracionAux = Number(duracion);

          if (duracionAux >= 90) {
            const tarifaAlta90 = valoresAux.filter(
              (e) =>
                e.tipoValor === "Tarifa Alta" &&
                e.horario &&
                Array.isArray(e.horario) &&
                e.horario.includes(reserva.horaReserva)
            )[0];

            const tarifaBaja90 = valoresAux.filter(
              (e) => e.tipoValor === "Tarifa Baja"
            )[0];

            if (tarifaAlta90) {
              setValor(tarifaAlta90.valor);
            } else {
              setValor(tarifaBaja90.valor);
            }
          } else {
            const tarifaAlta60 = valoresAux.filter(
              (e) =>
                e.tipoValor === "Tarifa Alta 60" &&
                e.horario &&
                Array.isArray(e.horario) &&
                e.horario.includes(reserva.horaReserva)
            )[0];

            const tarifaBaja60 = valoresAux.filter(
              (e) => e.tipoValor === "Tarifa Baja 60"
            )[0];

            if (tarifaAlta60) {
              setValor(tarifaAlta60.valor);
            } else {
              setValor(tarifaBaja60.valor);
            }
          }
        }
      }
    });
  }, [reserva, duracion]);

  const handleClose = () => {
    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    rut: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
    nombre: Yup.string().required("Contraseña requerida"),
    direccion: Yup.string().required("Dirección requerida"),
    correo: Yup.string().email("Correo no válido").required("Correo requerido"),
    telefono: Yup.string().test(
      "telefono test",
      "Teléfono no válido",
      (value) => matchIsValidTel(value)
    ),
  });

  const formik = useFormik({
    initialValues: {
      rut: "",
      nombre: "",
      correo: "",
      telefono: "+56",
      direccion: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setTouched,
    setFieldValue,
  } = formik;

  const obtenerUsuarioReserva = (e) => {
    setCargandoUser(true);
    getUsuario(e)
      .then((data) => {
        const response = data.data;
        if (response) {
          let nuevoTelefono = response.telefono.replace(/^\+56\s*/, "");

          if (nuevoTelefono.length >= 9) {
          } else {
            nuevoTelefono = `9${nuevoTelefono}`;
          }
          setFieldValue("telefono", `+56${nuevoTelefono}`);
          setFieldValue("correo", response.correo);
          setFieldValue("direccion", response.direccion);
          setFieldValue("nombre", response.nombre);
          setUsuario(response);
        } else {
          setFieldValue("telefono", "+56");
          setFieldValue("correo", "");
          setFieldValue("direccion", "");
          setFieldValue("nombre", "");
          setUsuario(null);
          AlertaToast({
            action: "Usuario no encontrado.",
            type: "warning",
            fullText: true,
          }).fire();
        }
      })
      .catch(() => {
        setFieldValue("telefono", "+56");
        setFieldValue("correo", "");
        setFieldValue("direccion", "");
        setFieldValue("nombre", "");
        setUsuario(null);
        AlertaToast({
          action: "Usuario no encontrado.",
          type: "warning",
          fullText: true,
        }).fire();
      })
      .finally(() => {
        setCargandoUser(false);
      });
  };

  const guardarDatos = () => {
    const newCorreo = getFieldProps("correo").value;
    if (String(newCorreo).includes("padelclubrengo.cl")) {
      return AlertaToast({
        action: "El correo no es válido",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogReserva"),
      }).fire();
    }
    setIsLoading(true);
    if (usuario) {
      AlertaModal({
        title: "ingresar la reserva",
        confirmButtonText: "Sí, ingresar",
        target: document.getElementById("dialogReserva"),
      })
        .fire()
        .then(async (result) => {
          if (result.isConfirmed) {
            validateReserva({
              hora_inicio: reserva.horaReserva,
              duracion: Number(duracion),
              fecha_reserva: reserva.fechaReserva,
              uuid_cancha: reserva.uuidCancha,
            })
              .unwrap()
              .then(async (resp) => {
                if (resp.code === 1) {
                  setIsLoading(false);
                  AlertaToast({
                    action: "Ya existe una reserva.",
                    fullText: true,
                    type: "error",
                    target: document.getElementById("dialogReserva"),
                  }).fire();
                } else {
                  try {
                    const horasReservadas = [reserva.horaReserva];
                    let i = 0;

                    let horaReservadaAux = reserva.horaReserva;

                    while (i < 3) {
                      horaReservadaAux = dayjs(
                        `${new Date(reserva.fechaReserva).getFullYear()}-${
                          new Date(reserva.fechaReserva).getMonth() + 1
                        }-${new Date(
                          reserva.fechaReserva
                        ).getDate()} ${horaReservadaAux}`
                      )
                        .add(30, "minutes")
                        .format("HH:mm");
                      horasReservadas.push(horaReservadaAux);
                      i = i + 1;
                    }
                    await addDoc(
                      collection(db, `canchas/${reserva.uuidCancha}/reservas`),
                      {
                        estadoActual:
                          tipoPago === "NO APLICA" ? "CREADO" : "PAGADO",
                        estados:
                          tipoPago === "NO APLICA"
                            ? ["CREADO"]
                            : ["CREADO", "PAGADO"],
                        fecha: reserva.fechaReserva,
                        horas: horasReservadas,
                        tipoMoneda: "Pesos",
                        tipoPago: tipoPago,
                        valor: Number(valor),
                        uuidUsuarioCreador: uuidUsuarioCreador,
                        uuidUsuarioReserva: usuario.id,
                        numeroTicket: generateID(),
                        tokenWebPay: "",
                        numeroComprobante: numeroComprobante,
                        uuidCancha: reserva.uuidCancha,
                        nombreCancha: reserva.nombreCancha,
                        duracion: Number(duracion),
                      }
                    );

                    await sendCorreo({
                      uuid_user: usuario.id,
                      subject_email: "Nueva Reserva",
                      message_email: `Estimado(a) ${usuario.nombre}, <br> Le informamos que se ha agendado una nueva cancha. La reserva es para la cancha: ${reserva.nombreCancha} el día ${reserva.fechaReserva} a las ${horasReservadas[0]}. <br> Te esperamos. <br><br> Saludos, <br> Equipo Padel Club Rengo `,
                    })
                      .unwrap()
                      .then(() => {})
                      .catch(() => {});
                    await sendCorreoContacto({
                      subject_email: "Nueva Reserva",
                      message_email: `Estimado(a), <br> ${usuario.nombre} ha agendado una nueva cancha. La reserva es para la cancha: ${reserva.nombreCancha} el día ${reserva.fechaReserva} a las ${horasReservadas[0]}. <br> Saludos, <br> Equipo Padel Club Rengo `,
                    })
                      .unwrap()
                      .then(() => {})
                      .catch(() => {});
                    setFechaSelect(dayjs(reserva.fechaReserva));
                    handleClose();
                    setIsLoading(false);
                  } catch (error) {
                    setIsLoading(false);
                    AlertaToast({
                      action: "Error en el servidor o el usuario ya existe",
                      fullText: true,
                      type: "error",
                      target: document.getElementById("dialogReserva"),
                    }).fire();
                  }
                }
              })
              .catch(() => {
                setIsLoading(false);
                AlertaToast({
                  action: "Ha ocurrido un error no esperado.",
                  fullText: true,
                  type: "error",
                  target: document.getElementById("dialogReserva"),
                }).fire();
              });
          }
        })
        .finally(() => {
          setIsLoading(true);
        });
    } else {
      let telefonoAux = getFieldProps("telefono").value;
      let nuevoTelefono = telefonoAux.replace(/^\+569\s*/, "");
      const newUser = {
        rut:
          deconstructRut(getFieldProps("rut").value.toUpperCase()).digits +
          deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier,
        displayName: getFieldProps("nombre").value,
        email: getFieldProps("correo").value,
        phoneNumber: nuevoTelefono,
        direccion: getFieldProps("direccion").value,
        type_user: "Cliente",
        numeroSerieCarnet: "",
        fechaNacimiento: "",
        fechaInicioContrato: "",
        totalMembresia: 0,
        tipoMembresia: "",
        edad: 0,
        fechaTerminoContrato: "",
      };
      AlertaModal({
        title: "ingresar la reserva",
        confirmButtonText: "Sí, ingresar",
        target: document.getElementById("dialogReserva"),
      })
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            validateReserva({
              hora_inicio: reserva.horaReserva,
              duracion: Number(duracion),
              fecha_reserva: reserva.fechaReserva,
              uuid_cancha: reserva.uuidCancha,
            })
              .unwrap()
              .then((resp) => {
                if (resp.code === 1) {
                  setIsLoading(false);
                  AlertaToast({
                    action: "Ya existe una reserva.",
                    fullText: true,
                    type: "error",
                    target: document.getElementById("dialogReserva"),
                  }).fire();
                } else {
                  crearUsuario(newUser)
                    .unwrap()
                    .then(async (resp) => {
                      try {
                        const horasReservadas = [reserva.horaReserva];
                        let i = 0;

                        let horaReservadaAux = reserva.horaReserva;

                        while (i < 3) {
                          horaReservadaAux = dayjs(
                            `${new Date(reserva.fechaReserva).getFullYear()}-${
                              new Date(reserva.fechaReserva).getMonth() + 1
                            }-${new Date(
                              reserva.fechaReserva
                            ).getDate()} ${horaReservadaAux}`
                          )
                            .add(30, "minutes")
                            .format("HH:mm");
                          horasReservadas.push(horaReservadaAux);
                          i = i + 1;
                        }
                        await addDoc(
                          collection(
                            db,
                            `canchas/${reserva.uuidCancha}/reservas`
                          ),
                          {
                            estadoActual:
                              tipoPago === "NO APLICA" ? "CREADO" : "PAGADO",
                            estados:
                              tipoPago === "NO APLICA"
                                ? ["CREADO"]
                                : ["CREADO", "PAGADO"],
                            fecha: reserva.fechaReserva,
                            horas: horasReservadas,
                            tipoMoneda: "Pesos",
                            tipoPago: tipoPago,
                            valor: Number(valor),
                            uuidUsuarioCreador: uuidUsuarioCreador,
                            uuidUsuarioReserva: resp.id,
                            numeroTicket: generateID(),
                            tokenWebPay: "",
                            numeroComprobante: numeroComprobante,
                            nombreCancha: reserva.nombreCancha,
                            uuidCancha: reserva.uuidCancha,
                            duracion: Number(duracion),
                          }
                        );
                        await sendCorreo({
                          uuid_user: usuario.id,
                          subject_email: "Nueva Reserva",
                          message_email: `Estimado(a) ${resp.nombre}, <br> Le informamos que se ha agendado una nueva cancha. La reserva es para la cancha: ${reserva.nombreCancha} el día ${reserva.fechaReserva} a las ${horasReservadas[0]}. <br> Te esperamos. <br><br> Saludos, <br> Equipo Padel Club Rengo `,
                        })
                          .unwrap()
                          .then(() => {})
                          .catch(() => {});
                        await sendCorreoContacto({
                          subject_email: "Nueva Reserva",
                          message_email: `Estimado(a), <br> ${resp.nombre} ha agendado una nueva cancha. La reserva es para la cancha: ${reserva.nombreCancha} el día ${reserva.fechaReserva} a las ${horasReservadas[0]}. <br> Saludos, <br> Equipo Padel Club Rengo `,
                        })
                          .unwrap()
                          .then(() => {})
                          .catch(() => {});
                        setFechaSelect(dayjs(reserva.fechaReserva));
                        handleClose();
                        setIsLoading(false);
                      } catch (error) {
                        setIsLoading(false);
                        AlertaToast({
                          action: "Error en el servidor o el usuario ya existe",
                          fullText: true,
                          type: "error",
                          target: document.getElementById("dialogReserva"),
                        }).fire();
                      }
                    })
                    .catch((error) => {
                      if (error.error?.data.statusCode === 409) {
                        AlertaToast({
                          action: error.error.data.message,
                          type: "warning",
                          fullText: true,
                        }).fire();
                      } else {
                        AlertaToast({
                          action: "Error en el servidor o el usuario ya existe",
                          fullText: true,
                          type: "error",
                          target: document.getElementById("dialogReserva"),
                        }).fire();
                      }
                      setIsLoading(false);
                    });
                }
              })
              .catch(() => {
                setIsLoading(false);
                AlertaToast({
                  action: "Ha ocurrido un error no esperado.",
                  fullText: true,
                  type: "error",
                  target: document.getElementById("dialogReserva"),
                }).fire();
              });
          }
        })
        .finally(() => {
          setIsLoading(true);
        });
    }
  };

  const handlePhoneNumber = (v) => {
    setFieldValue("telefono", v);
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogReserva"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Reserva</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Complete la información para la reserva
            </DialogContentText>
          </DialogContent>
          <Stack margin={3}>
            {cargandoUser ? (
              <Stack spacing={2}>
                <Skeleton width="100%" height={20} />
                <Skeleton width="100%" height={20} />
                <Skeleton width="100%" height={20} />
                <Skeleton width="100%" height={20} />
                <Skeleton width="100%" height={20} />
                <Stack direction="row" spacing={2}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="circular" width={40} height={40} />
                </Stack>
              </Stack>
            ) : (
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <TextField
                    required
                    margin="dense"
                    id="rut"
                    label="Rut"
                    fullWidth
                    {...getFieldProps("rut")}
                    error={Boolean(touched.rut && errors.rut)}
                    helperText={touched.rut && errors.rut}
                    onBlur={(e) => {
                      obtenerUsuarioReserva(e.target.value);
                    }}
                  />
                  <TextField
                    required
                    margin="dense"
                    id="nombre"
                    label="Nombre"
                    fullWidth
                    {...getFieldProps("nombre")}
                    error={Boolean(touched.nombre && errors.nombre)}
                    helperText={touched.nombre && errors.nombre}
                  />
                  <TextField
                    required
                    style={{ paddingRight: 10 }}
                    margin="dense"
                    id="direccion"
                    label="Dirección"
                    fullWidth
                    {...getFieldProps("direccion")}
                    error={Boolean(touched.direccion && errors.direccion)}
                    helperText={touched.direccion && errors.direccion}
                  />
                  <TextField
                    required
                    style={{ paddingRight: 10 }}
                    margin="dense"
                    id="correo"
                    label="Correo"
                    type="email"
                    fullWidth
                    {...getFieldProps("correo")}
                    error={Boolean(touched.correo && errors.correo)}
                    helperText={touched.correo && errors.correo}
                  />
                  <MuiTelInput
                    required
                    margin="dense"
                    id="telefono"
                    label="Teléfono"
                    forceCallingCode
                    defaultCountry="CL"
                    preferredCountries={["CL"]}
                    fullWidth
                    onFocus={() =>
                      setTouched({ ...touched, telefono: true }, true)
                    }
                    value={getFieldProps("telefono").value}
                    onChange={(e) => handlePhoneNumber(e)}
                    onBlur={getFieldProps("telefono").onBlur}
                    error={Boolean(touched.telefono && errors.telefono)}
                    helperText={touched.telefono && errors.telefono}
                  />

                  <FormControl>
                    <FormLabel id="duracion-reserva">
                      Duración Reserva
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="duracion-reserva"
                      value={duracion}
                      onChange={(event) => {
                        setDuracion(event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="60"
                        control={<Radio />}
                        label="60 min."
                      />
                      <FormControlLabel
                        value="90"
                        control={<Radio />}
                        label="90 min."
                      />
                    </RadioGroup>
                  </FormControl>

                  <Divider />
                  <TextField
                    sx={{
                      marginTop: 2,
                      marginBottom: 2,
                    }}
                    value={valor}
                    label="Total a pagar"
                    fullWidth
                    disabled
                  />

                  <Divider />

                  <Stack margin={3}>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Tipo Pago
                      </FormLabel>
                      <RadioGroup
                        value={tipoPago}
                        onChange={(event) => {
                          setTipoPago(event.target.value);
                        }}
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="EFECTIVO"
                          control={<Radio />}
                          label="EFECTIVO"
                        />
                        <FormControlLabel
                          value="DÉBITO"
                          control={<Radio />}
                          label="DÉBITO"
                        />
                        <FormControlLabel
                          value="CRÉDITO"
                          control={<Radio />}
                          label="CRÉDITO"
                        />
                        <FormControlLabel
                          value="NO APLICA"
                          control={<Radio />}
                          label="PAGO POSTERIOR"
                        />
                      </RadioGroup>
                    </FormControl>
                    {tipoPago !== "EFECTIVO" && tipoPago !== "NO APLICA" && (
                      <TextField
                        label="Ingresar numero de comprobante"
                        value={numeroComprobante}
                        onChange={(e) => {
                          setNumeroComprobante(e.target.value);
                        }}
                      />
                    )}
                  </Stack>

                  <DialogActions>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleClose}
                      disabled={isLoading}
                    >
                      Cerrar
                    </Button>
                    <LoadingButton
                      loading={isLoading}
                      variant="contained"
                      type="submit"
                      color="primary"
                    >
                      Agregar
                    </LoadingButton>
                  </DialogActions>
                </Form>
              </FormikProvider>
            )}
          </Stack>
        </Dialog>
      </div>
    </>
  );
}
