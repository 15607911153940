import React from "react";
// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import { Provider } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import store from "./store";
import { es } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";

export default function App() {
  return (
    <ThemeConfig>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Provider store={store}>
          <ScrollToTop />
          <Router />
        </Provider>
      </LocalizationProvider>
    </ThemeConfig>
  );
}
