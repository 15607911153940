import AWS from "aws-sdk";

const storage = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCES_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const NAME_BUCKET = process.env.REACT_APP_NAME_BUCKET;

const API_SERVER = process.env.REACT_APP_API_SERVER;
const ID_EMPRESA = process.env.REACT_APP_ID_EMPRESA;
const ID_PADEL = process.env.REACT_APP_ID_PADEL;
const AWS_ACCES_KEY = process.env.AWS_ACCES_KEY;
const AWS_SECRET_KEY = process.env.AWS_SECRET_KEY;
export {
  API_SERVER,
  ID_EMPRESA,
  ID_PADEL,
  AWS_ACCES_KEY,
  AWS_SECRET_KEY,
  storage,
  NAME_BUCKET,
};
