import React, { useRef, useCallback } from "react";
import {
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  Camera as CameraIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import Webcam from "react-webcam";

export default function DialogTomarFoto({
  open,
  setOpen,
  data,
  setData,
  titulo,
}) {
  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    // Ahora, imageSrc tiene la imagen en formato base64.
    // Puedes guardarla, mostrarla o hacer lo que necesites con ella.

    setData(imageSrc);
  }, [webcamRef]);

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogCrearUsuario"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">{titulo}</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              {data ? (
                <div>
                  <img src={data} alt="Captured" width="100%" />
                </div>
              ) : (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                />
              )}

              <Stack
                direction="row"
                alignContent="center"
                justifyContent="center"
              >
                {data ? (
                  <Button
                    onClick={() => {
                      setData(null);
                    }}
                    color="error"
                  >
                    <DeleteIcon fontSize="large" />
                  </Button>
                ) : (
                  <Button onClick={capture}>
                    <CameraIcon fontSize="large" />
                  </Button>
                )}
              </Stack>
            </Stack>

            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cerrar
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
