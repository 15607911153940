import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Skeleton, Stack } from "@mui/material";
import { saveAs } from "file-saver";
import Tabla from "../tabla/Tabla";
import axios from "axios";
import { API_SERVER, ID_PADEL } from "src/utils/urlBack_End";

export const ArchivosContratos = (props) => {
  const { contrato } = props;

  const [archivos, setArchivos] = useState([]);
  const [cargandoData, setCargandoData] = useState(true);

  useEffect(() => {
    setCargandoData(false);
    axios
      .get(`${API_SERVER}/usuarios/contratos/${contrato.rut}`, {
        headers: { Authorization: `Bearer ${ID_PADEL}` },
      })
      .then((res) => {
        setArchivos(res.data);
        setCargandoData(true);
      })
      .catch(() => {
        setCargandoData(true);
      })
      .finally(() => {
        setCargandoData(true);
      });
  }, [contrato]);

  return (
    <Stack spacing={2}>
      {cargandoData ? (
        <Stack marginTop={5} spacing={2}>
          <Tabla
            aplicarBuscador={false}
            encabezados={[
              {
                id: "nombreArchivo",
                label: "Nombre Archivo",
              },
              { id: "", label: "Acciones" },
            ]}
            data={archivos}
            descargarElemento={(e) => {
              saveAs(e.url, `${e.nombreArchivo}.pdf`);
            }}
            descargar
          />
        </Stack>
      ) : (
        <Box display="flex" justifyContent="center" mt={10}>
          {!cargandoData && (
            <Skeleton variant="rectangular" width="100%" height="500px" />
          )}
        </Box>
      )}
    </Stack>
  );
};
