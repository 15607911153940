import { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  Stack,
  AccordionActions,
  IconButton,
  CircularProgress,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { LoadingButton } from "@mui/lab";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import {
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "src/utils/firebaseInit";
import { v4 as uuidv4 } from "uuid";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { ID_PADEL } from "src/utils/urlBack_End";

export default function DialogCrearNoticia(props) {
  const { open, setOpen } = props;

  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [parrafos, setParrafos] = useState([]);
  const [nuevoParrafo, setNuevoParrafo] = useState("");
  const [selectFile, setSelectFile] = useState(null);
  const [progresoCarga, setProgresoCarga] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    nombre: Yup.string().required("Nombre requerido"),
  });

  const formik = useFormik({
    initialValues: {
      nombre: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const guardarDatos = () => {
    setIsLoading(true);
    if (parrafos.length < 1) {
      AlertaToast({
        action: "No se han seleccionado párrafos",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogCrearNoticia"),
      }).fire();
    } else if (!selectFile) {
      AlertaToast({
        action: "No se ha seleccionado imagén",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogCrearNoticia"),
      }).fire();
    } else {
      AlertaModal({
        title: "agregar la noticia",
        confirmButtonText: "Sí, agregar",
        target: document.getElementById("dialogCrearNoticia"),
      })
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            const storageRef = ref(storage, `noticias/${uuidv4()}`);
            const uploadTask = uploadBytesResumable(storageRef, selectFile);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgresoCarga(progress);
                // eslint-disable-next-line default-case
                switch (snapshot.state) {
                  case "paused":
                    break;
                  case "running":
                    break;
                }
              },
              (error) => {
                // eslint-disable-next-line default-case
                switch (error.code) {
                  case "storage/unauthorized":
                    AlertaToast({
                      action: "Sin permisos",
                      fullText: true,
                      type: "error",
                      target: document.getElementById("dialogCrearNoticia"),
                    }).fire();
                    break;
                  case "storage/canceled":
                    AlertaToast({
                      action: "Subida cancelada",
                      fullText: true,
                      type: "error",
                      target: document.getElementById("dialogCrearNoticia"),
                    }).fire();
                    setIsLoading(false);
                    break;
                  case "storage/unknown":
                    AlertaToast({
                      action: "Error no determinado",
                      fullText: true,
                      type: "error",
                      target: document.getElementById("dialogCrearNoticia"),
                    }).fire();
                    setIsLoading(false);
                    break;
                }
              },
              () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then(
                  async (downloadURL) => {
                    const newUser = {
                      titulo: getFieldProps("nombre").value,
                      fecha: Timestamp.fromDate(new Date()),
                      img: {
                        alt: getFieldProps("nombre").value,
                        src: downloadURL,
                      },
                      cuerpo: parrafos,
                    };

                    const data = await addDoc(
                      collection(db, `padel/${ID_PADEL}/noticias`),
                      newUser
                    );

                    if (data) {
                      AlertaToast({
                        element: "noticia",
                        action: "creada",
                      }).fire();
                      setIsLoading(false);
                      handleClose();
                    } else {
                      AlertaToast({
                        action: "Error no determinado",
                        fullText: true,
                        type: "error",
                        target: document.getElementById("dialogCrearNoticia"),
                      }).fire();
                      setIsLoading(false);
                    }
                  }
                );
              }
            );
          }
        });
    }
  };
  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogCrearNoticia"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Nueva Noticia</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Complete la información de la noticia
            </DialogContentText>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  required
                  margin="dense"
                  id="nombre"
                  label="Nombre Noticia"
                  fullWidth
                  {...getFieldProps("nombre")}
                  error={Boolean(touched.nombre && errors.nombre)}
                  helperText={touched.nombre && errors.nombre}
                />

                <Button variant="contained" fullWidth component="label">
                  {selectFile ? selectFile.name : "Seleccione imagen"}
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={(e) => {
                      const newFile = e.target.files[0];
                      if (newFile) {
                        if (String(newFile.type).includes("image/")) {
                          setSelectFile(newFile);
                        } else {
                          AlertaToast({
                            action: "Error al seleccionar el archivo",
                            fullText: true,
                            type: "error",
                            target:
                              document.getElementById("dialogCrearNoticia"),
                          }).fire();
                        }
                      }
                    }}
                  />
                </Button>

                <TextField
                  required
                  margin="dense"
                  label="Párrafo"
                  value={nuevoParrafo}
                  fullWidth
                  multiline
                  rows={5}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      parrafos.push(nuevoParrafo);
                      setNuevoParrafo("");
                    }
                  }}
                  onChange={(e) => {
                    setNuevoParrafo(e.target.value);
                  }}
                  helperText="Presione enter para agregar el párrafo"
                />

                <Paper elevation={2} style={{ marginTop: 10 }}>
                  {parrafos.length >= 1 ? (
                    parrafos.map((element, idx) => {
                      return (
                        <Accordion key={idx}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${idx}-content`}
                            id={`panel${idx}`}
                          >
                            <Typography>Párrafo {idx}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <AccordionActions>
                              <IconButton>
                                <DeleteIcon />
                              </IconButton>
                            </AccordionActions>
                            <Typography>{element}</Typography>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })
                  ) : (
                    <Stack
                      alignContent="center"
                      justifyItems="center"
                      justifyContent="center"
                      mt={10}
                    >
                      <Typography
                        gutterBottom
                        align="center"
                        variant="subtitle1"
                      >
                        Sin resultados
                      </Typography>
                      <Typography variant="body2" align="center">
                        No se han agregado parrafos.
                      </Typography>
                    </Stack>
                  )}
                </Paper>

                <DialogActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                    disabled={isLoading}
                  >
                    Cerrar
                  </Button>
                  {isLoading ? (
                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress variant="determinate" />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          color="text.secondary"
                        >
                          {`${Math.round(progresoCarga)}%`}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <LoadingButton
                      loading={isLoading}
                      variant="contained"
                      type="submit"
                      color="primary"
                    >
                      Agregar
                    </LoadingButton>
                  )}
                </DialogActions>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
