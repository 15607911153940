import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import BackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Titulo } from "src/components/Titulo";
import Page from "../components/Page";
import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { PermisosUsuarios } from "src/components/gestionUsuarios/PermisosUsuarios";
import { tienePermiso } from "src/utils/Permisos";
import TabsComponente from "src/components/TabsComponente";
import { DatosPersonales } from "src/components/gestionUsuarios/DatosPersonales";

export default function Usuario() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const { permisos: permissions } = useSelector((state) => state.auth.user);

  useEffect(() => {
    setIsLoadingUser(true);
    const q = doc(db, "usuarios", id);
    const unsubscribe = onSnapshot(q, (doc) => {
      setSelectedUser({
        id: doc.id,
        ...doc.data(),
      });
      setIsLoadingUser(false);
    });
    return () => unsubscribe();
  }, [id]);

  return (
    <Page title="Padel Club Rengo | Usuario">
      <Container>
        <Titulo>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={2} md={3} align="left">
              <Button
                variant="text"
                color="primary"
                onClick={() => navigate(-1)}
              >
                <BackIcon />
                Volver
              </Button>
            </Grid>
            <Grid item xs={8} md={6} align="center">
              <Typography variant="h4">
                {selectedUser?.nombre || "Usuario"}
              </Typography>
            </Grid>
            <Grid item xs={2} md={3} align="end"></Grid>
          </Grid>
        </Titulo>

        {selectedUser ? (
          <Stack marginTop={5}>
            <TabsComponente
              encabezados={["Datos Personales", "Permisos"].filter(
                (_encabezado, idx) =>
                  tienePermiso(permissions, permissions[idx])
              )}
              tabs={[
                <DatosPersonales selectedUser={selectedUser} />,
                <PermisosUsuarios selectedUser={selectedUser} />,
              ].filter((_componente, idx) =>
                tienePermiso(permissions, permissions[idx])
              )}
              orientacion={"horizontal"}
            />
          </Stack>
        ) : (
          <Box display="flex" justifyContent="center" mt={10}>
            {isLoadingUser && (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </Box>
        )}
      </Container>
    </Page>
  );
}
