import { useEffect, useState } from "react";
import {
  DialogActions,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Card,
  Typography,
  CardActions,
  Grid,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { QrReader } from "react-qr-reader";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { AlertaToast } from "src/components/AlertaSwall";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";

export default function DialogLeerQR(props) {
  const { open, setOpen, cancha, reserva, canchaName } = props;

  const [datoQR, setDatoQR] = useState(null);
  const [datoReserva, setDatoReserva] = useState(null);
  const [tipoIngreso, setTipoIngreso] = useState("");
  const [tipoPago, setTipoPago] = useState("EFECTIVO");
  const [numeroComprobante, setNumeroComprobante] = useState("");
  const [codigoReserva, setCodigoReserva] = useState("");
  const [usuario, setUsuario] = useState(null);

  const [guardandoDatos, setGuardandoDatos] = useState(false);

  const [mostrarDialog, setMostrarDialog] = useState(false);

  useEffect(() => {
    if (datoQR) {
      const separacion = String(datoQR).split("_");
      const docRef = doc(
        db,
        `canchas/${separacion[0]}/reservas`,
        separacion[1]
      );
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data && reserva.numeroTicket === data.numeroTicket) {
            setDatoReserva(data);
          } else {
            AlertaToast({
              action: "Los datos no coinciden",
              fullText: true,
              type: "error",
              target: document.getElementById("dialogLeerQR"),
            }).fire();
          }
        } else {
          AlertaToast({
            action: "Error en el servidor",
            fullText: true,
            type: "error",
            target: document.getElementById("dialogLeerQR"),
          }).fire();
        }
      });
    }
  }, [datoQR, reserva]);

  useEffect(() => {
    const docRef = doc(db, `usuarios/${reserva.uuidUsuarioReserva}`);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data) {
          setUsuario(data);
        }
      }
    });
  }, [reserva]);

  const obtenerCodigoReserva = (e) => {
    if (Number(e) === reserva.numeroTicket) {
      const docRef = doc(db, `canchas/${cancha.id}/reservas`, reserva.id);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data && reserva.numeroTicket === data.numeroTicket) {
            setDatoReserva(data);
          } else {
            AlertaToast({
              action: "Los datos no coinciden",
              fullText: true,
              type: "error",
              target: document.getElementById("dialogLeerQR"),
            }).fire();
          }
        } else {
          AlertaToast({
            action: "Error en el servidor",
            fullText: true,
            type: "error",
            target: document.getElementById("dialogLeerQR"),
          }).fire();
        }
      });
    } else {
      AlertaToast({
        action: "El código de la reserva es incorrecto",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogLeerQR"),
      }).fire();
    }
  };

  const pagar = async () => {
    if (tipoPago !== "EFECTIVO" && numeroComprobante === "") {
      AlertaToast({
        action: "Ingresar numero de comprobante",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogComprobante"),
      }).fire();
    } else {
      setGuardandoDatos(true);
      try {
        await updateDoc(doc(db, `canchas/${cancha.id}/reservas`, reserva.id), {
          estadoActual: "FINALIZADO",
          estados: arrayUnion("PAGADO", "FINALIZADO"),
          tipoPago: tipoPago,
          numeroComprobante: numeroComprobante,
        });
        AlertaToast({
          element: "Reserva",
          action: "pagada y cliente ingresado",
        }).fire();
      } catch (error) {
        AlertaToast({
          action: "Error en el servidor",
          fullText: true,
          type: "error",
        }).fire();
      } finally {
        setGuardandoDatos(false);
        setMostrarDialog(false);
        setOpen(false);
      }
    }
  };
  const cancelar = async () => {
    setGuardandoDatos(true);
    try {
      await updateDoc(doc(db, `canchas/${cancha.id}/reservas`, reserva.id), {
        estadoActual: "CANCELADO",
        estados: arrayUnion("CANCELADO"),
      });
      AlertaToast({
        element: "Reserva",
        action: "cancelada",
      }).fire();
    } catch {
      AlertaToast({
        action: "Error en el servidor",
        fullText: true,
        type: "error",
      }).fire();
    } finally {
      setGuardandoDatos(false);
      setOpen(false);
    }
  };
  const finalizar = async () => {
    setGuardandoDatos(true);
    try {
      await updateDoc(doc(db, `canchas/${cancha.id}/reservas`, reserva.id), {
        estadoActual: "FINALIZADO",
        estados: arrayUnion("FINALIZADO"),
      });
      AlertaToast({
        element: "cliente",
        action: "ingresado",
      }).fire();
    } catch {
      AlertaToast({
        action: "Error en el servidor",
        fullText: true,
        type: "error",
      }).fire();
    } finally {
      setGuardandoDatos(true);
      setOpen(false);
    }
  };

  const color = "primary";

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogLeerQR"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Ingreso Reserva</DialogTitle>

          {datoReserva ? (
            <Stack>
              <>
                <Card
                  sx={{
                    border: 1,
                    borderColor: (theme) => theme.palette[color].dark,
                    padding: 5,
                    boxShadow: 0,
                    textAlign: "center",
                    color: (theme) => theme.palette[color].dark,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={5} md={5}>
                      <Stack>
                        <Typography variant="h6">Número Ticket</Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                          {reserva.numeroTicket}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={5} md={5}>
                      <Stack>
                        <Typography variant="h6">Fecha</Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                          {dayjs(reserva.fecha).format("DD/MM/YYYY")}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={2}>
                    <Grid item xs={5} md={5}>
                      <Stack>
                        <Typography variant="h6">Cancha</Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                          {canchaName}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={5} md={5}>
                      <Stack>
                        <Typography variant="h6">Tipo Pago</Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                          {reserva.tipoPago}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Divider style={{ padding: 5 }} />

                  <Grid container spacing={2} marginTop={2}>
                    <Grid item xs={12} md={12}>
                      <Stack>
                        <Typography variant="h5">Tarjeta de Ingreso</Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} marginTop={2}>
                    <Grid item xs={5} md={5}>
                      <Stack>
                        <Typography variant="h6">Hora</Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                          {reserva.horas.toString()}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={5} md={5}></Grid>
                  </Grid>

                  <Grid container marginTop={2}>
                    <Grid item xs={5} md={5}>
                      <Stack>
                        <Typography variant="h6">Usuario</Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                          {usuario.nombre}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={5} md={5}></Grid>
                  </Grid>
                </Card>
                <CardActions>
                  {datoReserva.estadoActual === "CREADO" ? (
                    <>
                      <LoadingButton
                        loading={guardandoDatos}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          setMostrarDialog(true);
                        }}
                      >
                        Pagar
                      </LoadingButton>
                      <LoadingButton
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={cancelar}
                      >
                        Cancelar
                      </LoadingButton>
                    </>
                  ) : datoReserva.estadoActual === "PAGADO" ? (
                    <>
                      <LoadingButton
                        loading={guardandoDatos}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={finalizar}
                      >
                        Ingresar
                      </LoadingButton>
                      <LoadingButton
                        loading={guardandoDatos}
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={cancelar}
                      >
                        Cancelar
                      </LoadingButton>
                    </>
                  ) : (
                    <></>
                  )}
                </CardActions>
              </>
            </Stack>
          ) : (
            <>
              <DialogContent>
                <DialogContentText>
                  Seleccione la forma del ingreso
                </DialogContentText>
              </DialogContent>
              <Stack direction="row" spacing={2} margin={2}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setTipoIngreso("QR")}
                >
                  Código QR
                </LoadingButton>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setTipoIngreso("Reserva")}
                >
                  Código Reserva
                </Button>
              </Stack>
              {tipoIngreso !== "" &&
                (tipoIngreso === "QR" ? (
                  <QrReader
                    onResult={(result, error) => {
                      if (!!result) {
                        setDatoQR(result?.text);
                      }

                      if (!!error) {
                        AlertaToast({
                          action: "Error en leer el QR",
                          fullText: true,
                          type: "error",
                        }).fire();
                      }
                    }}
                    style={{ width: "100%" }}
                  />
                ) : (
                  <Stack margin={5}>
                    <TextField
                      label="Código de Reserva"
                      fullWidth
                      value={codigoReserva}
                      onChange={(e) => {
                        setCodigoReserva(e.target.value);
                      }}
                      onBlur={(e) => {
                        obtenerCodigoReserva(e.target.value);
                      }}
                    />
                  </Stack>
                ))}
            </>
          )}
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {mostrarDialog && (
        <Dialog
          open={mostrarDialog}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="md"
          id="dialogComprobante"
        >
          <DialogTitle id="form-dialog-title">Ingreso Tipo Pago</DialogTitle>
          <Stack margin={3}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Tipo Pago
              </FormLabel>
              <RadioGroup
                value={tipoPago}
                onChange={(event) => {
                  setTipoPago(event.target.value);
                }}
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="EFECTIVO"
                  control={<Radio />}
                  label="EFECTIVO"
                />
                <FormControlLabel
                  value="DÉBITO"
                  control={<Radio />}
                  label="DÉBITO"
                />
                <FormControlLabel
                  value="CRÉDITO"
                  control={<Radio />}
                  label="CRÉDITO"
                />
              </RadioGroup>
            </FormControl>
            {tipoPago !== "EFECTIVO" && (
              <TextField
                label="Ingresar numero de comprobante"
                value={numeroComprobante}
                onChange={(e) => {
                  setNumeroComprobante(e.target.value);
                }}
              />
            )}
          </Stack>
          <DialogActions>
            <LoadingButton
              variant="contained"
              color="secondary"
              onClick={() => setMostrarDialog(false)}
              loading={guardandoDatos}
            >
              Cerrar
            </LoadingButton>

            <LoadingButton
              variant="contained"
              color="primary"
              onClick={pagar}
              loading={guardandoDatos}
            >
              Pagar
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
