import { useEffect, useState } from "react";
import Permisos from "src/utils/Permisos";
import Tabla from "../tabla/Tabla";
import { ValidarPermisos } from "src/components/ValidarPermisos";
import { Dialog, DialogContent, Skeleton, Stack } from "@mui/material";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import dayjs from "dayjs";
import DialogEditarPago from "./dialog/DialogEditarPago";
import { AlertaToast } from "../AlertaSwall";

export const PagosUsuarios = (props) => {
  const [openEditarPago, setOpenEditarPago] = useState(false);
  const [editarPago, setEditarPago] = useState(false);
  const { selectedUser } = props;

  const [pagos, setPagos] = useState([]);

  useEffect(() => {
    const q = query(collection(db, `usuarios/${selectedUser.id}/pagos`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setPagos(
        cities.map((e) => ({
          ...e,
          fechaInicio: dayjs(e.fechaInicioPeriodo).format("DD-MM-YYYY"),
          fechaTermino: dayjs(e.fechaTerminoPeriodo).format("DD-MM-YYYY"),
          pagadoString: e.pagado ? "SI" : "NO",
        }))
      );
    });
    return () => unsubscribe();
  }, [selectedUser]);

  const editarElemento = (row) => {
    if (row.estado !== "PENDIENTE") {
      return AlertaToast({
        action: "El pago ya se encuentra realizado",
        fullText: true,
        type: "info",
      }).fire();
    }
    setEditarPago(row);
    setOpenEditarPago(true);
  };

  return (
    <>
      <ValidarPermisos permission={Permisos.visualizarPagos}>
        {pagos ? (
          <Stack marginTop={5} spacing={2}>
            <Tabla
              encabezados={[
                {
                  id: "fechaInicio",
                  label: "Fecha Inicio Periodo",
                  disablePadding: false,
                },
                {
                  id: "fechaTermino",
                  label: "Fecha Término Periodo",
                  disablePadding: false,
                },
                {
                  id: "total",
                  label: "Total",
                  numeric: true,
                  disablePadding: false,
                },
                {
                  id: "estado",
                  label: "Estado Pago",
                  disablePadding: false,
                },

                { id: "", label: "Acciones" },
              ]}
              buscarPor={["fechaInicio"]}
              data={pagos}
              eliminarElemento={() => {}}
              permisosEliminar={Permisos.eliminarPagos}
              etiquetaBusqueda={"Buscar pagos"}
              permisosEditar={Permisos.editarPagos}
              editarElemento={editarElemento}
            />
          </Stack>
        ) : (
          <Skeleton variant="rectangular" width="100%" height="500px" />
        )}

        {openEditarPago && (
          <DialogEditarPago
            open={openEditarPago}
            setOpen={setOpenEditarPago}
            row={editarPago}
            selectUser={selectedUser}
          />
        )}
      </ValidarPermisos>
    </>
  );
};
