import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { LoadingButton } from "@mui/lab";
import { AlertaModal, AlertaToast } from "../../AlertaSwall";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { Box, Tabs, Tab, Stack, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import Tabla from "src/components/tabla/Tabla";
import BotonAgregar from "src/components/tabla/BotonAgregar";
import Permisos from "src/utils/Permisos";
import dayjs from "dayjs";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function DialogEditarProducto(props) {
  const { open, setOpen, producto, setProducto } = props;

  const [tabActual, setTabActual] = useState(0);

  const [openCrear, setOpenCrear] = useState(false);

  const [historialCompras, setHistorialCompras] = useState([]);
  const [isLoadingHistorialCompras, setIsHistorialCompra] = useState(false);

  const [historialVentas, setHistorialVentas] = useState([]);
  const [isLoadingHistorialVentas, setIsHistorialVentas] = useState(false);

  const { id: uuidUsuarioCreador, user: usuarioObtenido } = useSelector(
    (state) => state.auth.user
  );

  const handleChangeTabActual = (event, newValue) => {
    setTabActual(newValue);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCompra, setIsLoadingCompra] = useState(false);

  const handleClose = () => {
    setProducto(null);
    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    valor: Yup.string().required("Valor requerido"),
  });

  const formik = useFormik({
    initialValues: {
      idProducto: producto.idProducto,
      nombre: producto.nombre,
      valor: producto.valor,
      cantidadActual: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const guardarDatos = () => {
    setIsLoading(true);

    const newUser = {
      valor: Number(getFieldProps("valor").value),
    };

    AlertaModal({
      title: "editar el producto",
      confirmButtonText: "Sí, editar",
      target: document.getElementById("dialogCrearProducto"),
    })
      .fire()
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            await updateDoc(doc(db, `productos`, producto.id), newUser);
            AlertaToast({
              element: "Producto",
              action: "editado",
            }).fire();
            setIsLoading(false);
            handleClose();
          } catch (error) {
            AlertaToast({
              action: "Error al crear el producto",
              fullText: true,
              type: "error",
              target: document.getElementById("dialogCrearProducto"),
            }).fire();
            setIsLoading(false);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const agregarHistorialCompra = () => {
    setIsLoadingCompra(true);

    const newUser = {
      cantidad: Number(getFieldProps("cantidadActual").value),
    };

    if (newUser.cantidad === "") {
      AlertaToast({
        action: "La cantidad ingresada no es válida.",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogCrearHistorialCompras"),
      }).fire();
    }
    AlertaModal({
      title: "agregar la nueva cantidad del producto",
      confirmButtonText: "Sí, editar",
      target: document.getElementById("dialogCrearHistorialCompras"),
    })
      .fire()
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            await updateDoc(doc(db, `productos`, producto.id), {
              cantidad: producto.cantidad
                ? producto.cantidad + newUser.cantidad
                : newUser.cantidad,
            });

            await addDoc(
              collection(db, `productos/${producto.id}/historialCompras`),
              {
                fecha: dayjs().format("YYYY-MM-DD"),
                nombreUsuario: usuarioObtenido.nombre,
                uuidUsuario: uuidUsuarioCreador,
                cantidadAnterior: Number(
                  producto.cantidad ? producto.cantidad : 0
                ),
                cantidad: newUser.cantidad,
              }
            );

            AlertaToast({
              element: "Cantidad",
              action: "agregada",
              target: document.getElementById("dialogCrearProducto"),
            }).fire();
            setIsLoadingCompra(false);
            setOpenCrear(false);
          } catch (error) {
            AlertaToast({
              action: "Error al la cantidad",
              fullText: true,
              type: "error",
              target: document.getElementById("dialogCrearHistorialCompras"),
            }).fire();
            setIsLoadingCompra(false);
          }
        }
      })
      .finally(() => {
        setIsLoadingCompra(false);
      });
  };

  useEffect(() => {
    setIsHistorialCompra(true);
    const q = query(
      collection(db, `productos/${producto.id}/historialCompras`)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data) {
          cities.push({
            id: doc.id,
            ...data,
          });
        }
      });
      setHistorialCompras(cities);
      setIsHistorialCompra(false);
    });
    return () => unsubscribe();
  }, [producto]);

  useEffect(() => {
    setIsHistorialVentas(true);
    const q = query(collection(db, `productos/${producto.id}/historialVentas`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data) {
          cities.push({
            id: doc.id,
            ...data,
          });
        }
      });
      setHistorialVentas(cities);
      setIsHistorialVentas(false);
    });
    return () => unsubscribe();
  }, [producto]);
  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogCrearProducto"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Editar Producto</DialogTitle>
          <DialogContent>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabActual}
                  onChange={handleChangeTabActual}
                  aria-label="basic tabs example"
                >
                  <Tab label="Datos Producto" {...a11yProps(0)} />
                  <Tab label="Historial Compras" {...a11yProps(1)} />
                  <Tab label="Historial Ventas" {...a11yProps(2)} />
                </Tabs>
              </Box>

              {tabActual === 0 && (
                <Box sx={{ p: 3 }}>
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <TextField
                        margin="dense"
                        id="idProducto"
                        label="ID Producto"
                        fullWidth
                        {...getFieldProps("idProducto")}
                        disabled
                      />
                      <TextField
                        required
                        margin="dense"
                        id="nombre"
                        label="Nombre"
                        fullWidth
                        {...getFieldProps("nombre")}
                        disabled
                      />
                      <TextField
                        required
                        style={{ paddingRight: 10 }}
                        margin="dense"
                        id="valor"
                        label="Valor"
                        type="number"
                        fullWidth
                        {...getFieldProps("valor")}
                        error={Boolean(touched.valor && errors.valor)}
                        helperText={touched.valor && errors.valor}
                      />
                      <TextField
                        required
                        style={{ paddingRight: 10 }}
                        margin="dense"
                        label="Cantidad actual de productos"
                        type="number"
                        fullWidth
                        disabled
                        value={producto.cantidad ? producto.cantidad : 0}
                      />

                      <DialogActions>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleClose}
                          disabled={isLoading}
                        >
                          Cerrar
                        </Button>
                        <LoadingButton
                          loading={false}
                          variant="contained"
                          type="submit"
                          color="primary"
                        >
                          Editar
                        </LoadingButton>
                      </DialogActions>
                    </Form>
                  </FormikProvider>
                </Box>
              )}

              {tabActual === 1 && (
                <Box sx={{ p: 3 }}>
                  {!isLoadingHistorialCompras && historialCompras ? (
                    <Stack marginTop={5} spacing={2}>
                      <Tabla
                        aplicarBuscador={false}
                        encabezados={[
                          {
                            id: "nombreUsuario",
                            label: "Usuario",
                          },
                          {
                            id: "cantidadAnterior",
                            label: "Cantidad Anterior",
                          },
                          {
                            id: "cantidad",
                            label: "Cantidad",
                          },
                        ]}
                        data={historialCompras}
                        botones={[
                          <BotonAgregar
                            onClick={() => setOpenCrear(true)}
                            permission={Permisos.agregarProductos}
                          />,
                        ]}
                      />
                    </Stack>
                  ) : (
                    <Box display="flex" justifyContent="center" mt={10}>
                      {isLoadingHistorialCompras && (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="500px"
                        />
                      )}
                    </Box>
                  )}
                </Box>
              )}

              {tabActual === 2 && (
                <Box sx={{ p: 3 }}>
                  <Box sx={{ p: 3 }}>
                    {!isLoadingHistorialVentas && historialVentas ? (
                      <Stack marginTop={5} spacing={2}>
                        <Tabla
                          aplicarBuscador={false}
                          encabezados={[
                            {
                              id: "nombreUsuario",
                              label: "Usuario",
                            },
                            {
                              id: "cantidadAnterior",
                              label: "Cantidad Anterior",
                            },
                            {
                              id: "cantidad",
                              label: "Cantidad",
                            },
                          ]}
                          data={historialVentas}
                        />
                      </Stack>
                    ) : (
                      <Box display="flex" justifyContent="center" mt={10}>
                        {isLoadingHistorialVentas && (
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="500px"
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </DialogContent>
        </Dialog>
        {openCrear && (
          <Dialog
            open={openCrear}
            fullWidth
            maxWidth="md"
            id="dialogCrearHistorialCompras"
          >
            <DialogContent>
              <TextField
                required
                style={{ paddingTop: 10 }}
                margin="dense"
                id="cantidadActual"
                label="Cantidad actual de productos"
                type="number"
                fullWidth
                {...getFieldProps("cantidadActual")}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="inherit"
                variant="contained"
                onClick={() => {
                  setOpenCrear(false);
                }}
              >
                Cerrar
              </Button>
              <LoadingButton
                loading={isLoadingCompra}
                onClick={agregarHistorialCompra}
                variant="contained"
              >
                Agregar
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </>
  );
}
