import { configureStore } from "@reduxjs/toolkit";
import authReducers from "../reducers/authReducers";
import { usuariosApi } from "src/services/usuariosApi";
import { webpayApi } from "src/services/webPayApi";
import { reconocimientoApi } from "src/services/reconocimientoApi";
import { correosApi } from "src/services/correosApi";
import { reservasApi } from "src/services/reservasApi";
import { conveniosApi } from "src/services/conveniosApi";
import { getnetApi } from "src/services/getnetApi";

const reducers = {
  auth: authReducers,
  [usuariosApi.reducerPath]: usuariosApi.reducer,
  [webpayApi.reducerPath]: webpayApi.reducer,
  [reconocimientoApi.reducerPath]: reconocimientoApi.reducer,
  [correosApi.reducerPath]: correosApi.reducer,
  [reservasApi.reducerPath]: reservasApi.reducer,
  [conveniosApi.reducerPath]: conveniosApi.reducer,
  [getnetApi.reducerPath]: getnetApi.reducer,
};

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      usuariosApi.middleware,
      webpayApi.middleware,
      reconocimientoApi.middleware,
      correosApi.middleware,
      reservasApi.middleware,
      conveniosApi.middleware,
      getnetApi.middleware
    ),

  devTools: true,
});

export default store;
