export const generateID = () => {
  const rand = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
  const idGenerate = [];

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < rand(7, 9); index++) {
    const element = rand(1, 9);
    idGenerate.push(element);
  }
  // eslint-disable-next-line prefer-regex-literals
  const replacer = new RegExp(",", "g");
  return parseInt(idGenerate.toString().replace(replacer, ""), 10);
};

export const generateID4 = () => {
  const rand = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
  const idGenerate = [];

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < 4; index++) {
    const element = rand(1, 9);
    idGenerate.push(element);
  }
  // eslint-disable-next-line prefer-regex-literals
  const replacer = new RegExp(",", "g");
  return parseInt(idGenerate.toString().replace(replacer, ""), 10);
};
