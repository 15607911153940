import {
  Box,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "src/utils/firebaseInit";
import { ID_PADEL } from "src/utils/urlBack_End";
import {
  Check as CheckIcon,
  Cancel as CancelIcon,
  LockClock as LockClockIcon,
} from "@mui/icons-material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DialogLeerQR from "./dialog/DialogLeerQR";
import DialogVerReserva from "./dialog/DialogVerReserva";
import { AlertaToast } from "../AlertaSwall";
import DialogReservarCliente from "./dialog/DialogReservarCliente";

dayjs.extend(timezone);
dayjs.extend(utc);

dayjs.tz.setDefault("America/Santiago");

export const ReservaCanchasCliente = ({ cancha, padel, intervaloHorario }) => {
  const [openQr, setOperQr] = useState(false);
  const [openReserva, setOperReserva] = useState(false);
  const [openDatosReserva, setOpenDatosReserva] = useState(false);
  const [reservas, setReservas] = useState([]);
  const [cachaSelect, setCachaSelect] = useState(null);
  const [reservaSelect, setReservaSelect] = useState(null);
  const [fechaSelect, setFechaSelect] = useState(dayjs());
  const [diaSemana, setDiaSemana] = useState(
    new Date(fechaSelect).getDay() === 0 ? 7 : new Date(fechaSelect).getDay()
  );

  const fechaActual = dayjs().tz();

  useEffect(() => {
    const q = query(
      collection(db, "canchas"),
      where("uuidCancha", "==", cancha.id),
      where("uuidPadel", "==", ID_PADEL)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const canchasAux = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        canchasAux.push({
          id: doc.id,
          ...data,
        });
      });

      if (canchasAux.length >= 1) {
        const selectCanchaAux = canchasAux[0];
        setCachaSelect(selectCanchaAux);
        const q2 = query(
          collection(db, `canchas/${selectCanchaAux.id}/reservas`),
          where("fecha", "==", dayjs(fechaSelect).format("YYYY-MM-DD"))
        );

        const unsubscribe2 = onSnapshot(q2, (querySnapshot2) => {
          const reservaAux = [];
          querySnapshot2.forEach((doc) => {
            const data = doc.data();
            reservaAux.push({
              id: doc.id,
              ...data,
            });
          });

          const horaFinal = intervaloHorario.horaFinal;

          const horas = [];
          let horaActual = intervaloHorario.horaInicio;

          while (
            dayjs(
              `${new Date(fechaSelect).getFullYear()}-${
                new Date(fechaSelect).getMonth() + 1
              }-${new Date(fechaSelect).getDate()} ${horaActual}`
            ) <
            dayjs(
              `${new Date(fechaSelect).getFullYear()}-${
                new Date(fechaSelect).getMonth() + 1
              }-${new Date(fechaSelect).getDate()} ${horaFinal}`
            )
          ) {
            horas.push(horaActual);
            horaActual = dayjs(
              `${new Date(fechaSelect).getFullYear()}-${
                new Date(fechaSelect).getMonth() + 1
              }-${new Date(fechaSelect).getDate()} ${horaActual}`
            )
              .add(30, "minutes")
              .format("HH:mm");
          }
          if (reservaAux.length >= 1) {
            const reservasUsadas = horas.map((e) => {
              const existReserva = reservaAux.filter(
                (element) =>
                  element.horas &&
                  Array.isArray(element.horas) &&
                  element.horas.includes(e)
              )[0];
              if (existReserva) {
                return {
                  reserva: existReserva,
                  hora: e,
                  reservada: true,
                  porHora: false,
                };
              } else if (
                fechaActual >
                dayjs(
                  `${new Date(fechaSelect).getFullYear()}-${
                    new Date(fechaSelect).getMonth() + 1
                  }-${new Date(fechaSelect).getDate()} ${e}`
                )
              ) {
                return {
                  hora: e,
                  reservada: false,
                  porHora: true,
                };
              } else {
                return {
                  hora: e,
                  reservada: false,
                  porHora: false,
                };
              }
            });
            setReservas(reservasUsadas);
          } else {
            const reservasUsadas = horas.map((e) => {
              if (
                fechaActual >
                dayjs(
                  `${new Date(fechaSelect).getFullYear()}-${
                    new Date(fechaSelect).getMonth() + 1
                  }-${new Date(fechaSelect).getDate()} ${e}`
                )
              ) {
                return {
                  hora: e,
                  reservada: false,
                  porHora: true,
                };
              }
              return {
                hora: e,
                reservada: false,
                porHora: false,
              };
            });
            setReservas(reservasUsadas);
          }
        });
        return () => unsubscribe2();
      }
    });
    return () => unsubscribe();
  }, [cancha, intervaloHorario, diaSemana, fechaSelect]);

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Fecha"
          views={["day", "month", "year"]}
          value={fechaSelect}
          maxDate={fechaActual.add("3", "day")}
          minDate={fechaActual}
          inputFormat="DD/MM/YYYY"
          outputFormat="DD/MM/YYYY"
          onChange={(fecha) => {
            setFechaSelect(fecha);
            setDiaSemana(
              new Date(fecha).getDay() === 0 ? 7 : new Date(fecha).getDay()
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label="Fecha" fullWidth />
          )}
        />
      </LocalizationProvider>

      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {reservas.map((value, idx) => {
          return (
            <ListItem
              key={idx}
              disablePadding
              onClick={() => {
                if (!value.reservada && !value.porHora) {
                  // Reservar
                  setReservaSelect({
                    nombreCancha: cancha.nombre,
                    uuidCancha: cachaSelect.id,
                    horaReserva: value.hora,
                    fechaReserva: dayjs(fechaSelect).format("YYYY-MM-DD"),
                  });
                  setOperReserva(true);
                } else if (value.reservada && !value.porHora) {
                  AlertaToast({
                    action: "Fecha y hora reservada",
                    fullText: true,
                    type: "error",
                  }).fire();
                } else {
                }
              }}
            >
              <ListItemButton
                disabled={
                  (value.porHora || value.reservada) === true ? true : false
                }
              >
                <ListItemIcon>
                  {value.porHora ? (
                    <LockClockIcon color="warning" />
                  ) : value.reservada ? (
                    <CancelIcon color="error" />
                  ) : (
                    <CheckIcon color="primary" />
                  )}
                </ListItemIcon>
                <ListItemText primary={value.hora} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      {openQr && (
        <DialogLeerQR
          open={openQr}
          setOpen={setOperQr}
          cancha={cachaSelect}
          reserva={reservaSelect}
          canchaName={cancha.nombre}
        />
      )}
      {openReserva && (
        <DialogReservarCliente
          open={openReserva}
          setOpen={setOperReserva}
          reserva={reservaSelect}
        />
      )}
      {openDatosReserva && (
        <DialogVerReserva
          open={openDatosReserva}
          setOpen={setOpenDatosReserva}
          reserva={reservaSelect}
          canchaName={cancha.nombre}
          cancha={cachaSelect}
        />
      )}
    </Box>
  );
};
