import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { Button, TextField, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import ForgotPassword from "./ForgotPassword";
import { ID_EMPRESA } from "../../../utils/urlBack_End";
import { Alerta } from "../../Alerta";
import { useDispatch } from "react-redux";
import { login } from "../../../reducers/authReducers";
import { Link as RouterLink } from "react-router-dom";
import {
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "src/utils/firebaseInit";
import { doc, getDoc } from "firebase/firestore";

export default function LoginForm({ isClient = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [serverError, setServerError] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordMsg, setForgotPasswordMsg] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("El correo electrónico es inválido.")
      .required("Este campo es obligatorio."),
    password: Yup.string().required("Este campo es obligatorio."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: handleLogin,
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  async function handleLogin(e, { props, setSubmitting, setErrors }) {
    setServerError("");
    setLoginError("");
    setSubmitting(true);
    try {
      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          signInWithEmailAndPassword(auth, e.email, e.password)
            .then(async (userCredential) => {
              const user = userCredential.user;
              const docRef = doc(db, "usuarios", user.uid);
              const docSnap = await getDoc(docRef);

              if (docSnap.exists()) {
                const dataUsuario = docSnap.data();

                if (dataUsuario && dataUsuario.permisos) {
                  const existEmpresa = dataUsuario.empresas.filter(
                    (e) => e.uuidEmpresa === ID_EMPRESA
                  )[0];

                  if (existEmpresa) {
                    dispatch(
                      login({
                        user: {
                          id: user.uid,
                          rol: existEmpresa.tipoUsuario,
                          permisos: dataUsuario.permisos,
                          user: docSnap.data(),
                        },
                      })
                    );
                    localStorage.setItem("token", user.uid);
                    navigate("/dashboard/inicio", { replace: true });
                  } else {
                    setSubmitting(false);
                    setServerError(
                      "Ha ocurrido un error en el servidor. " +
                        "Por favor reintente más tarde."
                    );
                  }
                } else if (dataUsuario) {
                  const existEmpresa = dataUsuario.empresas.filter(
                    (e) => e.uuidEmpresa === ID_EMPRESA
                  )[0];
                  if (existEmpresa) {
                    dispatch(
                      login({
                        user: {
                          id: user.uid,
                          rol: existEmpresa.tipoUsuario,
                          permisos: dataUsuario.permisos,
                          user: docSnap.data(),
                        },
                      })
                    );
                    localStorage.setItem("token", user.uid);
                    navigate("/dashboard/inicio", { replace: true });
                  } else {
                    setSubmitting(false);
                    setServerError(
                      "Ha ocurrido un error en el servidor. " +
                        "Por favor reintente más tarde."
                    );
                  }
                } else {
                  await auth.signOut();
                  setSubmitting(false);
                  setServerError(
                    "Ha ocurrido un error en el servidor. " +
                      "Por favor reintente más tarde."
                  );
                }
              } else {
                await auth.signOut();
                setSubmitting(false);
                setServerError(
                  "Ha ocurrido un error en el servidor. " +
                    "Por favor reintente más tarde."
                );
              }
            })
            .catch(() => {
              setSubmitting(false);
              setServerError(
                "Ha ocurrido un error en el servidor. " +
                  "Por favor reintente más tarde."
              );
            });
        })
        .catch(() => {
          setSubmitting(false);
          setServerError(
            "Ha ocurrido un error en el servidor. " +
              "Por favor reintente más tarde."
          );
        });
    } catch (ex) {
      setSubmitting(false);
      setServerError(
        "Ha ocurrido un error en el servidor. " +
          "Por favor reintente más tarde."
      );
    }
  }
  const handleCloseForgotPassword = (msg) => {
    setForgotPassword(false);
    setForgotPasswordMsg(msg);
    setShowSnackbar(true);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {loginError && <Alert severity="error">{loginError}</Alert>}
            {serverError && <Alert severity="error">{serverError}</Alert>}
            {!isClient ? (
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Correo"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            ) : (
              <TextField
                fullWidth
                autoComplete="username"
                label="Rut"
                {...getFieldProps("rutCliente")}
                error={Boolean(touched.rutCliente && errors.rutCliente)}
                helperText={touched.rutCliente && errors.rutCliente}
              />
            )}

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Contraseña"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            sx={{ my: 2 }}
          >
            <p
              onClick={() => {
                setForgotPassword(true);
                setForgotPasswordMsg("");
              }}
              style={{
                color: "#084BB5",
                cursor: "pointer",
                fontSize: "0.9rem",
              }}
            >
              ¿Olvidaste tu contraseña?
            </p>
          </Stack>

          <Stack spacing={2}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Ingresar
            </LoadingButton>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="secondary"
              to="/register"
              component={RouterLink}
            >
              Unete a nosotros
            </Button>
          </Stack>
        </Form>
      </FormikProvider>

      {forgotPassword && (
        <ForgotPassword
          open={forgotPassword}
          onClose={handleCloseForgotPassword}
        />
      )}

      {forgotPasswordMsg && (
        <Alerta
          showAlert={showSnackbar}
          setShowAlert={setShowSnackbar}
          color="success"
          message={forgotPasswordMsg}
        />
      )}
    </>
  );
}
