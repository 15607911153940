import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import {
  Card,
  CardHeader,
  Container,
  Grid,
  Typography,
  IconButton,
  CardContent,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import Page from "../components/Page.js";
import Permisos from "../utils/Permisos.js";
import { Titulo } from "src/components/Titulo";
import { db } from "../utils/firebaseInit.js";
import { doc, onSnapshot } from "firebase/firestore";
import { ID_PADEL } from "src/utils/urlBack_End.js";
import {
  Edit as EditIcon,
  AttachMoney as AttachMoneyIcon,
} from "@mui/icons-material";
import { Stack } from "@mui/system";
import { ValidarPermisos } from "src/components/ValidarPermisos.js";
import DialogEditarTarifa from "src/components/tarifas/DialogEditarTarifa.js";

export default function Tarifas() {
  const [openEditar, setOpenEditar] = useState(false);

  const [tarifas, setTarifas] = useState([]);
  const [selectTarifa, setSelectTarifa] = useState(null);
  const [horarioFuncionamiento, setHorarioFuncionamiento] = useState({});
  const [isLoadingTarifas, setIsLoadingTarifas] = useState(false);

  useEffect(() => {
    setIsLoadingTarifas(true);
    const q = doc(db, "padel", ID_PADEL);
    const unsubscribe = onSnapshot(q, (doc) => {
      const dataAux = doc.data();

      if (dataAux && dataAux.valores) {
        setTarifas(dataAux.valores);
      }
      if (dataAux && dataAux.horarioFuncionamiento) {
        setHorarioFuncionamiento(dataAux.horarioFuncionamiento);
      }
      setIsLoadingTarifas(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Page title="Padel Club Rengo | Tarifas">
      <Container>
        <Titulo>
          <Typography variant="h4">Tarifas</Typography>
        </Titulo>

        <Stack padding="20px">
          <Alert severity="info">Solo se pueden editar las tarifas.</Alert>
        </Stack>

        {!isLoadingTarifas && tarifas ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {tarifas.length >= 1 ? (
                tarifas.map((e, index) => {
                  return (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <Card sx={{ maxWidth: 345 }}>
                        <ValidarPermisos permission={Permisos.editarTarifas}>
                          <Stack direction={"row-reverse"}>
                            <IconButton
                              onClick={() => {
                                setSelectTarifa(e);
                                setOpenEditar(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Stack>
                        </ValidarPermisos>
                        <CardHeader
                          avatar={<AttachMoneyIcon />}
                          title={e.tipoValor}
                          subheader={`${e.valor} ${e.tipoMoneda}`}
                        />
                        {e.horario && Array.isArray(e.horario) && (
                          <CardContent>
                            {e.horario.length >= 2 && (
                              <Typography>{`Tarifa de ${e.horario[0]} a ${
                                e.horario[e.horario.length - 1]
                              }`}</Typography>
                            )}
                          </CardContent>
                        )}
                      </Card>
                    </Grid>
                  );
                })
              ) : (
                <Stack
                  alignContent="center"
                  justifyItems="center"
                  justifyContent="center"
                  mt={10}
                  ml="40%"
                >
                  <Typography gutterBottom align="center" variant="subtitle1">
                    Horario de tarifas no disponible
                  </Typography>
                  <Typography variant="body2" align="center">
                    No se han encontrado elementos.
                  </Typography>
                </Stack>
              )}
            </Grid>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" mt={10}>
            {isLoadingTarifas && (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </Box>
        )}
        {openEditar && selectTarifa && (
          <DialogEditarTarifa
            open={openEditar}
            setOpen={setOpenEditar}
            selectTarifa={selectTarifa}
            horarioFuncionamiento={horarioFuncionamiento}
            tarifas={tarifas}
          />
        )}
      </Container>
    </Page>
  );
}
