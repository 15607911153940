import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";

import Page from "../components/Page";
import { Titulo } from "src/components/Titulo";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import Permisos from "src/utils/Permisos";
import Tabla from "src/components/tabla/Tabla";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { Add } from "@mui/icons-material";
export default function Convenios() {
  const navigate = useNavigate();
  const [clientes, setClientes] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  useEffect(() => {
    setIsLoadingUsers(true);
    const q = query(collection(db, "convenios"), where("estado", "==", true));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        if (data) {
          cities.push({
            id: doc.id,
            ...data,
          });
        }
      });
      setClientes(cities);
      setIsLoadingUsers(false);
    });
    return () => unsubscribe();
  }, []);

  const handleViewUser = (row) => {
    navigate(`${row.id}`);
  };

  const eliminarUsuario = (idUsuario) => {
    AlertaModal({
      title: "eliminar convenio",
      confirmButtonText: "Sí, eliminar",
    })
      .fire()
      .then(async (response) => {
        if (response.isConfirmed) {
          try {
            const conveniosRef = doc(db, "convenios", idUsuario);
            await updateDoc(conveniosRef, {
              estado: false,
            });
            AlertaToast({
              element: "convenio",
              action: "eliminado",
            }).fire();
          } catch (error) {
            AlertaToast({
              action: "Ha ocurrido un error al eliminar el convenio",
              fullText: true,
              type: "error",
            }).fire();
          }
        }
      });
  };

  return (
    <Page title="Padel Club Rengo | Convenios">
      <Container>
        <Titulo>
          <Typography variant="h4">Convenios</Typography>
        </Titulo>

        {!isLoadingUsers && clientes ? (
          <Stack marginTop={5} spacing={2}>
            <Tabla
              encabezados={[
                {
                  id: "rut",
                  label: "Rut",
                },
                {
                  id: "nombre",
                  label: "Nombre",
                },
                {
                  id: "telefono",
                  label: "Teléfono",
                },
                {
                  id: "correo",
                  label: "Correo",
                },
                {
                  id: "nombreRepresentanteLegal",
                  label: "Representante Legal",
                },
                { id: "", label: "Acciones" },
              ]}
              buscarPor={["nombre", "rut"]}
              etiquetaBusqueda="Buscar convenios"
              eliminarElemento={(e) => eliminarUsuario(e)}
              permisosEliminar={Permisos.eliminarConvenios}
              editarElemento={(e) => {
                handleViewUser(e);
              }}
              permisosEditar={Permisos.editarConvenios}
              data={clientes.map((u) => ({
                ...u,
                rut: formatRut(u.rut, RutFormat.DOTS_DASH),
              }))}
              botones={[
                <Button
                  onClick={() => navigate("nuevo-convenio")}
                  startIcon={<Add />}
                  variant="contained"
                >
                  Agregar Convenio
                </Button>,
              ]}
            />
          </Stack>
        ) : (
          <Box display="flex" justifyContent="center" mt={10}>
            {isLoadingUsers && (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </Box>
        )}
      </Container>
    </Page>
  );
}
