import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { useSelector } from "react-redux";

import Permisos, { tienePermiso } from "src/utils/Permisos";
import { AlertaToast } from "../AlertaSwall";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";

export const DatosPersonales = ({ selectedUser }) => {
  const { permisos: permissions } = useSelector((state) => state.auth.user);
  const [name, setName] = useState(selectedUser.nombre);
  const [direccion, setDireccion] = useState(selectedUser.direccion);
  const [phoneNumber, setPhoneNumber] = useState(selectedUser.telefono);
  const [state, setState] = useState(selectedUser.estado);
  const [canSave, setCanSave] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const canEdit = tienePermiso(permissions, Permisos.editarUsuarios);

  useEffect(() => {
    setCanSave(() => {
      return !phoneError && isUnsavedChanges();
    });

    function isUnsavedChanges() {
      if (!name.trim()) return false;
      const nameChanged = name.trim() !== selectedUser.nombre;
      const direccionChanged = direccion !== selectedUser.direccion;
      const phoneChanged =
        phoneNumber.replaceAll(" ", "") !==
        selectedUser.telefono.replaceAll(" ", "");
      const stateChanged = state !== selectedUser.estado;
      return nameChanged || phoneChanged || direccionChanged || stateChanged;
    }
  }, [name, phoneNumber, state, direccion, selectedUser, phoneError]);

  const handleState = (e) => {
    setState(e.target.value);
  };

  const handlePhoneNumber = (v) => {
    setPhoneError(!matchIsValidTel(v));
    setPhoneNumber(v);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleDireccion = (e) => {
    setDireccion(e.target.value);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const body = {
        nombre: name.trim(),
        telefono: phoneNumber.replaceAll(" ", ""),
        estado: state,
        direccion: direccion,
      };
      await updateDoc(doc(db, "usuarios", selectedUser.id), body);
      AlertaToast({
        action: "actualizado",
        element: "usuario",
      }).fire();
    } catch {
      AlertaToast({
        action: "Error en el servidor",
        fullText: true,
        type: "error",
      }).fire();
    } finally {
      setIsLoading(false);
      setCanSave(false);
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <LoadingButton
          onClick={handleSave}
          variant="contained"
          disabled={!canSave}
          loading={isLoading}
        >
          Guardar
        </LoadingButton>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nombre"
            id="nombre"
            value={name}
            onChange={handleName}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Dirección"
            id="direccion"
            value={direccion}
            onChange={handleDireccion}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiTelInput
            label="Teléfono"
            id="phoneNumber"
            forceCallingCode
            value={phoneNumber}
            onChange={handlePhoneNumber}
            defaultCountry="CL"
            preferredCountries={["CL"]}
            error={phoneError}
            fullWidth={true}
            disabled={!canEdit}
            helperText={phoneError ? "Teléfono no válido" : ""}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Estado"
            id="estado"
            value={state}
            select
            onChange={handleState}
            disabled={!canEdit}
          >
            <MenuItem value={true}>Activo</MenuItem>
            <MenuItem value={false}>Inactivo</MenuItem>
          </TextField>
        </Grid>
      </Grid>
    </Box>
  );
};
