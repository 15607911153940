import { useEffect, useState } from "react";
import {
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Typography,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogContent,
  IconButton,
  FormLabel,
} from "@mui/material";
import Cookies from "universal-cookie";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import { LoadingButton } from "@mui/lab";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { useSelector } from "react-redux";
import { ID_PADEL } from "src/utils/urlBack_End";
import { generateID } from "src/utils/generateID";
import { formatAsCLP } from "src/utils/formatNumber";
import {
  AccountCircle as AccountCircleIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { deconstructRut, validateRut } from "@fdograph/rut-utilities";
import dayjs from "dayjs";
import { getnetApi } from "src/services/getnetApi";
import { reservasApi } from "src/services/reservasApi";

export default function DialogReservarCliente(props) {
  const { open, setOpen, reserva } = props;
  const {
    id: uuidUsuarioCreador,
    rol: rolObtenido,
    user: usuario,
  } = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [valor, setValor] = useState(0);
  const [valorSocio, setValorSocio] = useState(0);
  const [numeroTicket, setNumeroTicket] = useState(0);
  const [codigoReserva, setCodigoReserva] = useState("");
  const [rutParticipante, setRutParticipante] = useState("");
  const [nombreParticipante, setNombreParticipante] = useState("");
  const [activarWebPay, setActivarWebPay] = useState(true);
  const [duracion, setDuracion] = useState("90");

  const [mostrarNombre, setMostrarNombre] = useState(false);
  const [participantes, setParticipantes] = useState([]);
  const urlHost = `${window.location.protocol}//${window.location.host}`;
  const cookies = new Cookies();

  const [createSessionGetnet] = getnetApi.useCreateMutation();

  const [validateReserva] = reservasApi.useValidateReservaMutation();

  const obtenerDatosUsuario = () => {
    if (rutParticipante === usuario.rut) {
      AlertaToast({
        action: "El participante a ingresar no puede ser igual al socio.",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogReserva"),
      }).fire();
      return;
    }

    if (rutParticipante === "") {
      AlertaToast({
        action: "Ingresar un rut válido.",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogReserva"),
      }).fire();
      return;
    }

    if (!validateRut(rutParticipante)) {
      AlertaToast({
        action: "Ingresar un rut válido.",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogReserva"),
      }).fire();
      return;
    }

    const rutFinal = String(
      `${deconstructRut(rutParticipante).digits}${
        deconstructRut(rutParticipante).verifier
      }`
    ).toUpperCase();

    const q = query(
      collection(db, `usuarios`),
      where("rut", "==", String(rutFinal).toUpperCase())
    );
    getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.docs.length >= 1) {
          querySnapshot.forEach((doc) => {
            const element = doc.data();

            const existElement = participantes.filter(
              (e) => e.rut === element.rut
            )[0];

            if (existElement) {
              AlertaToast({
                action: "El participante ya se encuentra en los registros.",
                fullText: true,
                type: "error",
                target: document.getElementById("dialogReserva"),
              }).fire();
            } else {
              setParticipantes([
                ...participantes,
                {
                  nombre: existElement.nombre,
                  rut: existElement.rut,
                },
              ]);
            }
          });
        } else {
          setMostrarNombre(true);
        }
      })
      .catch((error) => {
        setMostrarNombre(true);
      });
  };

  useEffect(() => {
    const docRef = doc(db, "padel", ID_PADEL);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        const nombrePadel = data?.nombre;
        const numeroTicketAux = generateID();
        setNumeroTicket(numeroTicketAux);
        setCodigoReserva(
          `${String(nombrePadel)
            .split(" ")
            .map((e) => String(e)[0])
            .toString()
            .replace(/,/g, "")}${numeroTicketAux}`
        );
        if (data && data.valores) {
          const valoresAux = data.valores;

          if (rolObtenido === "Soci@") {
            const socioAlta = valoresAux.filter(
              (e) =>
                e.horario &&
                Array.isArray(e.horario) &&
                e.horario.includes(reserva.horaReserva) &&
                e.tipoValor === "Socio Alta"
            )[0];

            const socioBaja = valoresAux.filter(
              (e) => e.tipoValor === "Socio Baja"
            )[0];

            if (socioAlta) {
              setValor(socioAlta.valor);
            } else {
              setValor(socioBaja.valor);
            }
          } else {
            const duracionAux = Number(duracion);

            if (duracionAux >= 90) {
              const tarifaAlta90 = valoresAux.filter(
                (e) =>
                  e.tipoValor === "Tarifa Alta" &&
                  e.horario &&
                  Array.isArray(e.horario) &&
                  e.horario.includes(reserva.horaReserva)
              )[0];

              const tarifaBaja90 = valoresAux.filter(
                (e) => e.tipoValor === "Tarifa Baja"
              )[0];

              if (tarifaAlta90) {
                setValor(tarifaAlta90.valor);
              } else {
                setValor(tarifaBaja90.valor);
              }
            } else {
              const tarifaAlta60 = valoresAux.filter(
                (e) =>
                  e.tipoValor === "Tarifa Alta 60" &&
                  e.horario &&
                  Array.isArray(e.horario) &&
                  e.horario.includes(reserva.horaReserva)
              )[0];

              const tarifaBaja60 = valoresAux.filter(
                (e) => e.tipoValor === "Tarifa Baja 60"
              )[0];

              if (tarifaAlta60) {
                setValor(tarifaAlta60.valor);
              } else {
                setValor(tarifaBaja60.valor);
              }
            }
          }
        }
      }
    });
  }, [reserva, duracion]);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "padel", ID_PADEL), (doc) => {
      const response = doc.data();

      setActivarWebPay(response.estadoPagoInternet);
    });

    return () => unsub();
  }, [usuario]);

  const [tipoPago, setTipoPago] = useState("LOCAL");

  const handleClose = () => {
    setOpen(false);
  };

  const guardarDatos = () => {
    setIsLoading(true);

    if (rolObtenido === "Soci@" && participantes.length <= 0) {
      AlertaToast({
        action: "Se deben agregar los participantes.",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogReserva"),
      }).fire();
      setIsLoading(false);
      return;
    }
    AlertaModal({
      title: "ingresar la reserva",
      confirmButtonText: "Sí, ingresar",
      target: document.getElementById("dialogReserva"),
    })
      .fire()
      .then(async (result) => {
        if (result.isConfirmed) {
          validateReserva({
            hora_inicio: reserva.horaReserva,
            duracion: Number(duracion),
            fecha_reserva: reserva.fechaReserva,
            uuid_cancha: reserva.uuidCancha,
          })
            .unwrap()
            .then(async (resp) => {
              if (resp.code === 1) {
                setIsLoading(false);
                AlertaToast({
                  action: "Ya existe una reserva.",
                  fullText: true,
                  type: "error",
                  target: document.getElementById("dialogReserva"),
                }).fire();
              } else {
                if (tipoPago === "LOCAL") {
                  const horasReservadas = [reserva.horaReserva];
                  let i = 0;

                  let horaReservadaAux = reserva.horaReserva;

                  while (i < 3) {
                    horaReservadaAux = dayjs(
                      `${new Date(reserva.fechaReserva).getFullYear()}-${
                        new Date(reserva.fechaReserva).getMonth() + 1
                      }-${new Date(
                        reserva.fechaReserva
                      ).getDate()} ${horaReservadaAux}`
                    )
                      .add(30, "minutes")
                      .format("HH:mm");
                    horasReservadas.push(horaReservadaAux);
                    i = i + 1;
                  }
                  const bodySocio = {
                    estadoActual: "CREADO",
                    estados: ["CREADO"],
                    fecha: reserva.fechaReserva,
                    horas: horasReservadas,
                    tipoMoneda: "Pesos",
                    tipoPago: tipoPago,
                    valor: Number(valor),
                    uuidUsuarioCreador: uuidUsuarioCreador,
                    uuidUsuarioReserva: uuidUsuarioCreador,
                    numeroTicket: numeroTicket,
                    tokenWebPay: "",
                    participantes,
                    uuidCancha: reserva.uuidCancha,
                    nombreCancha: reserva.nombreCancha,
                    duracion: Number(duracion),
                  };

                  const bodyCliente = {
                    estadoActual: "CREADO",
                    estados: ["CREADO"],
                    fecha: reserva.fechaReserva,
                    horas: horasReservadas,
                    tipoMoneda: "Pesos",
                    tipoPago: tipoPago,
                    valor: Number(valor),
                    uuidUsuarioCreador: uuidUsuarioCreador,
                    uuidUsuarioReserva: uuidUsuarioCreador,
                    numeroTicket: numeroTicket,
                    tokenWebPay: "",
                    uuidCancha: reserva.uuidCancha,
                    nombreCancha: reserva.nombreCancha,
                    duracion: Number(duracion),
                  };
                  try {
                    await addDoc(
                      collection(db, `canchas/${reserva.uuidCancha}/reservas`),
                      rolObtenido === "Soci@" ? bodySocio : bodyCliente
                    );
                    handleClose();
                    setIsLoading(false);
                  } catch (error) {
                    setIsLoading(false);
                    AlertaToast({
                      action: "Error en el servidor o el usuario ya existe",
                      fullText: true,
                      type: "error",
                      target: document.getElementById("dialogReserva"),
                    }).fire();
                  }
                } else {
                  cookies.set("nombreCancha", reserva.nombreCancha, {
                    path: "/",
                  });
                  cookies.set("uuidCancha", reserva.uuidCancha, { path: "/" });
                  cookies.set("fechaReserva", reserva.fechaReserva, {
                    path: "/",
                  });
                  cookies.set("horaReserva", reserva.horaReserva, {
                    path: "/",
                  });
                  cookies.set("participantes", participantes, { path: "/" });
                  cookies.set("numeroTicket", numeroTicket, { path: "/" });
                  cookies.set("duracion", duracion, { path: "/" });

                  createSessionGetnet({
                    amount: Number(valor),
                    returnUrl: `${urlHost}/dashboard/reserva-pago`,
                    numberCancha: reserva.uuidCancha,
                    uuidUser: uuidUsuarioCreador,
                    fromFront: true,
                  })
                    .unwrap()
                    .then((resp) => {
                      if (resp.processUrl && resp.requestId) {
                        cookies.set("requestId", resp.requestId, { path: "/" });
                        window.location.href = `${resp.processUrl}`;
                      }
                    })
                    .catch(() => {
                      AlertaToast({
                        action: "Error en el servidor",
                        fullText: true,
                        type: "error",
                        target: document.getElementById("dialogReserva"),
                      }).fire();
                    });
                }
              }
            })
            .catch(() => {
              setIsLoading(false);
              AlertaToast({
                action: "Error en el servidor",
                fullText: true,
                type: "error",
                target: document.getElementById("dialogReserva"),
              }).fire();
            });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogReserva"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Reserva</DialogTitle>
          <Grid container margin={3}>
            <Grid item xs={4} md={4}>
              <Stack alignItems="center" justifyContent="center">
                <Typography variant="body1">{numeroTicket}</Typography>
                <Typography variant="h6" color="primary">
                  Número Ticket
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4} md={4}></Grid>
            <Grid item xs={4} md={4}>
              <Stack alignItems="center" justifyContent="center">
                <Typography variant="body1">
                  {codigoReserva.replace(/,/g, "")}
                </Typography>
                <Typography variant="h6" color="primary">
                  Código de reserva
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Divider style={{ margin: 10 }} />
            </Grid>
          </Grid>

          <Stack margin={3} spacing={4}>
            <Stack margin={3} spacing={2}>
              <Stack alignItems="center" justifyContent="center">
                <Typography variant="body1">
                  {`${formatAsCLP(valor)}`}
                </Typography>
                <Typography variant="h6" color="primary">
                  Total a pagar
                </Typography>
              </Stack>

              {rolObtenido === "Soci@" && (
                <Stack>
                  <Stack alignItems="center" justifyContent="center">
                    <Typography variant="h6" color="primary">
                      Participantes
                    </Typography>
                  </Stack>

                  {participantes.length < 3 && (
                    <Stack direction="row">
                      <TextField
                        label="Rut Participante"
                        value={rutParticipante}
                        onChange={(e) => {
                          setRutParticipante(e.target.value);
                        }}
                        fullWidth
                      />
                      <LoadingButton
                        variant="contained"
                        onClick={obtenerDatosUsuario}
                      >
                        Buscar
                      </LoadingButton>
                    </Stack>
                  )}

                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                  >
                    {participantes.map((element, idx) => (
                      <ListItem key={idx} disablePadding>
                        <ListItemIcon>
                          <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={element.nombre} />
                        <IconButton
                          onClick={() => {
                            AlertaModal({
                              title: "eliminar el participante",
                              confirmButtonText: "Sí, eliminar",
                              target: document.getElementById("dialogReserva"),
                            })
                              .fire()
                              .then((result) => {
                                if (result.isConfirmed) {
                                  setValor(valor - valorSocio);
                                  setParticipantes(
                                    participantes.filter(
                                      (e) => e.rut !== element.rut
                                    )
                                  );
                                }
                              });
                          }}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              )}

              <Stack alignItems="center" justifyContent="center">
                <Typography variant="h6" color="primary">
                  Método de pago
                </Typography>
              </Stack>

              <FormControl>
                <RadioGroup
                  value={tipoPago}
                  onChange={(event) => {
                    setTipoPago(event.target.value);
                  }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="LOCAL"
                    control={<Radio />}
                    label="Pago en el local"
                  />
                  {activarWebPay && (
                    <FormControlLabel
                      value="GETNET"
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            marginTop: 10,
                          }}
                        >
                          Tarjeta de crédito, débito o prepago.
                          <img src="/static/BOTON_GETNET_W120.png" />
                          Paga seguro todo lo que necesitas con Getnet
                          utilizando tus tarjetas de crédito, débito y prepago,
                          de todos los emisores nacionales e internacionales.
                        </div>
                      }
                    />
                  )}
                </RadioGroup>
              </FormControl>

              <Stack alignItems="center" justifyContent="center">
                <Typography variant="h6" color="primary">
                  Duración Reserva
                </Typography>
              </Stack>

              <FormControl>
                <FormLabel id="duracion-reserva">Duración Reserva</FormLabel>
                <RadioGroup
                  aria-labelledby="duracion-reserva"
                  value={duracion}
                  onChange={(event) => {
                    setDuracion(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="60"
                    control={<Radio />}
                    label="60 min."
                  />
                  <FormControlLabel
                    value="90"
                    control={<Radio />}
                    label="90 min."
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Stack>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Divider style={{ margin: 10 }} />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cerrar
            </Button>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={guardarDatos}
              color="primary"
            >
              Pagar
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {mostrarNombre && (
          <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            id="dialogNombre"
            fullWidth
            maxWidth="md"
          >
            <DialogTitle id="form-dialog-title">
              Ingresar Nombre Participante
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                <TextField
                  value={nombreParticipante}
                  onChange={(e) => {
                    setNombreParticipante(e.target.value);
                  }}
                  fullWidth
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setMostrarNombre(false);
                }}
              >
                Cerrar
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (nombreParticipante === "") {
                    AlertaToast({
                      action: "Ingresar un nombre válido.",
                      fullText: true,
                      type: "error",
                      target: document.getElementById("dialogNombre"),
                    }).fire();
                    return;
                  }
                  setValor(valor + valorSocio);
                  setParticipantes([
                    ...participantes,
                    {
                      rut: String(rutParticipante).toUpperCase(),
                      nombre: String(nombreParticipante),
                    },
                  ]);
                  setMostrarNombre(false);
                  setNombreParticipante("");
                  setRutParticipante("");
                }}
                color="primary"
              >
                Agregar
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </>
  );
}
