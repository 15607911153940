import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

import Page from "../components/Page";
import { Titulo } from "src/components/Titulo";
import GraficoIngresosGastos from "src/components/Dashboard/GraficoIngresosGastos";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { ID_PADEL } from "src/utils/urlBack_End";
export default function DashboardApp() {
  const { user } = useSelector((state) => state.auth?.user);
  const [canchas, setCanchas] = useState([]);

  useEffect(() => {
    const q = query(collection(db, `padel/${ID_PADEL}/canchas`));
    getDocs(q).then((querySnapshot) => {
      const canchasAux = [];
      querySnapshot.forEach((doc) => {
        canchasAux.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setCanchas(canchasAux);
    });
  }, []);

  return (
    <Page title="Padel Club Rengo | Hola">
      <Container>
        <Titulo>
          <Typography variant="h4">Bienvenido(a) {user?.nombre}</Typography>
        </Titulo>

        <GraficoIngresosGastos canchas={canchas} />
      </Container>
    </Page>
  );
}
