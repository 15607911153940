import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import {
  Card,
  CardHeader,
  Container,
  Grid,
  Typography,
  CardMedia,
  Button,
  Alert,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import Page from "../components/Page.js";
import Permisos from "../utils/Permisos.js";
import { Titulo } from "src/components/Titulo";
import { db } from "../utils/firebaseInit.js";
import { collection, onSnapshot, query } from "firebase/firestore";
import { ID_PADEL } from "src/utils/urlBack_End.js";
import {
  SportsTennis as SportsTennisIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { ValidarPermisos } from "src/components/ValidarPermisos.js";
import DialogCrearCancha from "src/components/canchas/DialogCrearCancha.js";

export default function Canchas() {
  const [openCrear, setOpenCrear] = useState(false);

  const [canchas, setCanchas] = useState([]);
  const [isLoadingCanchas, setIsLoadingCanchas] = useState(false);

  useEffect(() => {
    setIsLoadingCanchas(true);
    const q = query(collection(db, `padel/${ID_PADEL}/canchas`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        cities.push({
          id: doc.id,
          ...data,
        });
      });
      setCanchas(cities);
      setIsLoadingCanchas(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Page title="Padel Club Rengo | Canchas">
      <Container>
        <Titulo>
          <Typography variant="h4">Canchas</Typography>
        </Titulo>

        <Stack direction={"row-reverse"} padding="20px">
          <ValidarPermisos permission={Permisos.agregarCanchas}>
            <Button
              onClick={() => {
                setOpenCrear(true);
              }}
              variant="contained"
            >
              <AddIcon /> Agregar Cancha
            </Button>
          </ValidarPermisos>
        </Stack>

        <Stack padding="20px">
          <Alert severity="info">
            Solo se pueden agregar o eliminar canchas.
          </Alert>
        </Stack>

        {!isLoadingCanchas && canchas ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {canchas.length >= 1 ? (
                canchas.map((e, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <Card sx={{ maxWidth: 345, height: 400 }}>
                      <CardHeader
                        avatar={<SportsTennisIcon />}
                        title={e.nombre}
                        subheader={e.activa ? "Operando" : "En Mantención"}
                      />
                      <br></br>
                      <CardMedia
                        component="img"
                        height="194"
                        image={e.url}
                        alt={e.nombre}
                      />
                    </Card>
                  </Grid>
                ))
              ) : (
                <Stack
                  alignContent="center"
                  justifyItems="center"
                  justifyContent="center"
                  mt={10}
                  ml="40%"
                >
                  <Typography gutterBottom align="center" variant="subtitle1">
                    Sin resultados
                  </Typography>
                  <Typography variant="body2" align="center">
                    No se han encontrado elementos.
                  </Typography>
                </Stack>
              )}
            </Grid>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" mt={10}>
            {isLoadingCanchas && (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </Box>
        )}
        {openCrear && (
          <DialogCrearCancha open={openCrear} setOpen={setOpenCrear} />
        )}
      </Container>
    </Page>
  );
}
