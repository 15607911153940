import { useRef, useState, useEffect } from "react";
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import bellFill from "@iconify/icons-eva/bell-fill";
import doneAllFill from "@iconify/icons-eva/done-all-fill";
import { Icon } from "@iconify/react";
import { alpha } from "@mui/material/styles";

import MenuPopover from "../../components/MenuPopover";
import Scrollbar from "../../components/Scrollbar";

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        color={open ? "primary" : "default"}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}>
        <Badge
          //badgeContent={}
          color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        style={{ overflowY: "scroll" }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}>
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificaciones</Typography>
          </Box>

          <IconButton color="primary">
            <Icon icon={doneAllFill} width={20} height={20} />
          </IconButton>
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}></ListSubheader>
            }>
            {/* <NotificationItem handleClose={handleClose} /> */}
          </List>

          <Divider />

          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}></ListSubheader>
            }></List>
          <Divider />
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
