import { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import {
  alpha,
  useTheme,
  experimentalStyled as styled,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getAdminRoutes, getUsuarioRoutes, getReservasRoutes } from "../routes";
import { useSelector } from "react-redux";
import { Accordion, AccordionSummary } from "@mui/material";
import dayjs from "dayjs";
dayjs.locale("es");

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItem button disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const AccordionStyle = styled((props) => <AccordionSummary {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: "relative",
    textTransform: "capitalize",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    "&:before": {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: "none",
      position: "absolute",
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active, permisos }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children, child } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    background: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    ...{ "&:before": { display: "block" } },
  };

  const activeRootStyleListItem = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  };
  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  let existePermiso = false;

  if (item.permisos === "ALL") {
    existePermiso = true;
  } else {
    permisos.forEach((element) => {
      const existePrmisoArray = item.permisos.includes(element);
      if (existePrmisoArray) {
        existePermiso = true;
      }
    });
  }

  if (children && existePermiso) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText
                    disableTypography
                    primary={title}
                  ></ListItemText>
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  } else if (existePermiso) {
    const isActiveSub = active(path);
    if (!child) {
      return (
        <Accordion
          disableGutters
          TransitionProps={{ unmountOnExit: true }}
          sx={{
            ...(isActiveSub && activeRootStyleListItem),
          }}
        >
          <AccordionStyle
            component={RouterLink}
            to={path}
            sx={{
              ...(isActiveSub && activeRootStyle),
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            <ListItemText disableTypography primary={title} />
            {info && info}
          </AccordionStyle>
        </Accordion>
      );
    } else {
      return (
        <Accordion
          disableGutters
          TransitionProps={{ unmountOnExit: true }}
          sx={{
            ...(isActiveSub && activeRootStyleListItem),
          }}
        >
          <AccordionStyle
            sx={{
              ...(isActiveSub && activeRootStyle),
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            <ListItemText disableTypography secondary={title} />
            {info && info}
          </AccordionStyle>
        </Accordion>
      );
    }
  }
  return <></>;
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const { rol: rolObtenido, permisos = [] } = useSelector(
    (state) => state.auth?.user
  );
  const filteredNavConfig = filterNavConfig();

  function filterNavConfig() {
    if (rolObtenido !== "Cliente") {
      return getAdminNavConfig(permisos);
    } else if (rolObtenido !== "Reservas") {
      return getReservasRoutes();
    } else {
      return getUsuarioNavConfig();
    }
  }

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  // Revisa si las rutas a las que tiene acceso un usuario contienen cierta
  // ruta
  function routesContainPath(routes, path) {
    let pathWithNoSpaces = path.replaceAll(" ", "-");
    pathWithNoSpaces = removeAccents(pathWithNoSpaces);
    return routes.find((r) => r.path === pathWithNoSpaces);
  }

  function getAdminNavConfig(permisos) {
    return navConfig.filter((el) =>
      routesContainPath(getAdminRoutes(permisos, ""), el.title)
    );
  }

  function getUsuarioNavConfig(permisos) {
    return navConfig.filter((el) =>
      routesContainPath(getUsuarioRoutes(), el.title)
    );
  }

  function getReservasNavConfig(permisos) {
    return navConfig.filter((el) =>
      routesContainPath(getReservasRoutes(), el.title)
    );
  }

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other} style={{ innerHeight: "100vh" }}>
      <List disablePadding>
        {filteredNavConfig &&
          filteredNavConfig.map((item) => (
            <NavItem
              key={item.title}
              item={item}
              active={match}
              permisos={permisos}
            />
          ))}
      </List>
    </Box>
  );
}
