import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { LoadingButton } from "@mui/lab";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { validateRut, deconstructRut } from "@fdograph/rut-utilities";
import { usuariosApi } from "src/services/usuariosApi";
import { AlertaModal, AlertaToast } from "../../AlertaSwall";
import { Box, Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import dayjs from "dayjs";

export default function DialogCrearUsuario(props) {
  const { open, setOpen, selectDefinidoUser } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [crearUsuario] = usuariosApi.useAddMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    rut: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
    nombre: Yup.string().required("Contraseña requerida"),
    direccion: Yup.string().required("Dirección requerida"),
    correo: Yup.string().email("Correo no válido").required("Correo requerido"),
    telefono: Yup.string().test(
      "telefono test",
      "Teléfono no válido",
      (value) => matchIsValidTel(value)
    ),
    tipoUser: Yup.string().required("Tipo Usuario requerido"),
  });

  const formik = useFormik({
    initialValues: {
      rut: "",
      nombre: "",
      correo: "",
      telefono: "+56",
      direccion: "",
      tipoUser: selectDefinidoUser ? null : "Empresa",
      fechaNacimiento: dayjs(),
      fechaInicioContrato: dayjs(),
      tipoMembresia: "",
      numeroSerieCarnet: "",
      totalMembresia: 0,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setTouched,
    setFieldValue,
  } = formik;

  const guardarDatos = () => {
    setIsLoading(true);

    const tipoUserAux = getFieldProps("tipoUser").value;

    const newUser = {
      rut:
        deconstructRut(getFieldProps("rut").value.toUpperCase()).digits +
        deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier,
      displayName: getFieldProps("nombre").value,
      email: getFieldProps("correo").value,
      phoneNumber: getFieldProps("telefono").value,
      direccion: getFieldProps("direccion").value,
      type_user: tipoUserAux,
      numeroSerieCarnet: "",
      fechaNacimiento: "",
      fechaInicioContrato: "",
      totalMembresia: 0,
      tipoMembresia: "",
      edad: 0,
      fechaTerminoContrato: "",
    };

    AlertaModal({
      title: "agregar al usuario",
      confirmButtonText: "Sí, agregar",
      target: document.getElementById("dialogCrearUsuario"),
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          crearUsuario(newUser)
            .unwrap()
            .then(async () => {
              AlertaToast({
                element: "usuario",
                action: "creado",
              }).fire();
              setIsLoading(false);
              handleClose();
            })
            .catch((error) => {
              AlertaToast({
                action: "Error en el servidor o el usuario ya existe",
                fullText: true,
                type: "error",
                target: document.getElementById("dialogCrearUsuario"),
              }).fire();
              setIsLoading(false);
            });
        }
      })
      .finally(() => {
        setIsLoading(true);
      });
  };

  const handlePhoneNumber = (v) => {
    setFieldValue("telefono", v);
  };

  const handleTipoUser = (v) => {
    setFieldValue("tipoUser", v.target.value);
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogCrearUsuario"
        >
          <DialogTitle id="form-dialog-title">Creación Usuario</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Complete la información del usuario
            </DialogContentText>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  required
                  margin="dense"
                  id="rut"
                  label="Rut"
                  fullWidth
                  {...getFieldProps("rut")}
                  error={Boolean(touched.rut && errors.rut)}
                  helperText={touched.rut && errors.rut}
                />
                <TextField
                  required
                  margin="dense"
                  id="nombre"
                  label="Nombre"
                  fullWidth
                  {...getFieldProps("nombre")}
                  error={Boolean(touched.nombre && errors.nombre)}
                  helperText={touched.nombre && errors.nombre}
                />
                <TextField
                  required
                  style={{ paddingRight: 10 }}
                  margin="dense"
                  id="direccion"
                  label="Dirección"
                  fullWidth
                  {...getFieldProps("direccion")}
                  error={Boolean(touched.direccion && errors.direccion)}
                  helperText={touched.direccion && errors.direccion}
                />
                <TextField
                  required
                  style={{ paddingRight: 10 }}
                  margin="dense"
                  id="correo"
                  label="Correo"
                  type="email"
                  fullWidth
                  {...getFieldProps("correo")}
                  error={Boolean(touched.correo && errors.correo)}
                  helperText={touched.correo && errors.correo}
                />
                <MuiTelInput
                  required
                  margin="dense"
                  id="telefono"
                  label="Teléfono"
                  forceCallingCode
                  defaultCountry="CL"
                  preferredCountries={["CL"]}
                  fullWidth
                  onFocus={() =>
                    setTouched({ ...touched, telefono: true }, true)
                  }
                  value={getFieldProps("telefono").value}
                  onChange={(e) => handlePhoneNumber(e)}
                  onBlur={getFieldProps("telefono").onBlur}
                  error={Boolean(touched.telefono && errors.telefono)}
                  helperText={touched.telefono && errors.telefono}
                />
                {selectDefinidoUser && (
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Tipo Usuario
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="tipoUser"
                        value={getFieldProps("tipoUser").value}
                        label="Tipo Usuario"
                        onChange={(e) => handleTipoUser(e)}
                        error={Boolean(touched.tipoUser && errors.tipoUser)}
                        helperText={touched.tipoUser && errors.tipoUser}
                      >
                        <MenuItem value="Cliente">Cliente</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}

                <DialogActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                    disabled={isLoading}
                  >
                    Cerrar
                  </Button>
                  <LoadingButton
                    loading={false}
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    Agregar
                  </LoadingButton>
                </DialogActions>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
