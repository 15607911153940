import PropTypes from "prop-types";
// material

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({
  searchQuery = "",
  searchFilter = false,
  customMsg = "",
  ...other
}) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Sin resultados
      </Typography>
      <Typography variant="body2" align="center">
        {customMsg ||
          (searchFilter
            ? "No se han hallado resultados para la búsqueda " +
              "o para la fecha seleccionada."
            : "No se han encontrado elementos.")}
      </Typography>
    </Paper>
  );
}
