import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Page from "../components/Page";
import { Titulo } from "src/components/Titulo";
import { Box, Skeleton, Stack } from "@mui/material";
import TabsComponente from "src/components/TabsComponente";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { ID_PADEL } from "src/utils/urlBack_End";
import { ReservaCanchasCliente } from "src/components/reservasCliente/ReservaCanchasCliente";
import { PortadaReservaCliente } from "src/components/reservasCliente/PortadaReservaCliente";
export default function ReservasCliente() {
  const [isLoadingCanchas, setIsLoadingCanchas] = useState(false);
  const [canchas, setCanchas] = useState([]);
  const [padel, setPadel] = useState(null);

  const diaSemana = new Date().getDay() === 0 ? 7 : new Date().getDay();

  const [intervaloHorario, setIntervaloHorario] = useState(null);

  useEffect(() => {
    const q = query(
      collection(db, `padel/${ID_PADEL}/horarios`),
      where("dia", "==", diaSemana)
    );
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setIntervaloHorario(doc.data());
      });
    });
  }, [diaSemana]);

  useEffect(() => {
    const docRef = doc(db, "padel", ID_PADEL);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data) {
          setPadel(data.permisos);
        }
      } else {
      }
    });
  }, []);

  useEffect(() => {
    setIsLoadingCanchas(true);
    const q = query(collection(db, `padel/${ID_PADEL}/canchas`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const canchasAux = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        canchasAux.push({
          id: doc.id,
          ...data,
        });
      });
      setCanchas(canchasAux);
      setIsLoadingCanchas(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Page title="Padel Club Rengo | Reservas">
      <Container>
        <Titulo>
          <Typography variant="h4">Reservas</Typography>
        </Titulo>
      </Container>

      <Stack marginTop={5}>
        {!isLoadingCanchas ? (
          <TabsComponente
            encabezados={["", ...canchas.map((e) => e.nombre)]}
            tabs={[
              <PortadaReservaCliente />,
              ...canchas.map((e) => (
                <ReservaCanchasCliente
                  cancha={e}
                  padel={padel}
                  intervaloHorario={intervaloHorario}
                />
              )),
            ]}
            orientacion={"horizontal"}
          />
        ) : (
          <Box display="flex" justifyContent="center" mt={10}>
            {isLoadingCanchas && (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </Box>
        )}
      </Stack>
    </Page>
  );
}
