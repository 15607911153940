import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "src/utils/firebaseInit";

export default function ForgotPassword({ open, onClose, title }) {
  const [error, setError] = useState("");

  const LoginSchema = Yup.object().shape({
    correo: Yup.string().email("Correo no válido").required("Correo requerido"),
  });

  /**
   * Objeto encargado de validar los valores ingresados al formulario, según las
   * validaciones entregadas por LoginSchema.
   */
  const formik = useFormik({
    initialValues: {
      correo: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  /**
   * Se encarga de guardar los datos ingresados por el usuario, ya validados
   * a la variable empleado, que es la que se guardará en la bd.
   */
  const guardarDatos = async () => {
    setSubmitting(true);
    setError("");

    sendPasswordResetEmail(auth, getFieldProps("correo").value)
      .then(() => {
        onClose("Correo enviado con éxito");
      })
      .catch(() => {
        const errorAux =
          "Ha ocurrido un error. Revise que el correo sea el correcto.";
        setError(errorAux);
        setSubmitting(false);
      });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Recuperar Contraseña</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Rellene el formulario y le enviaremos un correo con instrucciones para
          restablecer su contraseña.
        </DialogContentText>

        {error && (
          <Alert severity="error" style={{ marginTop: 5, marginBottom: 5 }}>
            {error}
          </Alert>
        )}

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <TextField
              required
              margin="dense"
              id="correo"
              label="Correo"
              type="email"
              fullWidth
              {...getFieldProps("correo")}
              error={Boolean(touched.correo && errors.correo)}
              helperText={touched.correo && errors.correo}
            />

            <DialogActions style={{ marginTop: 15 }}>
              <LoadingButton
                onClick={() => onClose("")}
                color="error"
                disabled={isSubmitting}
              >
                Cancelar
              </LoadingButton>

              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                loading={isSubmitting}
              >
                Aceptar
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
