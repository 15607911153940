import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Page from "../components/Page";
import { Titulo } from "src/components/Titulo";
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import dayjs from "dayjs";
import { formatAsCLP } from "src/utils/formatNumber";
import { Cancel } from "@mui/icons-material";
import Cookies from "universal-cookie";
import { getnetApi } from "src/services/getnetApi";
import { useSelector } from "react-redux";
import {
  AlertaModalInformativa,
  AlertaToast,
} from "src/components/AlertaSwall";
export default function IngresoReserva() {
  const [reserva, setReserva] = useState(null);
  const [mostrar, setMostrar] = useState(false);
  const [numeroTicket, setNumeroTicket] = useState(null);

  const urlHost = `${window.location.protocol}//${window.location.host}`;

  const { id: uuidUsuarioCreador } = useSelector((state) => state.auth.user);

  const [createSessionGetnet] = getnetApi.useCreateMutation();
  const [statusGetnet] = getnetApi.useStatusMutation();

  const [cargandoDataReserva, setCargandoDataReserva] = useState(false);
  const cookies = new Cookies();

  const obtenerReserva = async () => {
    cookies.remove("uuidCancha");
    cookies.remove("uuidReserva");
    cookies.remove("requestId");
    setCargandoDataReserva(true);
    const canchasRef = query(
      collection(db, "canchas"),
      where("activa", "==", true)
    );

    const canchas = [];

    const snapshotCanchas = await getDocs(canchasRef);
    snapshotCanchas.forEach((doc) => {
      canchas.push(doc.id);
    });
    const fecha = dayjs().format("YYYY-MM-DD");

    let reservaAux = null;
    await Promise.all(
      canchas.map(async (e) => {
        const reservasRef = query(
          collection(db, `canchas/${e}/reservas`),
          where("fecha", "==", fecha),
          where("numeroTicket", "==", Number(numeroTicket))
        );
        const snapshotReservas = await getDocs(reservasRef);
        snapshotReservas.forEach((doc) => {
          reservaAux = {
            id: doc.id,
            reserva: doc.data(),
            canchaID: e,
          };
        });
      })
    );
    if (reservaAux && reservaAux !== null) {
      const docRef = doc(db, "usuarios", reservaAux.reserva.uuidUsuarioReserva);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setReserva({ ...reservaAux, usuario: docSnap.data() });
      } else {
        setReserva({ ...reservaAux, usuario: null });
      }
    } else {
      setReserva({ error: true });
    }
    setCargandoDataReserva(false);
  };

  const generarPago = async () => {
    cookies.set("uuidCancha", reserva.reserva.canchaID, { path: "/" });
    cookies.set("uuidReserva", reserva.reserva.id, { path: "/" });

    createSessionGetnet({
      amount: Number(reserva.reserva.valor),
      returnUrl: `${urlHost}/dashboard/inicio`,
      numberCancha: reserva.reserva.uuidCancha,
      uuidUser: uuidUsuarioCreador,
      fromFront: true,
    })
      .unwrap()
      .then((resp) => {
        if (resp.processUrl && resp.requestId) {
          cookies.set("requestId", resp.requestId, { path: "/" });
          window.location.href = `${resp.processUrl}`;
        }
      })
      .catch(() => {
        AlertaToast({
          action: "Error en el servidor",
          fullText: true,
          type: "error",
        }).fire();
      });
  };

  const ingresar = async () => {
    try {
      // Validar SI ES SOCIO

      if (
        reserva.reserva.usuario &&
        reserva.reserva.usuario.empresas[0] &&
        reserva.reserva.usuario.empresas[0].tipoUsuario === "Soci@"
      ) {
      } else {
        const reservaRef = doc(
          db,
          `canchas/${reserva.reserva.uuidCancha}/reservas`,
          reserva.reserva.uuidReserva
        );
        await updateDoc(reservaRef, {
          estadoActual: "INGRESADO",
          estados: arrayUnion("FINALIZADO", "INGRESADO"),
        });

        AlertaModalInformativa({
          title: "Bienvenido a Padel Club Rengo",
          text: `Ya se puede hacer uso de la cancha ${reserva.reserva.nombreCancha}`,
          width: 474,
        })
          .fire()
          .then(() => {
            setReserva(null);
            setMostrar(false);
            setNumeroTicket("");
          });
      }
    } catch (error) {
      AlertaToast({
        action: "Error en el servidor",
        fullText: true,
        type: "error",
      }).fire();
    }
  };

  useEffect(() => {
    const uuidCancha = cookies.get("uuidCancha");
    const uuidReserva = cookies.get("uuidReserva");
    const requestId = cookies.get("requestId");

    if (uuidCancha && uuidReserva && reserva === null) {
      statusGetnet(requestId)
        .unwrap()
        .then(async (response2) => {
          if (response2.status.reason === "00") {
            try {
              const reservaRef = doc(
                db,
                `canchas/${uuidCancha}/reservas`,
                uuidReserva
              );
              await updateDoc(reservaRef, {
                estadoActual: "PAGADO",
                tipoPago: "GETNET",
                estados: ["CREADO", "PAGADO"],
                requestId,
                webpay: response2,
              });

              try {
                setCargandoDataReserva(true);

                const reservaSnap = await getDoc(reservaRef);

                let reservaAux = null;

                if (reservaSnap.exists()) {
                  reservaAux = reservaSnap.data();
                }

                if (reservaAux && reservaAux !== null) {
                  const docRef = doc(
                    db,
                    "usuarios",
                    reservaAux.reserva.uuidUsuarioReserva
                  );
                  const docSnap = await getDoc(docRef);

                  if (docSnap.exists()) {
                    setReserva({ ...reservaAux, usuario: docSnap.data() });
                  } else {
                    setReserva({ ...reservaAux, usuario: null });
                  }
                } else {
                  setReserva({ error: true });
                }
                cookies.remove("uuidCancha");
                cookies.remove("uuidReserva");
                cookies.remove("requestId");
                setCargandoDataReserva(false);
              } catch (error) {
                setCargandoDataReserva(false);
              }
            } catch (error) {
              cookies.remove("uuidCancha");
              cookies.remove("uuidReserva");
              cookies.remove("requestId");
              AlertaToast({
                action:
                  "Error al procesar el pago. Comuniquese con nuestro equipo",
                fullText: true,
                type: "error",
              }).fire();
            }
          } else {
            cookies.remove("uuidCancha");
            cookies.remove("uuidReserva");
            cookies.remove("requestId");
            AlertaToast({
              action:
                "El pago no se ha procesado o se ha cancelado el proceso.",
              fullText: true,
              type: "error",
            }).fire();
          }
        })
        .catch(() => {
          cookies.remove("uuidCancha");
          cookies.remove("uuidReserva");
          cookies.remove("requestId");
          AlertaToast({
            action: "Error al validar el pago.",
            fullText: true,
            type: "error",
          }).fire();
        });
    }
  }, []);
  return (
    <Page title="Padel Club Rengo | Ingreso">
      <Container>
        <Titulo>
          <Typography variant="h4">Ingreso Reservas</Typography>
        </Titulo>

        <Stack marginTop={5} spacing={2}>
          <TextField
            fullWidth
            label="Número Reserva"
            value={numeroTicket}
            type="number"
            onChange={(e) => {
              if (String(e.target.value) === "") {
                setReserva(null);
                setMostrar(false);
              } else {
                setMostrar(true);
              }
              setNumeroTicket(e.target.value);
            }}
          />
          {mostrar && (
            <Button
              fullWidth
              variant="contained"
              onClick={obtenerReserva}
              disabled={String(numeroTicket).length <= 3}
            >
              Ingresar
            </Button>
          )}

          {reserva && reserva.reserva ? (
            <Card>
              <CardHeader
                title={`Estado Reserva: ${reserva.reserva.estadoActual}`}
              />
              <CardContent>
                <Stack spacing={2}>
                  <Grid container>
                    <Grid item xs={5} md={5}>
                      <Typography variant="h6" color="primary">
                        Hora Inicio Reserva
                      </Typography>
                    </Grid>
                    <Grid item xs={2} md={2}></Grid>
                    <Grid item xs={5} md={5}>
                      <Typography variant="body1" color="primary">
                        {reserva.reserva.horas[0]}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5} md={5}>
                      <Typography variant="h6" color="primary">
                        Cancha
                      </Typography>
                    </Grid>
                    <Grid item xs={2} md={2}></Grid>
                    <Grid item xs={5} md={5}>
                      <Typography variant="body1" color="primary">
                        {reserva.reserva.nombreCancha}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5} md={5}>
                      <Typography variant="h6" color="primary">
                        Tipo Pago
                      </Typography>
                    </Grid>
                    <Grid item xs={2} md={2}></Grid>
                    <Grid item xs={5} md={5}>
                      <Typography variant="body1" color="primary">
                        {reserva.reserva.tipoPago}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5} md={5}>
                      <Typography variant="h6" color="primary">
                        Valor
                      </Typography>
                    </Grid>
                    <Grid item xs={2} md={2}></Grid>
                    <Grid item xs={5} md={5}>
                      <Typography variant="body1" color="primary">
                        {formatAsCLP(reserva.reserva.valor)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              </CardContent>
              <CardActions>
                {reserva.reserva.estadoActual === "CREADO" && (
                  <Button variant="contained" fullWidth onClick={generarPago}>
                    Pagar Online
                  </Button>
                )}
                {(reserva.reserva.estadoActual === "PAGADO" ||
                  reserva.reserva.valor <= 0) && (
                  <Button variant="contained" fullWidth onClick={ingresar}>
                    Ingresar
                  </Button>
                )}
              </CardActions>
            </Card>
          ) : (
            reserva &&
            reserva.error && (
              <Card>
                <CardContent>
                  <Stack spacing={2} textAlign="center">
                    <Typography variant="h4" color="error">
                      <Cancel color="error" fontSize="large" />
                    </Typography>
                    <Typography variant="h4" color="error">
                      La reserva no ha podido ser encontrada.
                    </Typography>
                  </Stack>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setNumeroTicket("");
                      setReserva(null);
                      setMostrar(false);
                    }}
                  >
                    OK
                  </Button>
                </CardActions>
              </Card>
            )
          )}
        </Stack>
        <Backdrop
          sx={{ color: "#000000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={cargandoDataReserva}
        >
          <CircularProgress color="success" />
        </Backdrop>
      </Container>
    </Page>
  );
}
