const Permisos = {
  visualizarUsuarios: "Visualizar Usuarios",
  eliminarUsuarios: "Eliminar Usuarios",
  editarUsuarios: "Editar Usuarios",
  agregarUsuarios: "Agregar Usuarios",

  visualizarPermisos: "Visualizar Permisos",
  eliminarPermisos: "Eliminar Permisos",
  editarPermisos: "Editar Permisos",
  agregarPermisos: "Agregar Permisos",

  visualizarClientes: "Visualizar Clientes",
  eliminarClientes: "Eliminar Clientes",
  editarClientes: "Editar Clientes",
  agregarClientes: "Agregar Clientes",

  visualizarNoticias: "Visualizar Noticias",
  eliminarNoticias: "Eliminar Noticias",
  editarNoticias: "Editar Noticias",
  agregarNoticias: "Agregar Noticias",

  visualizarTarifas: "Visualizar Tarifas",
  eliminarTarifas: "Eliminar Tarifas",
  editarTarifas: "Editar Tarifas",
  agregarTarifas: "Agregar Tarifas",

  visualizarReportes: "Visualizar Reportes",
  eliminarReportes: "Eliminar Reportes",
  editarReportes: "Editar Reportes",
  agregarReportes: "Agregar Reportes",

  visualizarReservas: "Visualizar Reservas",
  eliminarReservas: "Eliminar Reservas",
  editarReservas: "Editar Reservas",
  agregarReservas: "Agregar Reservas",

  visualizarCanchas: "Visualizar Canchas",
  eliminarCanchas: "Eliminar Canchas",
  editarCanchas: "Editar Canchas",
  agregarCanchas: "Agregar Canchas",

  visualizarConvenios: "Visualizar Convenios",
  eliminarConvenios: "Eliminar Convenios",
  editarConvenios: "Editar Convenios",
  agregarConvenios: "Agregar Convenios",

  visualizarProductos: "Visualizar Productos",
  eliminarProductos: "Eliminar Productos",
  editarProductos: "Editar Productos",
  agregarProductos: "Agregar Productos",

  visualizarImagenes: "Visualizar Imagenes",
  eliminarImagenes: "Eliminar Imagenes",
  agregarImagenes: "Agregar Imagenes",

  visualizarPagos: "Visualizar Pagos",
  eliminarPagos: "Eliminar Pagos",
  agregarPagos: "Agregar Pagos",
  editarPagos: "Editar Pagos",
};

const tienePermiso = (permisos, target) => {
  return permisos.some((item) => {
    return (
      item.replace(/ /g, "").toLowerCase() ===
        target.replace(/ /g, "").toLowerCase() && item
    );
  });
};

export default Permisos;
export { tienePermiso };
