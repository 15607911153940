import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Page from "../components/Page";
import { Titulo } from "src/components/Titulo";
import { Box, Skeleton, Stack } from "@mui/material";
import Permisos from "src/utils/Permisos";
import Tabla from "src/components/tabla/Tabla";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import { useNavigate } from "react-router-dom";
import { reservasApi } from "src/services/reservasApi";
import dayjs from "dayjs";
import { formatAsCLP } from "src/utils/formatNumber";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
export default function ReservasAll() {
  const navigate = useNavigate();

  const {
    data: reservasAll = {},
    isLoading: isLoadingReservasAll,
    isSuccess: isSuccessReservasAll,
  } = reservasApi.useGetReservasAll2Query();

  const handleViewUser = (row) => {
    navigate(`${row.id}`);
  };

  const cancelarReserva = (idUsuario) => {
    if (idUsuario.estadoActual === "CANCELADO") {
      AlertaToast({
        action: "La reserva ya se encuentra cancelada",
        fullText: true,
        type: "info",
      }).fire();
    } else {
      AlertaModal({
        title: "cancelar reserva",
        confirmButtonText: "Sí, cancelar",
      })
        .fire()
        .then((response) => {
          if (response.isConfirmed) {
            updateDoc(
              doc(db, `canchas/${idUsuario.idCancha}/reservas`, idUsuario.id),
              {
                estadoActual: "CANCELADO",
                estados: arrayUnion("CANCELADO"),
              }
            )
              .then(() => {
                AlertaToast({
                  element: "reserva",
                  action: "cancelada",
                }).fire();
              })
              .catch(() => {
                AlertaToast({
                  action: "Error en el servidor",
                  fullText: true,
                  type: "error",
                }).fire();
              });
          }
        });
    }
  };

  return (
    <Page title="Padel Club Rengo | Todas las reservas">
      <Container>
        <Titulo>
          <Typography variant="h4">Información de Reservas</Typography>
        </Titulo>

        {!isLoadingReservasAll && reservasAll && reservasAll.results ? (
          <Stack marginTop={5} spacing={2}>
            <Tabla
              encabezados={[
                {
                  id: "id",
                  label: "ID",
                },
                {
                  id: "numeroTicket",
                  label: "Número Ticket",
                },
                {
                  id: "nombreCancha",
                  label: "Nombre Cancha",
                },
                {
                  id: "nombreUsuario",
                  label: "Usuario",
                },
                {
                  id: "valor",
                  label: "Valor",
                },
                {
                  id: "fecha",
                  label: "Fecha",
                },
                {
                  id: "estadoActual",
                  label: "Estado Reserva",
                },
                {
                  id: "tipoPago",
                  label: "Tipo Pago",
                },
                {
                  id: "horaInicio",
                  label: "Hora Inicio",
                },
                { id: "", label: "Acciones" },
              ]}
              buscarPor={[
                "nombreCancha",
                "nombreUsuario",
                "estadoActual",
                "fecha",
              ]}
              etiquetaBusqueda="Buscar por cancha, horario, estado reserva o fecha"
              eliminarElemento={(e) => cancelarReserva(e)}
              permisosEliminar={Permisos.eliminarReservas}
              editarElemento={(e) => {
                handleViewUser(e);
              }}
              permisosEditar={Permisos.editarReservas}
              data={
                isSuccessReservasAll
                  ? reservasAll.results.map((e) => {
                      return {
                        ...e,
                        fecha: dayjs(e.fecha).format("DD/MM/YYYY"),
                        horaInicio: e.horas[0],
                        valor: formatAsCLP(e.valor),
                      };
                    })
                  : []
              }
              botones={[]}
              deleteObject
            />
          </Stack>
        ) : (
          <Box display="flex" justifyContent="center" mt={10}>
            <Skeleton variant="rectangular" width="100%" height="500px" />
          </Box>
        )}
      </Container>
    </Page>
  );
}
