import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchBox(props) {
  const { onSearch, label, value } = props;

  const handleChange = (e) => {
    const val = e.target.value;
    onSearch(val);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel htmlFor="searchbox">{label}</InputLabel>
      <OutlinedInput
        id="searchbox"
        type="text"
        value={value}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  );
}
