import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import {
  TextField,
  Stack,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { auth, db } from "src/utils/firebaseInit";
import {
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { ID_EMPRESA } from "src/utils/urlBack_End";
import { useDispatch } from "react-redux";
import { login } from "src/reducers/authReducers";

export default function ControlPadelForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [serverError, setServerError] = useState("");

  const LoginSchema = Yup.object().shape({
    apiKey: Yup.string().required("API requerida"),
    email: Yup.string().email("Correo no válido").required("Correo requerido"),
    password: Yup.string().required("Este campo es obligatorio."),
  });

  const formik = useFormik({
    initialValues: {
      apiKey: "",
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleLogin,
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setTouched,
    setValues,
    values,
  } = formik;

  async function handleLogin(e, { props, setSubmitting, setErrors }) {
    setServerError("");
    setLoginError("");
    setSubmitting(true);
    try {
      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          signInWithEmailAndPassword(auth, e.email, e.password)
            .then(async (userCredential) => {
              const user = userCredential.user;
              const docRef = doc(db, "usuarios", user.uid);
              const docSnap = await getDoc(docRef);

              if (docSnap.exists()) {
                const dataUsuario = docSnap.data();

                if (dataUsuario && dataUsuario.permisos) {
                  const existEmpresa = dataUsuario.empresas.filter(
                    (e) => e.uuidEmpresa === ID_EMPRESA
                  )[0];

                  if (
                    existEmpresa.tipoUsuario === "Reservas" &&
                    dataUsuario.apiKey &&
                    dataUsuario.apiKey === e.apiKey
                  ) {
                    dispatch(
                      login({
                        user: {
                          id: user.uid,
                          rol: existEmpresa.tipoUsuario,
                          permisos: dataUsuario.permisos,
                          user: docSnap.data(),
                        },
                      })
                    );
                    localStorage.setItem("token", user.uid);
                    navigate("/dashboard/inicio", { replace: true });
                  } else {
                    setSubmitting(false);
                    setServerError(
                      "Ha ocurrido un error en el servidor. " +
                        "Por favor reintente más tarde."
                    );
                    await auth.signOut();
                  }
                } else {
                  await auth.signOut();
                  setSubmitting(false);
                  setServerError(
                    "Ha ocurrido un error en el servidor. " +
                      "Por favor reintente más tarde."
                  );
                }
              } else {
                await auth.signOut();
                setSubmitting(false);
                setServerError(
                  "Ha ocurrido un error en el servidor. " +
                    "Por favor reintente más tarde."
                );
              }
            })
            .catch(() => {
              setSubmitting(false);
              setServerError(
                "Ha ocurrido un error en el servidor. " +
                  "Por favor reintente más tarde."
              );
            });
        })
        .catch(() => {
          setSubmitting(false);
          setServerError(
            "Ha ocurrido un error en el servidor. " +
              "Por favor reintente más tarde."
          );
        });
    } catch (ex) {
      setSubmitting(false);
      setServerError(
        "Ha ocurrido un error en el servidor. " +
          "Por favor reintente más tarde."
      );
    }
  }

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {loginError && <Alert severity="error">{loginError}</Alert>}
            {serverError && <Alert severity="error">{serverError}</Alert>}
            <TextField
              fullWidth
              id="apiKey"
              label="API"
              {...getFieldProps("apiKey")}
              error={Boolean(touched.apiKey && errors.apiKey)}
              helperText={touched.apiKey && errors.apiKey}
            />
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              id="email"
              label="Correo"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Contraseña"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack spacing={2} marginTop={2}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Ingresar
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
