import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add";

import { ValidarPermisos } from "src/components/ValidarPermisos";

export default function BotonAgregar({ onClick, permission }) {
  return(
    <ValidarPermisos permission={permission}>
      <Button
        variant="contained"
        onClick={onClick}
        startIcon={<AddIcon/>}
      >
        Agregar
      </Button>
    </ValidarPermisos>
  );
}
