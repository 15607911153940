import { useEffect, useState } from "react";
import {
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  TextField,
  Card,
  Typography,
  CardActions,
  Grid,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  CardContent,
  Skeleton,
  Paper,
  Table,
  TableBody,
  Autocomplete,
  IconButton,
} from "@mui/material";
import { QrReader } from "react-qr-reader";
import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { AlertaToast } from "src/components/AlertaSwall";
import { LoadingButton } from "@mui/lab";
import { ID_EMPRESA } from "src/utils/urlBack_End";
import { generateID4 } from "src/utils/generateID";
import { correosApi } from "src/services/correosApi";
import { useSelector } from "react-redux";
import { round } from "lodash";
import { formatAsCLP } from "src/utils/formatNumber";
import { Delete as DeleteIcon } from "@mui/icons-material";
import dayjs from "dayjs";

export default function DialogLeerQR(props) {
  const { open, setOpen, cancha, reserva, canchaName, setOpenDatosReserva } =
    props;

  const [totalPagar, setTotalPagar] = useState(0);

  const { id: uuidUsuarioCreador, user } = useSelector(
    (state) => state.auth.user
  );

  const [datoQR, setDatoQR] = useState(null);
  const [datoReserva, setDatoReserva] = useState(null);
  const [isSocio, setIsSocio] = useState(false);
  const [tipoIngreso, setTipoIngreso] = useState("");
  const [tipoPago, setTipoPago] = useState("EFECTIVO");
  const [numeroComprobante, setNumeroComprobante] = useState("");
  const [codigoReserva, setCodigoReserva] = useState("");
  const [usuario, setUsuario] = useState(null);

  const [selectProducto, setSelectProducto] = useState(null);
  const [productos, setProductos] = useState([]);
  const [productosSelect, setProductosSelect] = useState([]);
  const [cantidad, setCantidad] = useState(1);

  const [descuento, setDescuento] = useState(0);
  const [descuentoPermitido, setDescuentoPermitido] = useState(false);
  const [verificarCodigo, setVerificarCodigo] = useState(false);
  const [codigoSolicitado, setCodigoSolicitado] = useState(false);
  const [codigoDescuento, setCodigoDescuento] = useState(0);

  const [guardandoDatos, setGuardandoDatos] = useState(false);

  const [mostrarDialog, setMostrarDialog] = useState(false);

  const [userSelect, setUserSelect] = useState(null);

  const [enviarCode, { isLoading: cargandoCodigo }] =
    correosApi.useSendCorreoCodigoMutation();

  useEffect(() => {
    const q = query(collection(db, "productos"));
    getDocs(q).then((querySnapshot) => {
      const productosAux = [];
      querySnapshot.forEach((doc) => {
        productosAux.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setProductos(productosAux);
    });
  }, []);

  useEffect(() => {
    // if (datoQR) {
    // const separacion = String(datoQR).split("_");
    const docRef = doc(db, `canchas/${cancha.id}/reservas`, reserva.id);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data && reserva.numeroTicket === data.numeroTicket) {
          setDatoReserva(data);
        } else {
          AlertaToast({
            action: "Los datos no coinciden",
            fullText: true,
            type: "error",
            target: document.getElementById("dialogLeerQR"),
          }).fire();
        }
      } else {
        AlertaToast({
          action: "Error en el servidor",
          fullText: true,
          type: "error",
          target: document.getElementById("dialogLeerQR"),
        }).fire();
      }
    });
    // }
  }, [reserva, cancha]);

  useEffect(() => {
    const docRef = doc(db, `usuarios/${reserva.uuidUsuarioReserva}`);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data) {
          if (Array.isArray(data.empresas) && data.empresas.length) {
            const datosEmpresasAux = data.empresas;
            let existEmpresaAux = datosEmpresasAux.filter(
              (e) => e.tipoUsuario === "Soci@" && e.uuidEmpresa === ID_EMPRESA
            )[0];

            if (existEmpresaAux) {
              setIsSocio(true);
            }
          }
          setUsuario(data);
        }
      }
    });
  }, [reserva]);

  const obtenerCodigoReserva = (e) => {
    if (Number(e) === reserva.numeroTicket) {
      const docRef = doc(db, `canchas/${cancha.id}/reservas`, reserva.id);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data && reserva.numeroTicket === data.numeroTicket) {
            setDatoReserva(data);
          } else {
            AlertaToast({
              action: "Los datos no coinciden",
              fullText: true,
              type: "error",
              target: document.getElementById("dialogLeerQR"),
            }).fire();
          }
        } else {
          AlertaToast({
            action: "Error en el servidor",
            fullText: true,
            type: "error",
            target: document.getElementById("dialogLeerQR"),
          }).fire();
        }
      });
    } else {
      AlertaToast({
        action: "El código de la reserva es incorrecto",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogLeerQR"),
      }).fire();
    }
  };

  const pagar = async () => {
    if (tipoPago !== "EFECTIVO" && numeroComprobante === "") {
      AlertaToast({
        action: "Ingresar numero de comprobante",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogComprobante"),
      }).fire();
    } else {
      setGuardandoDatos(true);
      try {
        await updateDoc(doc(db, `canchas/${cancha.id}/reservas`, reserva.id), {
          estadoActual: "FINALIZADO",
          estados: arrayUnion("PAGADO", "FINALIZADO"),
          tipoPago: tipoPago,
          estadoConsumo: "PAGADO",
          numeroComprobante: numeroComprobante,
          totalPagado: Number(totalPagar),
          descuento: descuento,
          numeroComprobanteConsumo: numeroComprobante,
          descuentoConsumo: descuento,
        });

        if (isSocio) {
          setOpenDatosReserva(true);
        }
        setGuardandoDatos(false);
        setMostrarDialog(false);
        setOpen(false);
        AlertaToast({
          element: "reserva",
          action: "pagada y si es socio se debe validar la información",
        }).fire();
      } catch (error) {
        AlertaToast({
          action: "Error en el servidor",
          fullText: true,
          type: "error",
        }).fire();
        setGuardandoDatos(false);
        setMostrarDialog(false);
        setOpen(false);
      }
    }
  };
  const pagarConsumo = async () => {
    if (tipoPago !== "EFECTIVO" && numeroComprobante === "") {
      AlertaToast({
        action: "Ingresar numero de comprobante",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogComprobante"),
      }).fire();
    } else {
      setGuardandoDatos(true);
      try {
        await updateDoc(doc(db, `canchas/${cancha.id}/reservas`, reserva.id), {
          estadoConsumo: "PAGADO",
          numeroComprobanteConsumo: numeroComprobante,
          totalPagado: Number(totalPagar),
          descuentoConsumo: descuento,
        });

        if (isSocio) {
          setOpenDatosReserva(true);
        }
        setGuardandoDatos(false);
        setMostrarDialog(false);
        setOpen(false);
        AlertaToast({
          element: "reserva",
          action: "pagada y si es socio se debe validar la información",
        }).fire();
      } catch (error) {
        AlertaToast({
          action: "Error en el servidor",
          fullText: true,
          type: "error",
        }).fire();
        setGuardandoDatos(false);
        setMostrarDialog(false);
        setOpen(false);
      }
    }
  };

  const solicitarCodigo = async () => {
    if (descuento <= 0) {
      AlertaToast({
        action: "El descuento debe ser mayor a 0",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogComprobante"),
      }).fire();
    } else {
      setCodigoSolicitado(true);
      try {
        const valorDescuento = totalPagar * Number(descuento) * 0.01;
        const codeGenerate = generateID4();
        const valorCancha = round(totalPagar - valorDescuento);
        await updateDoc(doc(db, `canchas/${cancha.id}/reservas`, reserva.id), {
          codigoDescuento: codeGenerate,
        });
        enviarCode({
          codigo: codeGenerate,
          cancha: canchaName,
          usuario: user.nombre,
          descuento: descuento,
          usuario_para: usuario.nombre,
          hora: reserva.horas[0],
          total_pagar: valorCancha,
          fecha: reserva.fecha,
        })
          .unwrap()
          .then(() => {
            setVerificarCodigo(true);
            AlertaToast({
              element: "código",
              action: "enviado",
              target: document.getElementById("dialogComprobante"),
            }).fire();
          })
          .catch(() => {
            AlertaToast({
              element: "código",
              action: "no enviado",
              target: document.getElementById("dialogComprobante"),
            }).fire();
          });
      } catch (error) {
        setCodigoSolicitado(false);
        AlertaToast({
          action: "Error al enviar el código",
          fullText: true,
          type: "error",
          target: document.getElementById("dialogComprobante"),
        }).fire();
      }
    }
  };

  const verificarCodigoBD = async () => {
    try {
      const docRef = doc(db, `canchas/${cancha.id}/reservas`, reserva.id);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
      } else {
        const dataObtenida = docSnap.data();

        if (Number(codigoDescuento) === dataObtenida.codigoDescuento) {
          setDescuentoPermitido(false);
          AlertaToast({
            element: "descuento",
            action: "aceptado",
            target: document.getElementById("dialogComprobante"),
          }).fire();
        } else {
          AlertaToast({
            element: "código",
            action: "no se encuentra en los registros.",
            target: document.getElementById("dialogComprobante"),
          }).fire();
        }
      }
    } catch (error) {}
  };
  const cancelar = async () => {
    setGuardandoDatos(true);
    try {
      await updateDoc(doc(db, `canchas/${cancha.id}/reservas`, reserva.id), {
        estadoActual: "CANCELADO",
        estados: arrayUnion("CANCELADO"),
      });
      AlertaToast({
        element: "reserva",
        action: "cancelada",
      }).fire();
    } catch {
      AlertaToast({
        action: "Error en el servidor",
        fullText: true,
        type: "error",
      }).fire();
    } finally {
      setGuardandoDatos(false);
      setOpen(false);
    }
  };
  const finalizar = async () => {
    setGuardandoDatos(true);
    try {
      await updateDoc(doc(db, `canchas/${cancha.id}/reservas`, reserva.id), {
        estadoActual: "FINALIZADO",
        estados: arrayUnion("FINALIZADO"),
      });
      if (isSocio) {
        setOpenDatosReserva(true);
      }
      setGuardandoDatos(true);
      setOpen(false);
      AlertaToast({
        element: "cliente",
        action: "ingresado",
      }).fire();
    } catch {
      setGuardandoDatos(true);
      setOpen(false);
      AlertaToast({
        action: "Error en el servidor",
        fullText: true,
        type: "error",
      }).fire();
    } finally {
    }
  };

  useEffect(() => {
    const q = query(
      collection(db, `canchas/${cancha.id}/reservas/${reserva.id}/consumos`)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      let totalConsumos = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data) {
          cities.push({
            id: doc.id,
            ...data,
          });
          totalConsumos = totalConsumos + data.valorTotal;
        }
      });
      setProductosSelect(cities);
      setTotalPagar(
        totalConsumos +
          (reserva.valor
            ? reserva.estadoActual === "CREADO"
              ? reserva.valor
              : 0
            : 0)
      );
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogLeerQR"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Ingreso Reserva</DialogTitle>

          {datoReserva && usuario && reserva ? (
            <Stack>
              <Card elevation={5}>
                <CardContent>
                  <Grid container spacing={2} marginBottom={2}>
                    <Grid item xs={12} md={12}>
                      <Stack>
                        <Typography
                          variant="h5"
                          textAlign="center"
                          color="primary"
                        >
                          Datos Reserva
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container padding={2}>
                    <Grid item xs={3} md={3}>
                      <Stack>
                        <Typography variant="h6" color="primary">
                          Número Ticket
                        </Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                          {reserva.numeroTicket}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Stack>
                        <Typography variant="h6" color="primary">
                          Cancha
                        </Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                          {reserva.nombreCancha}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Stack>
                        <Typography variant="h6" color="primary">
                          Hora Inicio
                        </Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                          {reserva.horas[0]}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Stack>
                        <Typography variant="h6" color="primary">
                          Duración
                        </Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                          {reserva.duracion
                            ? `${reserva.duracion} min.`
                            : "90 min."}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Divider />

                  <Grid container spacing={2} marginTop={2} marginBottom={2}>
                    <Grid item xs={12} md={12}>
                      <Stack>
                        <Typography
                          variant="h5"
                          textAlign="center"
                          color="primary"
                        >
                          Cliente
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container padding={2}>
                    <Grid item xs={4} md={4}>
                      <Stack>
                        <Typography variant="h6" color="primary">
                          Nombre
                        </Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                          {usuario.nombre}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Stack>
                        <Typography variant="h6" color="primary">
                          Contacto
                        </Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                          {usuario.telefono}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Stack>
                        <Typography variant="h6" color="primary">
                          Correo
                        </Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                          {usuario.correo}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={2} marginTop={2} marginBottom={2}>
                    <Grid item xs={12} md={12}>
                      <Stack>
                        <Typography
                          variant="h5"
                          textAlign="center"
                          color="primary"
                        >
                          Consumo Cliente
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                  {reserva.estadoConsumo &&
                  reserva.estadoConsumo === "PAGADO" ? (
                    <></>
                  ) : (
                    <Grid container padding={2}>
                      <Grid item xs={8} md={8}>
                        <Stack>
                          <Autocomplete
                            value={selectProducto}
                            onChange={(event, newValue) => {
                              console.log(newValue);
                              if (
                                newValue &&
                                newValue.cantidad &&
                                newValue.cantidad >= cantidad
                              ) {
                                setSelectProducto(newValue);
                              } else if (newValue) {
                                AlertaToast({
                                  action:
                                    "Según los registros no quedan productos disponibles.",
                                  fullText: true,
                                  type: "warning",
                                  target:
                                    document.getElementById("dialogLeerQR"),
                                }).fire();
                                setSelectProducto(null);
                              } else {
                                setSelectProducto(newValue);
                              }
                            }}
                            id="controllable-states-demo"
                            options={productos}
                            getOptionLabel={(option) => {
                              return option?.nombre;
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option?.nombre === value?.nombre
                            }
                            fullWidth
                            renderInput={(params) => (
                              <TextField {...params} label="Producto" />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <Stack>
                          <TextField
                            label="Cantidad"
                            fullWidth
                            value={cantidad}
                            type="number"
                            onChange={(e) => {
                              setCantidad(e.target.value);
                            }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <Stack alignItems="center">
                          <LoadingButton
                            variant="contained"
                            onClick={async () => {
                              if (selectProducto == null) {
                                AlertaToast({
                                  action: "Debes seleccionar un producto",
                                  fullText: true,
                                  type: "warning",
                                  target:
                                    document.getElementById("dialogLeerQR"),
                                }).fire();
                              } else if (cantidad < 1) {
                                AlertaToast({
                                  action:
                                    "La cantidad debe ser mayor o igual a uno",
                                  fullText: true,
                                  type: "warning",
                                  target:
                                    document.getElementById("dialogLeerQR"),
                                }).fire();
                              } else {
                                try {
                                  await addDoc(
                                    collection(
                                      db,
                                      `canchas/${cancha.id}/reservas/${reserva.id}/consumos`
                                    ),
                                    {
                                      nombre: selectProducto.nombre,
                                      cantidad: Number(cantidad),
                                      valorUnitario: selectProducto.valor,
                                      valorTotal: Number(
                                        selectProducto.valor * Number(cantidad)
                                      ),
                                    }
                                  );
                                  await updateDoc(
                                    doc(db, "productos", selectProducto.id),
                                    {
                                      cantidad:
                                        Number(selectProducto.cantidad) -
                                        Number(cantidad),
                                    }
                                  );

                                  await addDoc(
                                    collection(
                                      db,
                                      `productos/${selectProducto.id}/historialVentas`
                                    ),
                                    {
                                      fecha: dayjs().format("YYYY-MM-DD"),
                                      nombreUsuario: user.name,
                                      uuidUsuario: uuidUsuarioCreador,
                                      cantidadAnterior: selectProducto.cantidad,
                                      cantidad: Number(cantidad),
                                    }
                                  );
                                  setTotalPagar(
                                    totalPagar +
                                      Number(
                                        selectProducto.valor * Number(cantidad)
                                      )
                                  );
                                  AlertaToast({
                                    action: "Producto agregado con éxito.",
                                    fullText: true,
                                    target:
                                      document.getElementById("dialogLeerQR"),
                                  }).fire();

                                  setSelectProducto(null);
                                  setCantidad(1);
                                } catch (error) {
                                  AlertaToast({
                                    action:
                                      "Ha ocurrido un error al guardar los datos.",
                                    fullText: true,
                                    type: "warning",
                                    target:
                                      document.getElementById("dialogLeerQR"),
                                  }).fire();
                                }
                              }
                            }}
                          >
                            Agregar Producto
                          </LoadingButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  )}

                  <Card>
                    <CardContent>
                      <TableContainer component={Paper}>
                        <Table aria-label="spanning table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Tipo Consumo</TableCell>
                              <TableCell align="right">Cantidad</TableCell>
                              <TableCell align="right">
                                Valor Unitario
                              </TableCell>
                              <TableCell align="right">Total</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {productosSelect && productosSelect.length >= 1 ? (
                              productosSelect.map((row) => (
                                <TableRow key={row.nombre}>
                                  <TableCell>{row.nombre}</TableCell>
                                  <TableCell align="right">
                                    {row.cantidad}
                                  </TableCell>
                                  <TableCell align="right">
                                    {formatAsCLP(row.valorUnitario)}
                                  </TableCell>
                                  <TableCell align="right">
                                    {formatAsCLP(row.valorTotal)}
                                  </TableCell>
                                  <TableCell>
                                    {reserva.estadoConsumo &&
                                    reserva.estadoConsumo === "PAGADO" ? (
                                      <></>
                                    ) : (
                                      <IconButton
                                        color="error"
                                        onClick={async () => {
                                          await deleteDoc(
                                            doc(
                                              db,
                                              `canchas/${cancha.id}/reservas/${reserva.id}/consumos`,
                                              row.id
                                            )
                                          );
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4}>
                                  <Card
                                    sx={{
                                      backgroundColor: "#E5F6FD",
                                      color: "#0072E5",
                                    }}
                                  >
                                    <CardContent>
                                      <Typography
                                        variant="body1"
                                        textAlign="center"
                                        color="white"
                                      >
                                        Comienza a ingresar productos
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </TableCell>
                              </TableRow>
                            )}
                            {reserva.estadoActual === "CREADO" && (
                              <TableRow>
                                <TableCell rowSpan={3} />
                                <TableCell colSpan={2}>Valor Cancha</TableCell>
                                <TableCell align="right">
                                  {formatAsCLP(reserva.valor)}
                                </TableCell>
                              </TableRow>
                            )}

                            <TableRow>
                              {reserva.estadoActual !== "CREADO" && (
                                <TableCell rowSpan={3} />
                              )}
                              <TableCell colSpan={2}>Total a Pagar</TableCell>
                              <TableCell align="right">
                                {formatAsCLP(totalPagar)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </CardContent>
                <CardActions>
                  {datoReserva.estadoActual === "CREADO" ? (
                    <>
                      <LoadingButton
                        loading={guardandoDatos}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          setMostrarDialog(true);
                        }}
                      >
                        Pagar
                      </LoadingButton>
                    </>
                  ) : datoReserva.estadoActual === "PAGADO" ? (
                    <>
                      {reserva.estadoConsumo &&
                        reserva.estadoConsumo !== "PAGADO" && (
                          <LoadingButton
                            loading={guardandoDatos}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                              setMostrarDialog(true);
                            }}
                          >
                            Pagar Consumo
                          </LoadingButton>
                        )}
                      {/* <LoadingButton
                        loading={guardandoDatos}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={finalizar}
                      >
                        Ingresar
                  </LoadingButton> */}
                    </>
                  ) : (
                    <></>
                  )}
                </CardActions>
              </Card>
            </Stack>
          ) : (
            <>
              <Skeleton variant="rectangular" width="100%" height={400} />
              {/* 
              <DialogContent>
                <DialogContentText>
                  Seleccione la forma del ingreso
                </DialogContentText>
              </DialogContent>
              <Stack direction="row" spacing={2} margin={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setUserSelect(null);
                    setTipoIngreso("QR");
                  }}
                >
                  Código QR
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setUserSelect(null);
                    setTipoIngreso("Reserva");
                  }}
                >
                  Código Reserva
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={async () => {
                    const docRef = doc(
                      db,
                      "usuarios",
                      reserva.uuidUsuarioReserva
                    );
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                      setUserSelect(docSnap.data());
                      setTipoIngreso("");
                    } else {
                      // docSnap.data() will be undefined in this case
                      setUserSelect(null);
                      setTipoIngreso("");
                    }
                  }}
                >
                  Mostrar Información
                </Button>
              </Stack>
              {tipoIngreso === "QR" ? (
                <QrReader
                  onResult={(result, error) => {
                    if (!!result) {
                      setDatoQR(result?.text);
                    }

                    if (!!error) {
                      AlertaToast({
                        action: "Error en leer el QR",
                        fullText: true,
                        type: "error",
                      }).fire();
                    }
                  }}
                  style={{ width: "100%" }}
                />
              ) : tipoIngreso === "Reserva" ? (
                <Stack margin={5}>
                  <TextField
                    label="Código de Reserva"
                    fullWidth
                    value={codigoReserva}
                    onChange={(e) => {
                      setCodigoReserva(e.target.value);
                    }}
                    onBlur={(e) => {
                      obtenerCodigoReserva(e.target.value);
                    }}
                  />
                </Stack>
              ) : userSelect !== null ? (
                <Stack margin={5} spacing={2}>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Typography variant="h6" color="primary">
                        Nombre
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}></Grid>
                    <Grid item xs={4} md={4}>
                      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                        {userSelect.nombre}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Typography variant="h6" color="primary">
                        Correo
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}></Grid>
                    <Grid item xs={4} md={4}>
                      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                        {userSelect.correo}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Typography variant="h6" color="primary">
                        Contacto
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}></Grid>
                    <Grid item xs={4} md={4}>
                      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                        {userSelect.telefono}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Typography variant="h6" color="primary">
                        Estado Reserva
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}></Grid>
                    <Grid item xs={4} md={4}>
                      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                        {reserva.estadoActual}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              ) : (
                <Stack margin={5}></Stack>
              )}*/}
            </>
          )}
          <DialogActions>
            {reserva.estadoActual === "CREADO" && (
              <Button variant="contained" color="error" onClick={cancelar}>
                Cancelar Reserva
              </Button>
            )}

            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {mostrarDialog && (
        <Dialog
          open={mostrarDialog}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="md"
          id="dialogComprobante"
        >
          <DialogTitle id="form-dialog-title">Ingreso Tipo Pago</DialogTitle>
          <Stack margin={3}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Tipo Pago
              </FormLabel>
              <RadioGroup
                value={tipoPago}
                onChange={(event) => {
                  setTipoPago(event.target.value);
                }}
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="EFECTIVO"
                  control={<Radio />}
                  label="EFECTIVO"
                />
                <FormControlLabel
                  value="DÉBITO"
                  control={<Radio />}
                  label="DÉBITO"
                />
                <FormControlLabel
                  value="CRÉDITO"
                  control={<Radio />}
                  label="CRÉDITO"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              sx={{
                marginBottom: 2,
              }}
              label="Total a pagar"
              value={formatAsCLP(totalPagar)}
              disabled
            />
            {tipoPago !== "EFECTIVO" && (
              <TextField
                sx={{
                  marginBottom: 2,
                }}
                label="Ingresar numero de comprobante"
                value={numeroComprobante}
                onChange={(e) => {
                  setNumeroComprobante(e.target.value);
                }}
              />
            )}

            <Grid container>
              <Grid item xs={8}>
                <TextField
                  label="¿Aplicar Descuento?"
                  fullWidth
                  value={descuento}
                  onChange={(e) => {
                    const esNumeroPositivo = /^\d*\.?\d+$/;
                    if (
                      esNumeroPositivo.test(e.target.value) &&
                      Number(e.target.value) > 0
                    ) {
                      setDescuento(e.target.value);
                      setDescuentoPermitido(true);
                    } else {
                      setDescuento("");
                      setDescuentoPermitido(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                {descuentoPermitido && (
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    color="warning"
                    onClick={solicitarCodigo}
                    loading={cargandoCodigo}
                  >
                    Solicitar Descuento
                  </LoadingButton>
                )}
              </Grid>
            </Grid>

            {codigoSolicitado && (
              <Grid container>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    label="Ingresar Código"
                    value={codigoDescuento}
                    onChange={(e) => {
                      const esNumeroPositivo = /^\d*\.?\d+$/;
                      if (esNumeroPositivo.test(e.target.value)) {
                        setCodigoDescuento(e.target.value);
                      } else {
                        setCodigoDescuento("");
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}>
                  {verificarCodigo && (
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      color="warning"
                      onClick={verificarCodigoBD}
                      loading={cargandoCodigo}
                    >
                      Verificar Código
                    </LoadingButton>
                  )}
                </Grid>
              </Grid>
            )}
          </Stack>
          <DialogActions>
            <LoadingButton
              variant="contained"
              color="secondary"
              onClick={() => setMostrarDialog(false)}
              loading={guardandoDatos}
            >
              Cerrar
            </LoadingButton>
            <LoadingButton
              disabled={descuentoPermitido}
              variant="contained"
              color="primary"
              onClick={reserva.estadoActual === "PAGADO" ? pagarConsumo : pagar}
              loading={guardandoDatos}
            >
              Pagar
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
