import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const correosApi = createApi({
  reducerPath: "correosApi",
  baseQuery: baseQuery(`${API_SERVER}/correos`),
  tagTypes: ["Correos"],
  endpoints: (builder) => ({
    sendCorreoCodigo: builder.mutation({
      query: (body) => ({
        url: `/send-code`,
        method: "POST",
        body,
      }),
    }),
    sendCorreo: builder.mutation({
      query: (body) => ({
        url: `/send`,
        method: "POST",
        body,
      }),
    }),
    sendContacto: builder.mutation({
      query: (body) => ({
        url: `/send-contact`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useSendCorreoCodigoMutation,
  useSendContactoMutation,
  useSendCorreoMutation,
} = correosApi;
