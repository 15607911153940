import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import { visuallyHidden } from "@mui/utils";
import { ValidarPermisos } from "../ValidarPermisos";
import SearchBox from "./SearchBox.js";
import { removeAccents } from "src/utils/converter";
import SearchNotFound from "../SearchNotFound";
import { tienePermiso } from "src/utils/Permisos";
import { useSelector } from "react-redux";

function descendingComparator(a, b, orderBy) {
  const isNumeric = typeof a[orderBy] === "number";
  if (isNumeric) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  } else {
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, encabezados } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {encabezados.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Tabla({
  encabezados,
  encabezadosForSorting,
  data,
  eliminarElemento,
  editarElemento,
  accionesDefecto,
  permisosEliminar,
  permitirEliminarFila = () => true,
  permisosEditar,
  buscarPor,
  etiquetaBusqueda,
  actions,
  botones = [],
  aplicarBuscador = true,
  descargarElemento,
  descargar = false,
  deleteObject = false,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(encabezados[0].id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredData, setFilteredData] = useState(data);
  const [value, setValue] = useState("");

  const { permisos } = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!aplicarBuscador) return;
    setPage(0);
    const permisoEditar = permisosEditar || "";
    const permisoEliminar = permisosEliminar || "";
    const noTienePermisosAcciones =
      !tienePermiso(permisos, permisoEditar) &&
      !tienePermiso(permisos, permisoEliminar);

    if (noTienePermisosAcciones) {
      const idx1 = encabezados.findIndex((el) => el.label === "Acciones");
      const idx2 = encabezadosForSorting?.findIndex(
        (el) => el.label === "Acciones"
      );
      if (idx1 && idx1 !== -1) {
        // encabezados.splice(idx1, 1);
      }
      if (idx2 && idx2 !== -1) {
        // encabezadosForSorting.splice(idx2, 1);
      }
    }

    const filtered = data.filter((el) =>
      buscarPor.some((k) =>
        removeAccents(String(el[k]))
          .toLowerCase()
          .includes(removeAccents(value).toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [
    data,
    encabezados,
    encabezadosForSorting,
    permisosEliminar,
    permisosEditar,
    permisos,
    aplicarBuscador,
    buscarPor,
    value,
  ]);

  const handleRequestSort = (_e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleSearch = (item) => {
    setValue(item);
    setPage(0);
    const filtered = data.filter((el) =>
      buscarPor.some((k) =>
        removeAccents(String(el[k]))
          .toLowerCase()
          .includes(removeAccents(item).toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  const handleClickCell = (row, cell) => {
    if (!actions) return;
    if (actions[cell]) {
      actions[cell](row);
    } else {
      actions["default"](row);
    }
  };

  // Poner el cursor como pointer cuando hay alguna acción asociada a la celda
  const getCellStyle = (cell) => {
    let style = {};
    if (actions && (actions["default"] || actions[cell])) {
      style = { cursor: "pointer" };
    }
    return style;
  };

  const isUserNotFound = filteredData.length === 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, mt: 2 }}>
        {aplicarBuscador ? (
          <Box display="flex" justifyContent="space-between">
            <Box
              sx={{
                ...{ width: botones.length === 0 ? "65%" : "40%" },
                ...{ minWidth: "200px", my: 2, mr: 2 },
              }}
            >
              <SearchBox
                label={etiquetaBusqueda}
                onSearch={handleSearch}
                value={value}
              />
            </Box>
            <Stack
              spacing={2}
              direction="row"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              {botones.map((b) => (
                <React.Fragment key={crypto.randomUUID()}> {b} </React.Fragment>
              ))}
            </Stack>
          </Box>
        ) : botones.length >= 1 ? (
          <Box display="flex" justifyContent="space-between">
            <Box
              sx={{
                ...{ width: botones.length === 0 ? "65%" : "40%" },
                ...{ minWidth: "200px", my: 2, mr: 2 },
              }}
            ></Box>
            <Stack
              spacing={2}
              direction="row"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              {botones.map((b) => (
                <React.Fragment key={crypto.randomUUID()}> {b} </React.Fragment>
              ))}
            </Stack>
          </Box>
        ) : (
          <Box display="flex" justifyContent="space-between"></Box>
        )}

        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              encabezados={encabezadosForSorting || encabezados}
            />
            <TableBody>
              {stableSort(filteredData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, idx) => {
                  const keyObject = encabezados.map((e) => e.id);
                  return (
                    <TableRow tabIndex={-1} key={idx}>
                      {keyObject.map((e, index) => {
                        if (e === "" || e === " ") {
                          return (
                            <TableCell key={"table_" + index} align="left">
                              {accionesDefecto ? (
                                accionesDefecto()
                              ) : (
                                <Stack direction="row">
                                  {eliminarElemento &&
                                    permisosEliminar &&
                                    permitirEliminarFila(row) && (
                                      <ValidarPermisos
                                        permission={permisosEliminar}
                                      >
                                        <IconButton
                                          color="error"
                                          onClick={() => {
                                            if (deleteObject) {
                                              eliminarElemento(row);
                                            } else {
                                              eliminarElemento(row.id);
                                            }
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </ValidarPermisos>
                                    )}

                                  {editarElemento && permisosEditar && (
                                    <ValidarPermisos
                                      permission={permisosEditar}
                                    >
                                      <IconButton
                                        color="primary"
                                        onClick={() => {
                                          editarElemento(row);
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </ValidarPermisos>
                                  )}

                                  {descargarElemento && descargar && (
                                    <IconButton
                                      color="error"
                                      onClick={() => {
                                        descargarElemento(row);
                                      }}
                                    >
                                      <DownloadIcon />
                                    </IconButton>
                                  )}
                                </Stack>
                              )}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={"table_" + index}
                            onClick={() => handleClickCell(row, e)}
                            style={getCellStyle()}
                          >
                            {row[e]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={value} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={function defaultLabelDisplayedRows({
            from,
            to,
            count,
          }) {
            return `${from}–${to} de ${count !== -1 ? count : `más de ${to}`}`;
          }}
          labelRowsPerPage="Filas por página"
        />
      </Paper>
    </Box>
  );
}
