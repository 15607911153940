import { experimentalStyled as styled } from "@mui/material/styles";
import Card from "@mui/material/Card";

export const Titulo = styled(Card)(({ theme }) => ({
  boxShadow: "initial",
  marginBottom: "-1rem",
  textAlign: "center",
  padding: theme.spacing(2, 0),
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.grey[100],
}));
