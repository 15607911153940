export function splitCamelCase(s) {
  return (
    s.charAt(0).toUpperCase() +
    s
      .slice(1)
      .split(/([A-Z][a-z]+)/)
      .filter((s) => s)
      .map((s) => s.toLowerCase())
      .toString()
      .replaceAll(",", " ")
  );
}

export function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
