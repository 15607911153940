import { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { LoadingButton } from "@mui/lab";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { ID_PADEL } from "src/utils/urlBack_End";
import dayjs from "dayjs";

export default function DialogEditarTarifa(props) {
  const { open, setOpen, selectTarifa, horarioFuncionamiento, tarifas } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [horarioTarifa, setHorarioTarifa] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    valorTarifa: Yup.string().required("Tarifa requerida"),
  });

  const formik = useFormik({
    initialValues: {
      valorTarifa: Number(selectTarifa.valor),
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const guardarDatos = () => {
    setIsLoading(true);
    AlertaModal({
      title: "agregar al usuario",
      confirmButtonText: "Sí, agregar",
      target: document.getElementById("dialogEditarTarifa"),
    })
      .fire()
      .then(async (result) => {
        if (result.isConfirmed) {
          const modifyTarifa = tarifas.map((e) => {
            if (e.tipoValor === selectTarifa.tipoValor) {
              return {
                ...selectTarifa,
                valor: Number(getFieldProps("valorTarifa").value),
                horario: horarioTarifa
                  .filter((e) => e.exist === true)
                  .map((e) => e.hora),
              };
            }
            return e;
          });

          try {
            await updateDoc(doc(db, "padel", ID_PADEL), {
              valores: modifyTarifa,
            });
            AlertaToast({ action: "actualizados", element: "Valores" }).fire();
            setIsLoading(false);
            setOpen(false);
          } catch (error) {
            AlertaToast({
              action: "Error en el servidor",
              fullText: true,
              type: "error",
            }).fire();
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (selectTarifa && selectTarifa.horario) {
      const horarioInicio = horarioFuncionamiento.inicio;
      const horarioFin = horarioFuncionamiento.fin;

      const horas = [];
      const fechaActual = new Date();

      let horaActual = dayjs(
        `${fechaActual.getFullYear()}-${
          fechaActual.getMonth() + 1
        }-${fechaActual.getDate()} ${horarioInicio}`
      )
        .add(30, "minute")
        .format("HH:mm");
      horas.push(horarioInicio);

      while (
        dayjs(
          `${fechaActual.getFullYear()}-${
            fechaActual.getMonth() + 1
          }-${fechaActual.getDate()} ${horaActual}`
        ) <=
        dayjs(
          `${fechaActual.getFullYear()}-${
            fechaActual.getMonth() + 1
          }-${fechaActual.getDate()} ${horarioFin}`
        )
      ) {
        horas.push(horaActual);
        horaActual = dayjs(
          `${fechaActual.getFullYear()}-${
            fechaActual.getMonth() + 1
          }-${fechaActual.getDate()} ${horaActual}`
        )
          .add(30, "minute")
          .format("HH:mm");
      }
      horas.push(horarioFuncionamiento.horaFin);

      const horaExist = [];

      // eslint-disable-next-line array-callback-return
      horas.map((item) => {
        const exist = selectTarifa.horario.filter((e) => e === item)[0];

        if (exist) {
          horaExist.push({
            hora: item,
            exist: true,
          });
        } else {
          horaExist.push({
            hora: item,
            exist: false,
          });
        }
      });
      setHorarioTarifa(horaExist);
    }
  }, [selectTarifa, horarioFuncionamiento]);
  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogEditarTarifa"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Editar Tarifa</DialogTitle>
          <DialogContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  required
                  margin="dense"
                  id="valorTarifa"
                  label="Valor Tarifa"
                  type="number"
                  fullWidth
                  {...getFieldProps("valorTarifa")}
                  error={Boolean(touched.valorTarifa && errors.valorTarifa)}
                  helperText={touched.valorTarifa && errors.valorTarifa}
                />

                <Paper elevation={2} style={{ marginTop: 10 }}>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                  >
                    {horarioTarifa.map((element, idx) => (
                      <ListItem key={idx} disablePadding>
                        <ListItemButton
                          role={undefined}
                          onClick={() => {
                            setHorarioTarifa(
                              horarioTarifa.map((e) => {
                                if (e.hora === element.hora) {
                                  return {
                                    ...e,
                                    exist: !e.exist,
                                  };
                                }
                                return e;
                              })
                            );
                          }}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={element.exist}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={element.hora} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Paper>

                <DialogActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                    disabled={isLoading}
                  >
                    Cerrar
                  </Button>
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    Agregar
                  </LoadingButton>
                </DialogActions>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
