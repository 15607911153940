import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { AlertaModal, AlertaToast } from "../../AlertaSwall";
import {
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";

export default function DialogEditarPago(props) {
  const { open, setOpen, row, selectUser } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [tipoPago, setTipoPago] = useState("EFECTIVO");
  const [numeroComprobante, setNumeroComprobante] = useState("");

  const guardarDatos = () => {
    if (
      tipoPago !== "EFECTIVO" &&
      (numeroComprobante === "" || numeroComprobante === "0")
    ) {
      return AlertaToast({
        action: "Se debe ingresar el número de comprobante",
        fullText: true,
        type: "info",
        target: document.getElementById("dialogPagar"),
      }).fire();
    }

    const newData = {
      tipoPago,
      numeroComprobante,
      estado: "PAGADO",
      pagado: true,
    };

    AlertaModal({
      title: "realizar el pago",
      confirmButtonText: "Sí, pagar",
      target: document.getElementById("dialogPagar"),
    })
      .fire()
      .then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);

          try {
            await updateDoc(
              doc(db, `usuarios/${selectUser.id}/pagos`, row.id),
              newData
            );
            setOpen(false);
            AlertaToast({
              action: "actualizado",
              element: "Pago",
            }).fire();
          } catch (error) {
            AlertaToast({
              action: "Error en el servidor",
              fullText: true,
              type: "error",
              target: document.getElementById("dialogPagar"),
            }).fire();
          } finally {
            setIsLoading(false);
          }
        }
      });
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogPagar"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Período del pago: {`${row.fechaInicio} - ${row.fechaTermino}`}
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Tipo Pago
                </FormLabel>
                <RadioGroup
                  value={tipoPago}
                  onChange={(event) => {
                    setTipoPago(event.target.value);
                  }}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="EFECTIVO"
                    control={<Radio />}
                    label="EFECTIVO"
                  />
                  <FormControlLabel
                    value="DÉBITO"
                    control={<Radio />}
                    label="DÉBITO"
                  />
                  <FormControlLabel
                    value="CRÉDITO"
                    control={<Radio />}
                    label="CRÉDITO"
                  />
                </RadioGroup>
              </FormControl>

              {tipoPago !== "EFECTIVO" && (
                <TextField
                  style={{ paddingRight: 10 }}
                  value={numeroComprobante}
                  type="number"
                  onChange={(e) => setNumeroComprobante(e.target.value)}
                  label="Número Comprobante"
                  fullWidth
                />
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cerrar</Button>
            <LoadingButton
              variant="contained"
              onClick={guardarDatos}
              loading={isLoading}
            >
              Pagar
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
