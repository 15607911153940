/* eslint-disable jsx-a11y/iframe-has-title */
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Page from "../components/Page";
import { Titulo } from "src/components/Titulo";
import { useEffect, useRef, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { ID_EMPRESA, ID_PADEL } from "src/utils/urlBack_End";
import { usuariosApi } from "src/services/usuariosApi";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { LoadingButton } from "@mui/lab";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import SignatureCanvas from "react-signature-canvas";
import {
  validateRut,
  deconstructRut,
  formatRut,
  RutFormat,
} from "@fdograph/rut-utilities";
import DialogTomarFoto from "src/components/gestionUsuarios/dialog/DialogTomarFoto";
import {
  Stack,
  Button,
  TextField,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";

export default function Socio() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [tomarFotoDNIFront, setTomarFotoDNIFront] = useState(false);
  const [tomarFotoDNIPost, setTomarFotoDNIPost] = useState(false);
  const [tomarFotoFoto1, setTomarFotoFoto1] = useState(false);
  const [tomarFotoFoto2, setTomarFotoFoto2] = useState(false);
  const [tomarFotoFoto3, setTomarFotoFoto3] = useState(false);
  const [mostrarContratoPreeliminar, setMostrarContratoPreeliminar] =
    useState(false);
  const signatureRef = useRef({});
  const [crearUsuario] = usuariosApi.useAddMutation();
  const [firmarContratoApi] = usuariosApi.useFirmarContratoMutation();

  const [imagenDNIFront, setImagenDNIFront] = useState(null);
  const [imagenDNIPost, setImagenDNIPost] = useState(null);
  const [imagenFoto1, setImagenFoto1] = useState(null);
  const [imagenFoto2, setImagenFoto2] = useState(null);
  const [imagenFoto3, setImagenFoto3] = useState(null);
  const [valorTotalMembresia, setValorTotalMembresia] = useState(0);

  const LoginSchema = Yup.object().shape({
    rut: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
    nombre: Yup.string().required("Nombre requerido"),
    direccion: Yup.string().required("Dirección requerida"),
    correo: Yup.string().email("Correo no válido").required("Correo requerido"),
    telefono: Yup.string().test(
      "telefono test",
      "Teléfono no válido",
      (value) => matchIsValidTel(value)
    ),
  });

  const formik = useFormik({
    initialValues: {
      rut: "",
      nombre: "",
      correo: "",
      telefono: "+56",
      direccion: "",
      fechaNacimiento: null,
      tipoMembresia: "",
      numeroSerieCarnet: "",
      totalMembresia: 0,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setTouched,
    setFieldValue,
  } = formik;

  const obtenerUsuarioReserva = (e) => {
    const q = query(collection(db, "usuarios"), where("rut", "==", e));
    getDocs(q).then((querySnapshot) => {
      if (querySnapshot.empty) {
        setFieldValue("telefono", "+56");
        setFieldValue("correo", "");
        setFieldValue("direccion", "");
        setFieldValue("nombre", "");
      } else {
        querySnapshot.forEach((doc) => {
          const dataAux = doc.data();
          const empresas = dataAux.empresas;
          const dataEmpresa = empresas.filter(
            (e) => e.uuidEmpresa === ID_EMPRESA && e.tipoUsuario === "Empresa"
          );
          if (dataEmpresa.length >= 1) {
          } else {
            if (String(dataAux.telefono).includes("+")) {
              setFieldValue("telefono", `${dataAux.telefono}`);
            } else {
              setFieldValue("telefono", `+56${dataAux.telefono}`);
            }
            setFieldValue("correo", dataAux.correo);
            setFieldValue("direccion", dataAux.direccion);
            setFieldValue("nombre", dataAux.nombre);
          }
        });
      }
    });
  };

  const generarContrato = async () => {
    const rutAux =
      deconstructRut(getFieldProps("rut").value.toUpperCase()).digits +
      deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier;
    const data = {
      dia: dayjs().format("DD"),
      mes: dayjs().format("MM"),
      anio: dayjs().format("YYYY"),
      nombre: getFieldProps("nombre").value,
      rut: formatRut(rutAux, RutFormat.DOTS_DASH),
      email: getFieldProps("correo").value,
      domicilio: getFieldProps("direccion").value,
      valor: valorTotalMembresia,
      nombreFirma: getFieldProps("nombre").value,
    };

    try {
      await firmarContratoApi({
        ...data,
        blobImagen: signatureRef.current
          .getTrimmedCanvas()
          .toDataURL("image/jpg"),
      }).unwrap();
    } catch (error) {}
  };

  const guardarDatos = () => {
    setIsLoading(true);
    const rutAux =
      deconstructRut(getFieldProps("rut").value.toUpperCase()).digits +
      deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier;

    const numeroSerieCarnetAux = getFieldProps("numeroSerieCarnet").value;
    const fechaNacimientoAux = getFieldProps("fechaNacimiento").value;
    const fechaInicioContratoAux = dayjs();

    const newUser = {
      rut: rutAux,
      displayName: getFieldProps("nombre").value,
      email: getFieldProps("correo").value,
      phoneNumber: getFieldProps("telefono").value,
      direccion: getFieldProps("direccion").value,
      type_user: "Soci@",
      numeroSerieCarnet: numeroSerieCarnetAux,
      fechaNacimiento: dayjs(fechaNacimientoAux).format("YYYY-MM-DD"),
      fechaInicioContrato: fechaInicioContratoAux.format("YYYY-MM-DD"),
      totalMembresia: valorTotalMembresia,
      fechaTerminoContrato: fechaInicioContratoAux
        .add("1", "month")
        .format("YYYY-MM-DD"),
    };

    if (numeroSerieCarnetAux === "") {
      AlertaToast({
        action: "Se debe ingresar el número de serie",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogCrearUsuario"),
      }).fire();
      setIsLoading(false);
    } else if (!fechaNacimientoAux) {
      AlertaToast({
        action: "La fecha de nacimiento es requerida",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogCrearUsuario"),
      }).fire();
      setIsLoading(false);
    } else {
      AlertaModal({
        title: "agregar al usuario",
        confirmButtonText: "Sí, agregar",
        target: document.getElementById("dialogCrearUsuario"),
      })
        .fire()
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await generarContrato();
              try {
                await crearUsuario(newUser).unwrap();

                setIsLoading(false);
                navigate(-1);
                AlertaToast({
                  element: "usuario",
                  action: "creado",
                }).fire();
              } catch (error) {}
            } catch (error) {
              setIsLoading(false);
              AlertaToast({
                action:
                  "El contrato no ha podido ser firmado. Volver a intentar o contactar a soporte",
                fullText: true,
                type: "error",
              }).fire();
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handlePhoneNumber = (v) => {
    setFieldValue("telefono", v);
  };

  useEffect(() => {
    const docRef = doc(db, "padel", ID_PADEL);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data && data.valores) {
          const valoresAux = data.valores;

          if (valoresAux && Array.isArray(valoresAux)) {
            const valorMembresiaSemestral = valoresAux.filter(
              (e) => e.tipoValor === "Soci@"
            )[0].valor;

            setValorTotalMembresia(valorMembresiaSemestral);
          }
        }
      }
    });
  }, []);

  return (
    <Page title="Padel Club Rengo | Socio">
      <Container>
        <Titulo>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={2} md={3} align="left"></Grid>
            <Grid item xs={8} md={6} align="center">
              <Typography variant="h4">Nuevo Socio</Typography>
            </Grid>
            <Grid item xs={2} md={3} align="end"></Grid>
          </Grid>
        </Titulo>

        <Stack marginTop={5}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack direction="row" spacing={2}>
                <TextField
                  required
                  margin="dense"
                  id="rut"
                  label="Rut"
                  fullWidth
                  {...getFieldProps("rut")}
                  error={Boolean(touched.rut && errors.rut)}
                  helperText={touched.rut && errors.rut}
                  autoFocus
                  onBlur={(e) => {
                    obtenerUsuarioReserva(e.target.value);
                  }}
                />
                <TextField
                  required
                  margin="dense"
                  id="nombre"
                  label="Nombre"
                  fullWidth
                  {...getFieldProps("nombre")}
                  error={Boolean(touched.nombre && errors.nombre)}
                  helperText={touched.nombre && errors.nombre}
                />
              </Stack>

              <Stack direction="row" spacing={2} marginTop={3}>
                <TextField
                  required
                  style={{ paddingRight: 10 }}
                  margin="dense"
                  id="direccion"
                  label="Dirección"
                  fullWidth
                  {...getFieldProps("direccion")}
                  error={Boolean(touched.direccion && errors.direccion)}
                  helperText={touched.direccion && errors.direccion}
                />
                <TextField
                  required
                  style={{ paddingRight: 10 }}
                  margin="dense"
                  id="correo"
                  label="Correo"
                  type="email"
                  fullWidth
                  {...getFieldProps("correo")}
                  error={Boolean(touched.correo && errors.correo)}
                  helperText={touched.correo && errors.correo}
                />
              </Stack>
              <Stack direction="row" spacing={2} marginTop={3}>
                <MuiTelInput
                  required
                  margin="dense"
                  id="telefono"
                  label="Teléfono"
                  forceCallingCode
                  defaultCountry="CL"
                  preferredCountries={["CL"]}
                  fullWidth
                  onFocus={() =>
                    setTouched({ ...touched, telefono: true }, true)
                  }
                  value={getFieldProps("telefono").value}
                  onChange={(e) => handlePhoneNumber(e)}
                  onBlur={getFieldProps("telefono").onBlur}
                  error={Boolean(touched.telefono && errors.telefono)}
                  helperText={touched.telefono && errors.telefono}
                />
                <DatePicker
                  label="Fecha de Nacimiento"
                  views={["day", "month", "year"]}
                  inputFormat="dd/MM/yyyy"
                  value={getFieldProps("fechaNacimiento").value}
                  onChange={(v) => {
                    setFieldValue("fechaNacimiento", v);
                  }}
                  renderInput={(props) => <TextField {...props} fullWidth />}
                />
              </Stack>

              <Stack direction="row" spacing={2} marginTop={3}>
                <TextField
                  label="Valor mensual a pagar"
                  value={valorTotalMembresia}
                  disabled
                  fullWidth
                />
              </Stack>

              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={12} md={12}>
                  <Stack spacing={2}>
                    <TextField
                      required
                      style={{ paddingRight: 10 }}
                      margin="dense"
                      id="numeroSerieCarnet"
                      label="Numero de Serie Carnet"
                      fullWidth
                      {...getFieldProps("numeroSerieCarnet")}
                      error={Boolean(
                        touched.numeroSerieCarnet && errors.numeroSerieCarnet
                      )}
                      helperText={
                        touched.numeroSerieCarnet && errors.numeroSerieCarnet
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={6} md={6}>
                  {getFieldProps("rut").value !== "" &&
                    !Boolean(touched.rut && errors.rut) &&
                    !Boolean(touched.nombre && errors.nombre) &&
                    !Boolean(touched.direccion && errors.direccion) &&
                    !Boolean(touched.correo && errors.correo) &&
                    !Boolean(touched.telefono && errors.telefono) && (
                      <Stack spacing={2} direction="column">
                        <Button
                          onClick={() => {
                            setMostrarContratoPreeliminar(true);
                          }}
                          variant="contained"
                          fullWidth
                        >
                          Mostrar Borrador Contrato
                        </Button>

                        <Stack>
                          <Alert severity="info">
                            En el recuadro de abajo debe ir la firma del
                            cliente. Cuando la firma se encuentre lista, se debe
                            presionar el botón firmar contrato.
                          </Alert>
                          <Stack direction="row">
                            <Button
                              onClick={() => {
                                signatureRef.current.clear();
                              }}
                              variant="contained"
                              color="inherit"
                            >
                              Limpiar Firma
                            </Button>
                          </Stack>

                          <SignatureCanvas
                            canvasProps={{
                              style: { border: "1px solid #000000" },
                              height: 600,
                            }}
                            ref={signatureRef}
                          />
                        </Stack>

                        {/* edadCalculada >= 18 && (
                          <Stack direction="row" spacing={2}>
                            <Button
                              fullWidth
                              onClick={() => {
                                setTomarFotoDNIFront(true);
                              }}
                              variant="contained"
                              startIcon={<CloudUploadIcon />}
                            >
                              Foto Documento de Identidad (Frontal)
                            </Button>
                            <Button
                              fullWidth
                              onClick={() => {
                                setTomarFotoDNIPost(true);
                              }}
                              variant="contained"
                              startIcon={<CloudUploadIcon />}
                            >
                              Foto Documento de Identidad (Posterior)
                            </Button>
                          </Stack>
                            ) */}
                      </Stack>
                    )}
                </Grid>
                {/* <Grid item xs={12} md={12}>
                  <Stack spacing={2}>
                    edadCalculada >= 18 && (
                      <Stack spacing={4}>
                        <Alert severity="info">
                          Es importante ingresar las fotos de las cara para que
                          se pueda ingresar a las canchas.
                        </Alert>
                        <Stack direction="row" spacing={2}>
                          <Button
                            fullWidth
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            onClick={() => {
                              setTomarFotoFoto1(true);
                            }}
                          >
                            Foto Cara 1
                          </Button>

                          <Button
                            fullWidth
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            onClick={() => {
                              setTomarFotoFoto2(true);
                            }}
                          >
                            Foto Cara 2
                          </Button>

                          <Button
                            fullWidth
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            onClick={() => {
                              setTomarFotoFoto3(true);
                            }}
                          >
                            Foto Cara 3
                          </Button>
                        </Stack>
                      </Stack>
                    )
                  </Stack>
                  </Grid> */}
              </Grid>

              <Button
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancelar
              </Button>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                type="submit"
                color="primary"
              >
                Firmar Contrato
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Stack>

        {tomarFotoDNIFront && (
          <DialogTomarFoto
            open={tomarFotoDNIFront}
            setOpen={setTomarFotoDNIFront}
            setData={setImagenDNIFront}
            data={imagenDNIFront}
            titulo="Foto Documento de Identidad Parte Frontal"
          />
        )}

        {tomarFotoDNIPost && (
          <DialogTomarFoto
            open={tomarFotoDNIPost}
            setOpen={setTomarFotoDNIPost}
            setData={setImagenDNIPost}
            data={imagenDNIPost}
            titulo="Foto Documento de Identidad Parte Posterior"
          />
        )}

        {tomarFotoFoto1 && (
          <DialogTomarFoto
            open={tomarFotoFoto1}
            setOpen={setTomarFotoFoto1}
            setData={setImagenFoto1}
            data={imagenFoto1}
            titulo="Foto Cara 1"
          />
        )}

        {tomarFotoFoto2 && (
          <DialogTomarFoto
            open={tomarFotoFoto2}
            setOpen={setTomarFotoFoto2}
            setData={setImagenFoto2}
            data={imagenFoto2}
            titulo="Foto Cara 2"
          />
        )}

        {tomarFotoFoto3 && (
          <DialogTomarFoto
            open={tomarFotoFoto3}
            setOpen={setTomarFotoFoto3}
            setData={setImagenFoto3}
            data={imagenFoto3}
            titulo="Foto Cara 3"
          />
        )}

        {mostrarContratoPreeliminar && (
          <Dialog open={mostrarContratoPreeliminar} fullWidth maxWidth="lg">
            <DialogTitle id="form-dialog-title">
              Contrato Preeliminar
            </DialogTitle>
            <DialogContent>
              <iframe
                src="/files/CONTRATO_DE_SOCIO.pdf"
                width="100%"
                height={500}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setMostrarContratoPreeliminar(false);
                }}
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Container>
    </Page>
  );
}
