import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const webpayApi = createApi({
  reducerPath: "webpayApi",
  baseQuery: baseQuery(`${API_SERVER}/webpay`),
  tagTypes: ["Webpay"],
  endpoints: (builder) => ({
    create: builder.mutation({
      query: (body) => ({
        url: `/create`,
        method: "POST",
        body,
      }),
    }),
    status: builder.mutation({
      query: (token) => ({ url: `/status/${token}`, method: "GET" }),
    }),
    commit: builder.mutation({
      query: (body) => ({
        url: `/commit`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useCreateMutation, useCommitMutation, useStatusMutation } =
  webpayApi;
