export function formatAsCLP(amount) {
  const formatter = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  });
  if (isNaN(Number(amount))) {
    return formatter.format(0);
  }
  return formatter.format(amount);
}
