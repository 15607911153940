import { Box, Container, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { MotionContainer, varBounceIn } from "../animate";

export const PortadaReservaCliente = () => {
  return (
    <Box>
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Selecciona tu cancha favorita.
              </Typography>
            </motion.div>
            <Typography sx={{ color: "text.secondary" }}>
              Te invitamos a seleccionar la cancha que más te guste y puedas
              visualizar si quedan cupos disponibles para que vengas a disfrutar
              con amigos y familiares
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/illustration_select_cancha.png"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />
            </motion.div>
          </Box>
        </MotionContainer>
      </Container>
    </Box>
  );
};
