import { useEffect, useState } from "react";
import {
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Card,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import dayjs from "dayjs";

export default function DialogVerReserva(props) {
  const { open, setOpen, reserva, canchaName } = props;

  const [usuario, setUsuario] = useState(null);
  const [isLoadingDatoReserva, setIsLoadingDatoReserva] = useState(false);

  useEffect(() => {
    setIsLoadingDatoReserva(true);
    const docRef = doc(db, `usuarios/${reserva.uuidUsuarioReserva}`);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data) {
          setUsuario(data);
        }
      }
      setIsLoadingDatoReserva(false);
    });
  }, [reserva]);

  const color = "primary";

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      id="dialogLeerQR"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Reserva</DialogTitle>
      {!isLoadingDatoReserva && (
        <Stack>
          <Card
            sx={{
              border: 1,
              borderColor: (theme) => theme.palette[color].dark,
              padding: 5,
              boxShadow: 0,
              textAlign: "center",
              color: (theme) => theme.palette[color].dark,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={5} md={5}>
                <Stack>
                  <Typography variant="h6">Número Ticket</Typography>
                  <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    {reserva.numeroTicket}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={5} md={5}>
                <Stack>
                  <Typography variant="h6">Fecha</Typography>
                  <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    {dayjs(reserva.fecha).format("DD/MM/YYYY")}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={5} md={5}>
                <Stack>
                  <Typography variant="h6">Cancha</Typography>
                  <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    {canchaName}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={5} md={5}>
                <Stack>
                  <Typography variant="h6">Tipo Pago</Typography>
                  <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    {reserva.tipoPago}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Divider style={{ padding: 5 }} />

            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12} md={12}>
                <Stack>
                  <Typography variant="h5">Tarjeta de Ingreso</Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={5} md={5}>
                <Stack>
                  <Typography variant="h6">Hora</Typography>
                  <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    {reserva.horas.toString()}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={5} md={5}></Grid>
            </Grid>

            {usuario && (
              <Grid container marginTop={2}>
                <Grid item xs={5} md={5}>
                  <Stack>
                    <Typography variant="h6">Usuario</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      {usuario.nombre}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={5} md={5}></Grid>
              </Grid>
            )}
          </Card>
        </Stack>
      )}
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpen(false)}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
