import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const usuariosApi = createApi({
  reducerPath: "usuariosApi",
  baseQuery: baseQuery(`${API_SERVER}/usuarios`),
  tagTypes: ["Usuarios"],
  endpoints: (builder) => ({
    getUsuario: builder.query({
      query: (id) => {
        return { url: `/${id}` };
      },
      providesTags: (result, error, id) => [{ type: "Usuarios", id: "LIST" }],
    }),
    add: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "POST",
        body,
      }),
    }),
    addConvenio: builder.mutation({
      query: (body) => ({
        url: `/convenio`,
        method: "POST",
        body,
      }),
    }),
    firmarContrato: builder.mutation({
      query: (body) => ({
        url: `/firmar-contrato`,
        method: "POST",
        body,
      }),
    }),
    sendCodePayment: builder.mutation({
      query: (body) => ({
        url: `/send-code-payment`,
        method: "POST",
        body,
      }),
    }),
    validateCodePayment: builder.mutation({
      query: (body) => ({
        url: `/validate-code-payment`,
        method: "POST",
        body,
      }),
    }),
    delete: builder.mutation({
      query(id) {
        return {
          url: `/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useAddMutation,
  useDeleteMutation,
  useAddConvenioMutation,
  useFirmarContratoMutation,
  useGetUsuarioQuery,
  useLazyGetUsuarioQuery,
  useSendCodePaymentMutation,
  useValidateCodePaymentMutation,
} = usuariosApi;
