import React, { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Page from "../components/Page";
import { Titulo } from "src/components/Titulo";
import { Box, Grid, Paper, Skeleton, Stack, TextField } from "@mui/material";
import { ReservaCanchas } from "src/components/reservas/ReservaCanchas";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { reservasApi } from "src/services/reservasApi";

dayjs.extend(timezone);
dayjs.extend(utc);

dayjs.tz.setDefault("America/Santiago");
export default function Reservas() {
  const [fechaSelect, setFechaSelect] = useState(dayjs());

  const { data: reservas, isLoading: isLoadingCanchas } =
    reservasApi.useGetReservasAllQuery(
      dayjs(fechaSelect).format("YYYY-MM-DD"),
      {
        pollingInterval: 30000,
      }
    );

  return (
    <Page title="Padel Club Rengo | Reservas">
      <Container>
        <Titulo>
          <Typography variant="h4">Reservas</Typography>
        </Titulo>
      </Container>

      <Stack marginTop={5}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Fecha"
            views={["day", "month", "year"]}
            value={fechaSelect}
            maxDate={dayjs().add("3", "day")}
            inputFormat="DD/MM/YYYY"
            outputFormat="DD/MM/YYYY"
            onChange={(fecha) => {
              setFechaSelect(fecha);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Fecha" fullWidth />
            )}
          />
        </LocalizationProvider>
        {!isLoadingCanchas ? (
          <Grid item xs={12} md={12} mt={3}>
            <Grid container spacing={2}>
              {reservas &&
                reservas.map((value, idx) => {
                  return (
                    <Grid key={`canchas_${idx}`} item xs={3} md={3}>
                      <Paper
                        sx={{
                          width: "100%",
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                        }}
                      >
                        <ReservaCanchas
                          cancha={value.cancha}
                          fechaSelect={fechaSelect}
                          reservas={value.reservas}
                          setFechaSelect={setFechaSelect}
                        />
                      </Paper>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        ) : (
          <Box display="flex" justifyContent="center" mt={10}>
            {isLoadingCanchas && (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </Box>
        )}
      </Stack>
    </Page>
  );
}
