import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";

import Page from "../components/Page";
import { Titulo } from "src/components/Titulo";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import BotonAgregar from "src/components/tabla/BotonAgregar";
import Permisos from "src/utils/Permisos";
import Tabla from "src/components/tabla/Tabla";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import { useNavigate } from "react-router-dom";
import { usuariosApi } from "src/services/usuariosApi";
import { useEffect, useState } from "react";
import { ID_EMPRESA } from "src/utils/urlBack_End";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import DialogCrearUsuarioConvenio from "src/components/gestionClientes/dialog/DialogCrearUsuarioConvenio";
import DialogCrearUsuario from "src/components/gestionUsuarios/dialog/DialogCrearUsuario";
import { Add } from "@mui/icons-material";
export default function Clientes() {
  const navigate = useNavigate();
  const [deleteUser] = usuariosApi.useDeleteMutation();
  const [openCrear, setOpenCrear] = useState(false);
  const [openCrearConvenio, setOpenCrearConvenio] = useState(false);

  const [clientes, setClientes] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  useEffect(() => {
    setIsLoadingUsers(true);
    const q = query(
      collection(db, "usuarios"),
      where("empresas", "array-contains-any", [
        {
          tipoUsuario: "Cliente",
          uuidEmpresa: ID_EMPRESA,
        },
        {
          tipoUsuario: "Soci@",
          uuidEmpresa: ID_EMPRESA,
        },
      ])
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        if (data) {
          cities.push({
            id: doc.id,
            ...data,
          });
        }
      });
      setClientes(cities);
      setIsLoadingUsers(false);
    });
    return () => unsubscribe();
  }, []);

  const handleViewUser = (row) => {
    navigate(`${row.id}`);
  };

  const eliminarUsuario = (idUsuario) => {
    AlertaModal({
      title: "Eliminar cliente",
      confirmButtonText: "Eliminar",
    })
      .fire()
      .then((response) => {
        if (response.isConfirmed) {
          deleteUser(idUsuario)
            .unwrap()
            .then(() => {
              AlertaToast({
                element: "cliente",
                action: "eliminado",
              }).fire();
            })
            .catch(() => {
              AlertaToast({
                action: "Error no determinado",
                fullText: true,
                type: "error",
              }).fire();
            });
        }
      });
  };

  return (
    <Page title="Padel Club Rengo | Clientes">
      <Container>
        <Titulo>
          <Typography variant="h4">Clientes</Typography>
        </Titulo>

        {!isLoadingUsers && clientes ? (
          <Stack marginTop={5} spacing={2}>
            <Tabla
              encabezados={[
                {
                  id: "rut",
                  label: "Rut",
                },
                {
                  id: "nombre",
                  label: "Nombre",
                },
                {
                  id: "telefono",
                  label: "Teléfono",
                },
                {
                  id: "correo",
                  label: "Correo",
                },
                {
                  id: "tipoUsuario",
                  label: "Tipo Usuario",
                },
                { id: "", label: "Acciones" },
              ]}
              buscarPor={["nombre", "rut", "tipoUsuario"]}
              etiquetaBusqueda="Buscar clientes"
              eliminarElemento={(e) => eliminarUsuario(e)}
              permisosEliminar={Permisos.eliminarClientes}
              editarElemento={(e) => {
                handleViewUser(e);
              }}
              permisosEditar={Permisos.editarClientes}
              data={clientes.map((u) => ({
                ...u,
                rut: formatRut(u.rut, RutFormat.DOTS_DASH),
                tipoUsuario: u.empresas[0].tipoUsuario,
              }))}
              botones={[
                <BotonAgregar
                  onClick={() => setOpenCrear(true)}
                  permission={Permisos.agregarClientes}
                />,
                <Button
                  onClick={() => navigate("nuevo-socio")}
                  startIcon={<Add />}
                  variant="contained"
                >
                  Agregar Socio
                </Button>,
              ]}
            />
          </Stack>
        ) : (
          <Box display="flex" justifyContent="center" mt={10}>
            {isLoadingUsers && (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </Box>
        )}
        {openCrear && (
          <DialogCrearUsuario
            open={openCrear}
            setOpen={setOpenCrear}
            selectDefinidoUser={true}
          />
        )}
        {openCrearConvenio && (
          <DialogCrearUsuarioConvenio
            open={openCrearConvenio}
            setOpen={setOpenCrearConvenio}
          />
        )}
      </Container>
    </Page>
  );
}
