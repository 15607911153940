import { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Avatar,
  ListItem,
  ListItemAvatar,
  List,
  Skeleton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { SportsTennisOutlined as SportsTennisOutlinedIcon } from "@mui/icons-material";
import { db } from "src/utils/firebaseInit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useSelector } from "react-redux";
import { formatAsCLP } from "src/utils/formatNumber";

export default function DialogMisReservas(props) {
  const { open, setOpen, cancha } = props;

  const { id: uuidUsuario } = useSelector((state) => state.auth?.user);

  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [reservas, setReservas] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    const q = query(
      collection(db, `canchas/${cancha.id}`, "reservas"),
      where("uuidUsuarioReserva", "==", uuidUsuario)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const reservasAux = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        reservasAux.push({
          id: doc.id,
          ...data,
        });
      });
      setReservas(reservasAux);
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, [uuidUsuario, cancha]);

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogMisReservas"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">{cancha.nombre}</DialogTitle>
          <DialogContent>
            {isLoading ? (
              <Skeleton width="100%" height={500} />
            ) : (
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {reservas && reservas.length >= 1 ? (
                  reservas.map((item, idx) => {
                    return (
                      <ListItem key={idx} onClick={() => {}}>
                        <ListItemAvatar>
                          <Avatar>
                            <SportsTennisOutlinedIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${item.fecha} - ${item.horas[0]}`}
                          secondary={`${formatAsCLP(item.valor)} - ${
                            item.tipoPago
                          }`}
                        />
                      </ListItem>
                    );
                  })
                ) : (
                  <Stack
                    alignContent="center"
                    justifyItems="center"
                    justifyContent="center"
                    mt={10}
                    ml="40%"
                  >
                    <Typography gutterBottom align="center" variant="subtitle1">
                      Sin resultados
                    </Typography>
                    <Typography variant="body2" align="center">
                      No se han encontrado elementos.
                    </Typography>
                  </Stack>
                )}
              </List>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
