import { write, read, utils } from "xlsx";
import FileSaver from "file-saver";

const toJSON = (workbook, heading) => {
  let result = {};
  workbook.SheetNames.forEach((sheetName) => {
    const roa = utils.sheet_to_json(workbook.Sheets[sheetName], {
      header: heading,
      raw: false,
      dateNF: "yyyy-mm-dd",
    });
    if (roa.length) result = roa;
  });
  const resultTransformed = result.slice(1).map(({ value, ...rest }) => ({
    ...rest,
    value: Number(value),
  }));
  return resultTransformed;
};

const toSheet = (workbook, jsonObject, heading) => {
  utils.sheet_add_aoa(workbook, [heading]);
  utils.sheet_add_json(workbook, jsonObject, {
    origin: "A2",
    skipHeader: true,
  });
  const wb = {
    Sheets: { lista_precios: workbook },
    SheetNames: ["lista_precios"],
  };
  const excelBuffer = write(wb, {
    bookType: "xlsx",
    type: "array",
    cellStyles: true,
  });

  return excelBuffer;
};

const readFile = (d, heading) => {
  const workbook = read(d, {
    type: "binary",
    cellText: false,
    cellDates: true,
  });
  return toJSON(workbook, heading);
};

const createSheet = (jsonObject, name, heading) => {
  const workbook = utils.book_new();
  const blob = new Blob([toSheet(workbook, jsonObject, heading)], {
    type: "application/octet-stream",
  });
  FileSaver.saveAs(blob, `${name}.xlsx`);
};

export { toSheet, toJSON, createSheet, readFile };
