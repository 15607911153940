import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import {
  Card,
  Container,
  Grid,
  Typography,
  IconButton,
  CardMedia,
  Button,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import Page from "../components/Page.js";
import Permisos from "../utils/Permisos.js";
import { AlertaModal, AlertaToast } from "../components/AlertaSwall.js";
import { Titulo } from "src/components/Titulo";
import { db, storage } from "../utils/firebaseInit.js";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
} from "firebase/firestore";
import { ID_PADEL } from "src/utils/urlBack_End.js";
import { Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { ValidarPermisos } from "src/components/ValidarPermisos.js";
import { deleteObject, ref } from "firebase/storage";
import DialogCrearImagenes from "src/components/imagenes/DialogCrearNoticia.js";

export default function Imagenes() {
  const [openCrear, setOpenCrear] = useState(false);

  const [imagen, setImagen] = useState([]);
  const [isLoadingImagen, setIsLoadingImagen] = useState(false);

  useEffect(() => {
    setIsLoadingImagen(true);
    const q = query(collection(db, `padel/${ID_PADEL}/imagenes`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        cities.push({
          id: doc.id,
          ...data,
        });
      });
      setImagen(cities);
      setIsLoadingImagen(false);
    });
    return () => unsubscribe();
  }, []);

  const eliminarImagen = (e) => {
    AlertaModal({
      title: "Eliminar imágenes",
      confirmButtonText: "Eliminar",
    })
      .fire()
      .then((response) => {
        if (response.isConfirmed) {
          try {
            const fileRef = ref(storage, e.img.src);
            deleteObject(fileRef)
              .then(() => {
                deleteDoc(doc(db, `padel/${ID_PADEL}/imagenes`, e.id))
                  .then(() => {
                    AlertaToast({
                      action: "eliminada",
                      element: "imagen",
                    }).fire();
                  })
                  .catch(() => {
                    AlertaToast({
                      action: "Error en el servidor",
                      fullText: true,
                      type: "error",
                    }).fire();
                  });
              })
              .catch((error) => {
                deleteDoc(doc(db, `padel/${ID_PADEL}/imagenes`, e.id))
                  .then(() => {
                    AlertaToast({
                      action: "eliminada",
                      element: "imagen",
                    }).fire();
                  })
                  .catch(() => {
                    AlertaToast({
                      action: "Error en el servidor",
                      fullText: true,
                      type: "error",
                    }).fire();
                  });
              });
          } catch {
            AlertaToast({
              action: "Error en el servidor",
              fullText: true,
              type: "error",
            }).fire();
          }
        }
      });
  };

  return (
    <Page title="Padel Club Rengo | Imágenes">
      <Container>
        <Titulo>
          <Typography variant="h4">Imágenes</Typography>
        </Titulo>

        <Stack direction={"row-reverse"} padding="20px">
          <ValidarPermisos permission={Permisos.agregarImagenes}>
            <Button
              onClick={() => {
                setOpenCrear(true);
              }}
              variant="contained"
            >
              <AddIcon /> Agregar Imágenes
            </Button>
          </ValidarPermisos>
        </Stack>

        <Stack padding="20px">
          <Alert severity="info">
            Solo se pueden agregar o eliminar imágenes.
          </Alert>
        </Stack>

        {!isLoadingImagen && imagen ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {imagen.length >= 1 ? (
                imagen.map((e, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <Card sx={{ maxWidth: 345, height: 250 }}>
                      <ValidarPermisos permission={Permisos.eliminarImagenes}>
                        <Stack direction={"row-reverse"}>
                          <IconButton
                            onClick={() => {
                              eliminarImagen(e);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      </ValidarPermisos>
                      <CardMedia
                        component="img"
                        height="194"
                        image={e.img.src}
                        alt={e.img.alt}
                      />
                    </Card>
                  </Grid>
                ))
              ) : (
                <Stack
                  alignContent="center"
                  justifyItems="center"
                  justifyContent="center"
                  mt={10}
                  ml="40%"
                >
                  <Typography gutterBottom align="center" variant="subtitle1">
                    Sin resultados
                  </Typography>
                  <Typography variant="body2" align="center">
                    No se han encontrado elementos.
                  </Typography>
                </Stack>
              )}
            </Grid>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" mt={10}>
            {isLoadingImagen && (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </Box>
        )}
        {openCrear && (
          <DialogCrearImagenes open={openCrear} setOpen={setOpenCrear} />
        )}
      </Container>
    </Page>
  );
}
