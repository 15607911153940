/* eslint-disable jsx-a11y/iframe-has-title */
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Page from "../components/Page";
import { Titulo } from "src/components/Titulo";
import { useRef, useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { LoadingButton } from "@mui/lab";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import SignatureCanvas from "react-signature-canvas";
import {
  validateRut,
  deconstructRut,
  formatRut,
  RutFormat,
} from "@fdograph/rut-utilities";
import {
  Stack,
  Button,
  TextField,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { conveniosApi } from "src/services/conveniosApi";

export default function CrearConvenio() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [mostrarContratoPreeliminar, setMostrarContratoPreeliminar] =
    useState(false);
  const signatureRef = useRef({});
  const [firmarConvenioApi] = conveniosApi.useFirmarConvenioMutation();

  const LoginSchema = Yup.object().shape({
    rut: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
    nombre: Yup.string().required("Nombre requerido"),
    direccion: Yup.string().required("Dirección requerida"),
    correo: Yup.string().email("Correo no válido").required("Correo requerido"),
    telefono: Yup.string().test(
      "telefono test",
      "Teléfono no válido",
      (value) => matchIsValidTel(value)
    ),
    nombreRepresentanteLegal: Yup.string().required(
      "Nombre Representante Legal requerido"
    ),
    rutRepresentanteLegal: Yup.string().required(
      "Rut Representante Legal requerido"
    ),
  });

  const formik = useFormik({
    initialValues: {
      rut: "",
      nombre: "",
      correo: "",
      telefono: "+56",
      direccion: "",
      fechaInicioContrato: dayjs(),
      nombreRepresentanteLegal: "",
      rutRepresentanteLegal: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setTouched,
    setFieldValue,
  } = formik;

  const generarContrato = async () => {
    const rutAux =
      deconstructRut(getFieldProps("rut").value.toUpperCase()).digits +
      deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier;

    const rutRepreAux =
      deconstructRut(getFieldProps("rutRepresentanteLegal").value.toUpperCase())
        .digits +
      deconstructRut(getFieldProps("rutRepresentanteLegal").value.toUpperCase())
        .verifier;
    const data = {
      rut: formatRut(rutAux, RutFormat.DOTS_DASH),
      domicilio: getFieldProps("direccion").value,
      tituloEmpresa: String(getFieldProps("nombre").value).toUpperCase(),
      dia: dayjs().format("DD"),
      mes: dayjs().format("MM"),
      anio: dayjs().format("YYYY"),
      nombre: getFieldProps("nombre").value,
      email: getFieldProps("correo").value,
      nombreRepresentante: getFieldProps("nombreRepresentanteLegal").value,
      rutRepresentante: formatRut(rutRepreAux, RutFormat.DOTS_DASH),
      nombreRepresentanteFirma: getFieldProps("nombreRepresentanteLegal").value,
      nombreEmpresaFirma: getFieldProps("nombre").value,
    };

    try {
      await firmarConvenioApi({
        ...data,
        blobImagen: signatureRef.current
          .getTrimmedCanvas()
          .toDataURL("image/jpg"),
      }).unwrap();
    } catch (error) {}
  };

  const guardarDatos = () => {
    setIsLoading(true);
    const rutAux =
      deconstructRut(getFieldProps("rut").value.toUpperCase()).digits +
      deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier;

    const nombreEmpresa = getFieldProps("nombre").value;
    const direccionEmpresa = getFieldProps("direccion").value;
    const correoEmpresa = getFieldProps("correo").value;
    const telefonoEmpresa = String(getFieldProps("telefono").value)
      .replace("+56", "")
      .replace(/\s/g, "");
    const rutRepresentanteLegal = getFieldProps("rutRepresentanteLegal").value;
    const nombreRepresentanteLegal = getFieldProps(
      "nombreRepresentanteLegal"
    ).value;

    const data = {
      rut: rutAux,
      nombre: nombreEmpresa,
      direccion: direccionEmpresa,
      correo: correoEmpresa,
      telefono: telefonoEmpresa,
      nombreRepresentanteLegal: nombreRepresentanteLegal,
      rutRepresentanteLegal: rutRepresentanteLegal,
      estado: true,
      fechaInicioContrato: dayjs(
        getFieldProps("fechaInicioContrato").value
      ).format("YYYY-MM-DD"),
    };

    if (
      nombreEmpresa === "" ||
      direccionEmpresa === "" ||
      correoEmpresa === "" ||
      telefonoEmpresa === "" ||
      rutRepresentanteLegal === "" ||
      nombreRepresentanteLegal === ""
    ) {
      setIsLoading(false);
      AlertaToast({
        action: "Todos los campos son requeridos.",
        fullText: true,
      }).fire();
    } else {
      AlertaModal({
        title: "agregar el convenio",
        confirmButtonText: "Sí, agregar",
      })
        .fire()
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await addDoc(collection(db, "convenios"), data);
              await generarContrato();
              setIsLoading(false);
              navigate(-1);
              AlertaToast({
                action: "Los datos han sido guardados con éxito.",
                fullText: true,
              }).fire();
            } catch (error) {
              setIsLoading(false);
              AlertaToast({
                action:
                  "El contrato no ha podido ser firmado. Volver a intentar o contactar a soporte",
                fullText: true,
                type: "error",
              }).fire();
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handlePhoneNumber = (v) => {
    setFieldValue("telefono", v);
  };

  return (
    <Page title="Padel Club Rengo | Convenio">
      <Container>
        <Titulo>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={2} md={3} align="left"></Grid>
            <Grid item xs={8} md={6} align="center">
              <Typography variant="h4">Nuevo Convenio</Typography>
            </Grid>
            <Grid item xs={2} md={3} align="end"></Grid>
          </Grid>
        </Titulo>

        <Stack marginTop={5}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack direction="row" spacing={2}>
                <TextField
                  required
                  margin="dense"
                  id="rut"
                  label="Rut"
                  fullWidth
                  {...getFieldProps("rut")}
                  error={Boolean(touched.rut && errors.rut)}
                  helperText={touched.rut && errors.rut}
                  autoFocus
                />
                <TextField
                  required
                  margin="dense"
                  id="nombre"
                  label="Nombre"
                  fullWidth
                  {...getFieldProps("nombre")}
                  error={Boolean(touched.nombre && errors.nombre)}
                  helperText={touched.nombre && errors.nombre}
                />
              </Stack>

              <Stack direction="row" spacing={2} marginTop={3}>
                <TextField
                  required
                  style={{ paddingRight: 10 }}
                  margin="dense"
                  id="direccion"
                  label="Dirección"
                  fullWidth
                  {...getFieldProps("direccion")}
                  error={Boolean(touched.direccion && errors.direccion)}
                  helperText={touched.direccion && errors.direccion}
                />
                <TextField
                  required
                  style={{ paddingRight: 10 }}
                  margin="dense"
                  id="correo"
                  label="Correo"
                  type="email"
                  fullWidth
                  {...getFieldProps("correo")}
                  error={Boolean(touched.correo && errors.correo)}
                  helperText={touched.correo && errors.correo}
                />
              </Stack>

              <Stack direction="row" spacing={2} marginTop={3}>
                <MuiTelInput
                  required
                  margin="dense"
                  id="telefono"
                  label="Teléfono"
                  forceCallingCode
                  defaultCountry="CL"
                  preferredCountries={["CL"]}
                  fullWidth
                  onFocus={() =>
                    setTouched({ ...touched, telefono: true }, true)
                  }
                  value={getFieldProps("telefono").value}
                  onChange={(e) => handlePhoneNumber(e)}
                  onBlur={getFieldProps("telefono").onBlur}
                  error={Boolean(touched.telefono && errors.telefono)}
                  helperText={touched.telefono && errors.telefono}
                />
              </Stack>
              <Stack direction="row" spacing={2} marginTop={3}>
                <TextField
                  required
                  style={{ paddingRight: 10 }}
                  margin="dense"
                  id="rutRepresentanteLegal"
                  label="Rut Representante Legal"
                  fullWidth
                  {...getFieldProps("rutRepresentanteLegal")}
                  error={Boolean(
                    touched.rutRepresentanteLegal &&
                      errors.rutRepresentanteLegal
                  )}
                  helperText={
                    touched.rutRepresentanteLegal &&
                    errors.rutRepresentanteLegal
                  }
                />
                <TextField
                  required
                  style={{ paddingRight: 10 }}
                  margin="dense"
                  id="nombreRepresentanteLegal"
                  label="Nombre Representante Legal"
                  fullWidth
                  {...getFieldProps("nombreRepresentanteLegal")}
                  error={Boolean(
                    touched.nombreRepresentanteLegal &&
                      errors.nombreRepresentanteLegal
                  )}
                  helperText={
                    touched.nombreRepresentanteLegal &&
                    errors.nombreRepresentanteLegal
                  }
                />
              </Stack>
              <Stack direction="row" spacing={2} marginTop={3}>
                <DatePicker
                  label="Fecha Inicio Convenio"
                  views={["day", "month", "year"]}
                  inputFormat="dd/MM/yyyy"
                  value={getFieldProps("fechaInicioContrato").value}
                  onChange={(v) => {
                    setFieldValue("fechaInicioContrato", v);
                  }}
                  renderInput={(props) => <TextField {...props} fullWidth />}
                />
              </Stack>

              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={12} md={6}>
                  {getFieldProps("rut").value !== "" &&
                    getFieldProps("nombre").value !== "" &&
                    getFieldProps("direccion").value !== "" &&
                    getFieldProps("correo").value !== "" &&
                    getFieldProps("telefono").value !== "" &&
                    !Boolean(touched.rut && errors.rut) &&
                    !Boolean(touched.nombre && errors.nombre) &&
                    !Boolean(touched.direccion && errors.direccion) &&
                    !Boolean(touched.correo && errors.correo) &&
                    !Boolean(touched.telefono && errors.telefono) && (
                      <Stack spacing={2} direction="column">
                        <Button
                          onClick={() => {
                            setMostrarContratoPreeliminar(true);
                          }}
                          variant="contained"
                          fullWidth
                        >
                          Mostrar Borrador Convenio
                        </Button>

                        <Stack>
                          <Alert severity="info">
                            En el recuadro de abajo debe ir la firma del
                            cliente. Cuando la firma se encuentre lista, se debe
                            presionar el botón firmar contrato.
                          </Alert>
                          <Stack direction="row">
                            <Button
                              onClick={() => {
                                signatureRef.current.clear();
                              }}
                              variant="contained"
                              color="inherit"
                            >
                              Limpiar Firma
                            </Button>
                          </Stack>

                          <SignatureCanvas
                            canvasProps={{
                              style: { border: "1px solid #000000" },
                              height: 600,
                            }}
                            ref={signatureRef}
                          />
                        </Stack>
                      </Stack>
                    )}
                </Grid>
              </Grid>

              <Button
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancelar
              </Button>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                type="submit"
                color="primary"
              >
                Firmar Convenio
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Stack>

        {mostrarContratoPreeliminar && (
          <Dialog open={mostrarContratoPreeliminar} fullWidth maxWidth="lg">
            <DialogTitle id="form-dialog-title">
              Convenio Preeliminar
            </DialogTitle>
            <DialogContent>
              <iframe
                src="/files/Convenio_Cancha_Padel.pdf"
                width="100%"
                height={500}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setMostrarContratoPreeliminar(false);
                }}
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Container>
    </Page>
  );
}
