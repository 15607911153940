import ReactApexChart from "react-apexcharts";
import {
  Card,
  CardHeader,
  Box,
  TextField,
  Stack,
  Skeleton,
} from "@mui/material";
import { formatAsCLP } from "src/utils/formatNumber";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";

export default function GraficoIngresosGastos(props) {
  const { canchas } = props;
  const [fechaSelect, setFechaSelect] = useState(dayjs());

  const [series, setSeries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const seriesAux = [];
      await Promise.all(
        canchas.map(async (item) => {
          const q = query(
            collection(db, `canchas`),
            where("uuidCancha", "==", item.id)
          );
          const dataQ = await getDocs(q);

          if (dataQ.empty) {
          } else {
            const newData = dataQ.docs[0];
            const q2 = query(collection(db, `canchas/${newData.id}/reservas`));
            const dataQ2 = await getDocs(q2);

            const reservasAux = [];
            dataQ2.forEach((doc) => {
              const data = doc.data();
              reservasAux.push({
                id: doc.id,
                ...data,
              });
            });

            const totalValores = [];

            [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              // eslint-disable-next-line array-callback-return
            ].map((element) => {
              let total = 0;

              reservasAux.forEach((e) => {
                const fechaReserva = `${String(e.fecha).split("-")[0]}-${
                  String(e.fecha).split("-")[1]
                }`;
                const fechaSelectReserva = `${dayjs(fechaSelect).format(
                  "YYYY"
                )}-${element}`;

                if (fechaReserva === fechaSelectReserva) {
                  total = total + e.valor;
                }
              });

              totalValores.push(total);
            });

            seriesAux.push({
              name: item.nombre,
              data: totalValores,
            });
          }
        })
      );
      setSeries(seriesAux);
    };
    fetchData().catch(() => {});
  }, [canchas, fechaSelect]);

  /*const chart = {
    series: [
      {
        name: "Ingresos",
        data: ingresos.map((ingreso) => ingreso.total),
      },
      {
        name: "Costos",
        data: gastos.map((gasto) => gasto.total),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
        },
      },
      stroke: {
        width: [3, 3],
        curve: "straight",
        dashArray: [0, 8],
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - " +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ""
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },

      dataLabels: {
        enabled: true,
        position: "top",
        offsetY: 0,
        formatter: function (val, opt) {
          return formatAsCLP(val);
        },
        // style: {
        //   fontSize: "12px",
        //   colors: [
        //     function ({ _value, seriesIndex, dataPointIndex, w }) {
        //       const yValues =
        //         w.globals.seriesRange[seriesIndex][dataPointIndex].y[0];
        //       const ratio = Math.abs(yValues.y1 - yValues.y2) / w.globals.maxY;
        //       return ratio < 0.2 ? "#000" : "#fff";
        //     },
        //   ],
        // },
      },
      xaxis: {
        type: "category",
        labels: {
          formatter: function (val, opt) {
            return val;
          },
          datetimeFormatter: {
            year: "YYYY",
            month: "MMMM",
          },
        },
        categories: ingresos.map((ingreso) => ingreso.fecha),
      },

      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + ":";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + ":";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      // xaxis: {
      //   categories: isSuccess ? tasa.map((t) => t.x) : [],
      // },
    },
  };*/

  const chart = {
    series: [
      {
        name: "Net Profit",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "Revenue",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "Free Cash Flow",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ],
      },
      yaxis: {
        title: {
          text: "$ (CLP)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return formatAsCLP(val) + " CLP";
          },
        },
      },
    },
  };

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardHeader title={"Ingresos Canchas - Año"} subheader="" />

      <Stack margin={3} spacing={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Fecha"
            views={["year"]}
            value={fechaSelect}
            maxDate={dayjs()}
            inputFormat="YYYY"
            outputFormat="YYYY"
            onChange={(fecha) => {
              setFechaSelect(fecha);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Fecha" fullWidth />
            )}
          />
        </LocalizationProvider>

        <Box dir="ltr">
          {/*<ReactApexChart
          height={350}
          options={merge(BaseOptionChart(), chart.options)}
          series={chart.series}
          type="line"
  />*/}
          {series && series.length >= 1 ? (
            <ReactApexChart
              options={chart.options}
              series={series}
              type="bar"
              height={350}
            />
          ) : (
            <Skeleton width="100%" height={500} />
          )}
        </Box>
      </Stack>
    </Card>
  );
}
