import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import {
  Card,
  CardHeader,
  Container,
  Grid,
  Typography,
  CardMedia,
  CardActionArea,
} from "@mui/material";
import { useEffect, useState } from "react";
import Page from "../components/Page.js";
import { Titulo } from "src/components/Titulo";
import { db } from "../utils/firebaseInit.js";
import { collection, getDocs, query, where } from "firebase/firestore";
import { ID_PADEL } from "src/utils/urlBack_End.js";
import { SportsTennis as SportsTennisIcon } from "@mui/icons-material";
import { Stack } from "@mui/system";
import DialogMisReservas from "src/components/misReservas/DialogMisReservas.js";

export default function MisReservas() {
  const [mostrarReservasCanchas, setMostrarReservasCanchas] = useState(false);

  const [selectReserva, setSelectReserva] = useState(null);

  const [canchas, setCanchas] = useState([]);
  const [isLoadingNoticias, setIsLoadingNoticias] = useState(false);

  useEffect(() => {
    setIsLoadingNoticias(true);

    const fetchData = async () => {
      const canchasAux = [];

      const q = query(collection(db, `padel/${ID_PADEL}/canchas`));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        canchasAux.push({
          id: doc.id,
          ...data,
        });
      });

      const canchasAux2 = [];

      await Promise.all(
        canchasAux.map(async (item) => {
          const q2 = query(
            collection(db, "canchas"),
            where("uuidCancha", "==", item.id)
          );

          const querySnapshot2 = await getDocs(q2);
          querySnapshot2.forEach((doc) => {
            canchasAux2.push({
              nombre: item.nombre,
              id: doc.id,
              url: item.url,
              ...doc.data(),
            });
          });
        })
      );
      setCanchas(canchasAux2);
      setIsLoadingNoticias(false);
    };

    fetchData().catch(() => setIsLoadingNoticias(false));
  }, []);

  return (
    <Page title="Padel Club Rengo | Mis Reservas">
      <Container>
        <Titulo>
          <Typography variant="h4">Reservas Realizadas</Typography>
        </Titulo>

        {!isLoadingNoticias && canchas ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {canchas.length >= 1 ? (
                canchas.map((e, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <Card sx={{ maxWidth: 345 }}>
                      <CardActionArea
                        onClick={() => {
                          setSelectReserva(e);
                          setMostrarReservasCanchas(true);
                        }}
                      >
                        <CardHeader
                          avatar={<SportsTennisIcon />}
                          title={e.nombre}
                        />
                        <br></br>
                        <CardMedia
                          component="img"
                          height="194"
                          image={e.url}
                          alt={e.nombre}
                        />
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Stack
                  alignContent="center"
                  justifyItems="center"
                  justifyContent="center"
                  mt={10}
                  ml="40%"
                >
                  <Typography gutterBottom align="center" variant="subtitle1">
                    Sin resultados
                  </Typography>
                  <Typography variant="body2" align="center">
                    No se han encontrado elementos.
                  </Typography>
                </Stack>
              )}
            </Grid>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" mt={10}>
            {isLoadingNoticias && (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </Box>
        )}
      </Container>
      {mostrarReservasCanchas && (
        <DialogMisReservas
          open={mostrarReservasCanchas}
          setOpen={setMostrarReservasCanchas}
          cancha={selectReserva}
        />
      )}
    </Page>
  );
}
