import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { API_SERVER } from "../utils/urlBack_End";

export const getnetApi = createApi({
  reducerPath: "getnetApi",
  baseQuery: baseQuery(`${API_SERVER}/getnet`),
  tagTypes: ["Getnet"],
  endpoints: (builder) => ({
    create: builder.mutation({
      query: (body) => ({
        url: `/create`,
        method: "POST",
        body,
      }),
    }),
    status: builder.mutation({
      query: (token) => ({ url: `/status/${token}`, method: "GET" }),
    }),

    createMembresia: builder.mutation({
      query: (body) => ({
        url: `/create-membership`,
        method: "POST",
        body,
      }),
    }),
    statusMembresia: builder.mutation({
      query: ({ requestId, ...body }) => ({
        url: `/status-membership/${requestId}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useCreateMutation,
  useStatusMutation,
  useCreateMembresiaMutation,
  useStatusMembresiaMutation,
} = getnetApi;
