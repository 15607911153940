import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { AlertaToast } from "../AlertaSwall";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { Button } from "@mui/material";
import { saveAs } from "file-saver";

export const DatosCliente = (props) => {
  const { cliente } = props;

  const [isLoading, setIsLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    direccion: Yup.string().required("Dirección requerida"),
    correo: Yup.string().email("Correo no válido").required("Correo requerido"),
    telefono: Yup.string().test(
      "telefono test",
      "Teléfono no válido",
      (value) => matchIsValidTel(value)
    ),
  });

  const formik = useFormik({
    initialValues: {
      direccion: cliente.direccion,
      telefono: cliente.telefono,
      correo: cliente.correo,
      urlCertificado: cliente.urlCertificado ? cliente.urlCertificado : null,
      isChanged: false,
      isError: false,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setTouched,
    values,
    setValues,
  } = formik;

  const guardarDatos = async () => {
    setIsLoading(true);
    const body = {
      direccion: getFieldProps("direccion").value,
      telefono: getFieldProps("telefono").value,
    };

    try {
      await updateDoc(doc(db, "usuarios", cliente.id), body);
      AlertaToast({
        element: "cliente",
        action: "actualizado",
      }).fire();
      setValues((values) => ({ ...values, isChanged: false }));
    } catch {
      AlertaToast({
        action: "Error en el servidor",
        fullText: true,
        type: "error",
      }).fire();
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhoneNumber = (v) => {
    setValues(
      {
        ...values,
        telefono: v,
        isChanged: isUnsavedChanges({ ...values, telefono: v }),
      },
      true
    );
  };

  function isUnsavedChanges(values) {
    const errorDireccion = !values.direccion.trim();
    const errorTelefono = Boolean(errors.telefono);

    const hayErroresDeEntradas = errorDireccion || errorTelefono;

    if (hayErroresDeEntradas) return false;
    const direccionChanged = values.direccion !== cliente.direccion;
    const telefonoChanged = values.telefono !== cliente.telefono;
    return direccionChanged || telefonoChanged;
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <LoadingButton
            variant="contained"
            disabled={!values.isChanged || isLoading}
            loading={isLoading}
            type="submit"
          >
            Guardar
          </LoadingButton>
        </Box>

        <TextField
          required
          margin="dense"
          id="direccion"
          label="Dirección"
          fullWidth
          {...getFieldProps("direccion")}
          onChange={(e) =>
            setValues(
              {
                ...values,
                direccion: e.target.value,
                isChanged: isUnsavedChanges({
                  ...values,
                  direccion: e.target.value,
                }),
              },
              true
            )
          }
          error={Boolean(touched.direccion && errors.direccion)}
          helperText={touched.direccion && errors.direccion}
        />

        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={12} md={6}>
            <TextField
              required
              style={{ paddingRight: 10 }}
              margin="dense"
              id="correo"
              label="Correo"
              type="email"
              fullWidth
              {...getFieldProps("correo")}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6} align="end">
            <MuiTelInput
              required
              margin="dense"
              id="telefono"
              label="Teléfono"
              forceCallingCode
              defaultCountry="CL"
              preferredCountries={["CL"]}
              fullWidth
              onFocus={() => setTouched({ ...touched, telefono: true }, true)}
              value={getFieldProps("telefono").value}
              onChange={(e) => handlePhoneNumber(e)}
              onBlur={getFieldProps("telefono").onBlur}
              error={Boolean(touched.telefono && errors.telefono)}
              helperText={touched.telefono && errors.telefono}
            />
          </Grid>
        </Grid>

        {getFieldProps("urlCertificado").value && (
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              saveAs(
                getFieldProps("urlCertificado").value,
                `Certificado ${cliente.nombre}.pdf`
              );
            }}
          >
            Descargar Certificado
          </Button>
        )}
      </Form>
    </FormikProvider>
  );
};
