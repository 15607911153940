import { NAME_BUCKET, storage } from "./urlBack_End";

export default async function uploadToS3(base64String, nameFile) {
  // Convierte la cadena base64 a un array de bytes
  const byteCharacters = atob(base64String.split(";base64,").pop());
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blobData = new Blob(byteArrays, { type: "image/jpeg" });

  // Configuración para subir a S3
  const params = {
    Bucket: NAME_BUCKET,
    Key: nameFile,
    Body: blobData,
    ContentType: "image/jpeg",
  };

  try {
    // Subir a S3
    await storage.putObject(params).promise();
    return nameFile;
  } catch (error) {
    return error;
  }
}

// Luego, puedes llamar a la función uploadToS3() con tu cadena base64 como argumento
