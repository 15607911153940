import React, { useState } from "react";
import { Icon } from "@iconify/react";
import Dialog from "@mui/material/Dialog";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { LoadingButton } from "@mui/lab";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { useFormik, Form, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { useSelector } from "react-redux";
import { AlertaToast } from "../AlertaSwall";

YupPassword(Yup); // extend Yup
const PASSWD_ERR_MSG = "La contraseña debe tener al menos ";

export default function ConfigurarCuenta({ open, setOpen }) {
  const { user: usuarioObtenido } = useSelector((state) => state.auth.user);
  const [showPassword, setShowPassword] = useState(false);

  function isValidPasswordConfirmation(value) {
    const v = value || "";
    return getFieldProps("newPassword").value === v;
  }

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required("Se debe completar el nombre"),
    phoneNumber: Yup.string()
      .required("Se debe completar el teléfono")
      .test("Phone number test", "El teléfono no es válido", (value) =>
        matchIsValidTel(value)
      ),
    newPassword: Yup.string()
      .min(8, PASSWD_ERR_MSG + "8 caracteres")
      .minLowercase(1, PASSWD_ERR_MSG + "una minúscula")
      .minUppercase(1, PASSWD_ERR_MSG + "una mayúscula")
      .minNumbers(1, PASSWD_ERR_MSG + "un número"),
    passwordConfirmation: Yup.string().test(
      "passwordConfirmation test",
      "Las contraseñas no coinciden",
      (value) => isValidPasswordConfirmation(value)
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: usuarioObtenido?.nombre || "",
      phoneNumber: usuarioObtenido?.telefono || "",
      newPassword: "",
      passwordConfirmation: "",
      remember: true,
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: async (values, actions) => {
      const body = {
        nombre: values.name,
        telefono: values.phoneNumber.replaceAll(" ", ""),
      };
      try {
        AlertaToast({ action: "actualizado", element: "Usuario" }).fire();
      } catch {
        AlertaToast({
          action: "Error en el servidor",
          fullText: true,
          type: "error",
        }).fire();
      } finally {
        actions.setSubmitting(false);
        setOpen(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setTouched,
    setValues,
  } = formik;

  const handlePhoneNumber = (v) => {
    setFieldValue("phoneNumber", v);
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Configurar Cuenta</DialogTitle>
      <DialogContent>
        {usuarioObtenido ? (
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={2} mb={2}>
                <TextField
                  required
                  margin="dense"
                  id="name"
                  InputLabelProps={{ shrink: true }}
                  label="Nombre"
                  fullWidth
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />

                <MuiTelInput
                  required
                  margin="dense"
                  id="phoneNumber"
                  label="Teléfono"
                  forceCallingCode
                  defaultCountry="CL"
                  preferredCountries={["CL"]}
                  fullWidth
                  onFocus={() =>
                    setTouched({ ...touched, telefono: true }, true)
                  }
                  value={getFieldProps("phoneNumber").value}
                  onChange={(v) => handlePhoneNumber(v)}
                  onBlur={getFieldProps("phoneNumber").onBlur}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />

                <TextField
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  label="Nueva contraseña"
                  {...getFieldProps("newPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                          edge="end"
                        >
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  helperText={touched.newPassword && errors.newPassword}
                  onChange={(e) => {
                    setTouched({ ...touched, passwordConfirmation: false });
                    setValues({ ...values, newPassword: e.target.value });
                  }}
                  onBlur={() => {
                    if (values.passwordConfirmation) {
                      setTouched({
                        ...touched,
                        newPassword: true,
                        passwordConfirmation: true,
                      });
                    } else {
                      setTouched({ ...touched, newPassword: true });
                    }
                  }}
                />

                <TextField
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  label="Confirmar nueva contraseña"
                  {...getFieldProps("passwordConfirmation")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword((prev) => !prev)}
                          edge="end"
                        >
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(
                    errors.passwordConfirmation && touched.passwordConfirmation
                  )}
                  helperText={
                    touched.passwordConfirmation && errors.passwordConfirmation
                  }
                />
              </Stack>

              <DialogActions>
                <LoadingButton
                  color="secondary"
                  onClick={() => setOpen(false)}
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Cerrar
                </LoadingButton>

                <LoadingButton
                  loading={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Modificar
                </LoadingButton>
              </DialogActions>
            </Form>
          </FormikProvider>
        ) : (
          <Skeleton />
        )}
      </DialogContent>
    </Dialog>
  );
}
