import { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "src/utils/firebaseInit";
import { v4 as uuidv4 } from "uuid";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { ID_PADEL } from "src/utils/urlBack_End";

export default function DialogCrearImagenes(props) {
  const { open, setOpen } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [selectFile, setSelectFile] = useState(null);
  const [progresoCarga, setProgresoCarga] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const guardarDatos = () => {
    console.log("entra");
    setIsLoading(true);
    if (!selectFile) {
      AlertaToast({
        action: "No se ha seleccionado imagén",
        fullText: true,
        type: "error",
        target: document.getElementById("dialogCrearImagen"),
      }).fire();
    } else {
      AlertaModal({
        title: "agregar la imagen",
        confirmButtonText: "Sí, agregar",
        target: document.getElementById("dialogCrearImagen"),
      })
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            const storageRef = ref(storage, `imagenes/${uuidv4()}`);
            const uploadTask = uploadBytesResumable(storageRef, selectFile);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgresoCarga(progress);
                // eslint-disable-next-line default-case
                switch (snapshot.state) {
                  case "paused":
                    break;
                  case "running":
                    break;
                }
              },
              (error) => {
                // eslint-disable-next-line default-case
                switch (error.code) {
                  case "storage/unauthorized":
                    AlertaToast({
                      action: "Sin permisos",
                      fullText: true,
                      type: "error",
                      target: document.getElementById("dialogCrearImagen"),
                    }).fire();
                    break;
                  case "storage/canceled":
                    AlertaToast({
                      action: "Subida cancelada",
                      fullText: true,
                      type: "error",
                      target: document.getElementById("dialogCrearImagen"),
                    }).fire();
                    setIsLoading(false);
                    break;
                  case "storage/unknown":
                    AlertaToast({
                      action: "Error no determinado",
                      fullText: true,
                      type: "error",
                      target: document.getElementById("dialogCrearImagen"),
                    }).fire();
                    setIsLoading(false);
                    break;
                }
              },
              () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then(
                  async (downloadURL) => {
                    const newUser = {
                      fecha: Timestamp.fromDate(new Date()),
                      img: {
                        alt: selectFile.name,
                        src: downloadURL,
                      },
                    };

                    const data = await addDoc(
                      collection(db, `padel/${ID_PADEL}/imagenes`),
                      newUser
                    );

                    if (data) {
                      AlertaToast({
                        element: "imagen",
                        action: "creada",
                      }).fire();
                      setIsLoading(false);
                      handleClose();
                    } else {
                      AlertaToast({
                        action: "Error no determinado",
                        fullText: true,
                        type: "error",
                        target: document.getElementById("dialogCrearImagen"),
                      }).fire();
                      setIsLoading(false);
                    }
                  }
                );
              }
            );
          }
        });
    }
  };
  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogCrearImagen"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Nueva Imagen</DialogTitle>
          <DialogContent>
            <DialogContentText>Seleccione una iamgen</DialogContentText>
            <Button variant="contained" fullWidth component="label">
              {selectFile ? selectFile.name : "Seleccione imagen"}
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={(e) => {
                  const newFile = e.target.files[0];
                  if (newFile) {
                    if (String(newFile.type).includes("image/")) {
                      setSelectFile(newFile);
                    } else {
                      AlertaToast({
                        action: "Error al seleccionar el archivo",
                        fullText: true,
                        type: "error",
                        target: document.getElementById("dialogCrearImagen"),
                      }).fire();
                    }
                  }
                }}
              />
            </Button>

            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                disabled={isLoading}
              >
                Cerrar
              </Button>
              {isLoading ? (
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                  <CircularProgress variant="determinate" />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="text.secondary"
                    >
                      {`${Math.round(progresoCarga)}%`}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  color="primary"
                  onClick={guardarDatos}
                >
                  Agregar
                </LoadingButton>
              )}
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
