import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUsers,
  faTableTennis,
  faNewspaper,
  faDollar,
  faUsersLine,
  faTicket,
  faBuilding,
  faPhotoFilm,
} from "@fortawesome/free-solid-svg-icons";
import Permisos from "../../utils/Permisos";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <FontAwesomeIcon icon={name} width={22} height={22} />
);

const sidebarConfig = [
  {
    title: "inicio",
    path: "/dashboard/inicio",
    icon: getIcon(faHome),
    permisos: [Permisos.visualizarReportes],
  },
  {
    title: "reservas",
    path: "/dashboard/reservas",
    icon: getIcon(faTicket),
    permisos: [Permisos.visualizarReservas],
  },
  {
    title: "reservas all",
    path: "/dashboard/reservas-all",
    icon: getIcon(faTicket),
    permisos: [Permisos.visualizarReservas],
  },
  {
    title: "clientes",
    path: "/dashboard/clientes",
    icon: getIcon(faUsersLine),
    permisos: [Permisos.visualizarClientes],
  },
  {
    title: "convenios",
    path: "/dashboard/convenios",
    icon: getIcon(faBuilding),
    permisos: [Permisos.visualizarConvenios],
  },
  {
    title: "usuarios",
    path: "/dashboard/usuarios",
    icon: getIcon(faUsers),
    permisos: [Permisos.visualizarUsuarios],
  },
  {
    title: "productos",
    path: "/dashboard/productos",
    icon: getIcon(faUsers),
    permisos: [Permisos.visualizarProductos],
  },
  {
    title: "tarifas",
    path: "/dashboard/tarifas",
    icon: getIcon(faDollar),
    permisos: [Permisos.visualizarTarifas],
  },
  {
    title: "noticias",
    path: "/dashboard/noticias",
    icon: getIcon(faNewspaper),
    permisos: [Permisos.visualizarNoticias],
  },
  {
    title: "imágenes",
    path: "/dashboard/imagenes",
    icon: getIcon(faPhotoFilm),
    permisos: [Permisos.visualizarImagenes],
  },
  {
    title: "canchas",
    path: "/dashboard/canchas",
    icon: getIcon(faTableTennis),
    permisos: [Permisos.visualizarCanchas],
  },
  {
    title: "login",
    path: "/login",
    icon: getIcon(faUsers),
  },
];

const sidebarConfigUsuario = [
  {
    title: "inicio",
    path: "/dashboard/inicio",
    icon: getIcon(faTicket),
    permisos: "ALL",
  },
  {
    title: "mis reservas",
    path: "/dashboard/mis-reservas",
    icon: getIcon(faUsersLine),
    permisos: "ALL",
  },
  {
    title: "login",
    path: "/login",
    icon: getIcon(faUsers),
  },
];

export { sidebarConfig, sidebarConfigUsuario };
