import { Link as RouterLink, useNavigate } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

import { MotionContainer, varBounceIn } from "../components/animate";
import Page from "../components/Page";
import { useEffect, useState } from "react";
import {
  Stack,
  Box,
  Button,
  Container,
  Typography,
  Skeleton,
  Grid,
} from "@mui/material";
import { AlertaToast } from "src/components/AlertaSwall";
import {
  CheckCircleOutline as CheckIcon,
  CancelOutlined as CancelOutlinedIcon,
} from "@mui/icons-material";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import dayjs from "dayjs";
import { getnetApi } from "src/services/getnetApi";

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function PagoReserva() {
  const { id: uuidUsuarioCreador } = useSelector((state) => state.auth?.user);

  const [mensajeError, setMensajeError] = useState("");
  const [mensajeOK, setMensajeOK] = useState(null);

  const navigate = useNavigate();

  const cookies = new Cookies();

  const uuidCancha = cookies.get("uuidCancha");
  const nombreCancha = cookies.get("nombreCancha");
  const fechaReserva = cookies.get("fechaReserva");
  const horaReserva = cookies.get("horaReserva");
  const participantes = cookies.get("participantes");
  const requestId = cookies.get("requestId");
  const numeroTicket = cookies.get("numeroTicket");
  const duracion = cookies.get("duracion");

  const [cargandoData, setCargandoData] = useState(false);

  const [statusGetnet] = getnetApi.useStatusMutation();

  useEffect(() => {
    setCargandoData(true);

    if (!uuidCancha || !fechaReserva || !horaReserva || !requestId) {
      setCargandoData(false);
      setMensajeError("Se han eliminado las cookies.");
    } else {
      statusGetnet(requestId)
        .unwrap()
        .then(async (response2) => {
          if (response2.status.reason === "00") {
            const horasReservadas = [horaReserva];
            let i = 0;

            const request = response2.request;

            const payment = request.payment;

            let horaReservadaAux = horaReserva;

            while (i < 3) {
              horaReservadaAux = dayjs(
                `${new Date().getFullYear()}-${
                  new Date().getMonth() + 1
                }-${new Date().getDate()} ${horaReservadaAux}`
              )
                .add(30, "minutes")
                .format("HH:mm");
              horasReservadas.push(horaReservadaAux);
              i = i + 1;
            }

            let exist = null;

            const q = query(
              collection(db, `canchas/${uuidCancha}/reservas`),
              where("fecha", "==", fechaReserva),
              where("requestId", "==", requestId)
            );

            try {
              const querySnapshot = await getDocs(q);

              querySnapshot.forEach((doc) => {
                const dataAux = doc.data();
                exist = {
                  id: doc.id,
                  ...dataAux,
                };
              });
            } catch (error) {}

            if (exist) {
              cookies.remove("uuidCancha");
              cookies.remove("fechaReserva");
              cookies.remove("horaReserva");
              cookies.remove("participantes");
              cookies.remove("requestId");
              cookies.remove("numeroTicket");
              cookies.remove("duracion");
              navigate("/");
            } else {
              try {
                await addDoc(collection(db, `canchas/${uuidCancha}/reservas`), {
                  estadoActual: "PAGADO",
                  estados: ["CREADO", "PAGADO"],
                  fecha: fechaReserva,
                  horas: horasReservadas,
                  tipoMoneda: "Pesos",
                  tipoPago: "GETNET",
                  valor: Number(payment.amount.total),
                  uuidUsuarioCreador: uuidUsuarioCreador,
                  uuidUsuarioReserva: uuidUsuarioCreador,
                  numeroTicket: Number(numeroTicket),
                  requestId,
                  webpay: response2,
                  participantes: participantes,
                  uuidCancha: uuidCancha,
                  nombreCancha,
                  duracion: Number(duracion),
                });
                cookies.remove("uuidCancha");
                cookies.remove("fechaReserva");
                cookies.remove("horaReserva");
                cookies.remove("participantes");
                cookies.remove("requestId");
                cookies.remove("numeroTicket");
                cookies.remove("duracion");
              } catch (error) {
                AlertaToast({
                  action:
                    "Error al reservar la cancha. Comuniquese con nuestro equipo.",
                  fullText: true,
                  type: "error",
                }).fire();
              }
            }

            setMensajeOK(response2.payment[0]);
          } else {
            setMensajeError(response2);
          }
          setCargandoData(false);
        })
        .catch(() => {
          setCargandoData(false);
          AlertaToast({
            action: "Error al validar el estado.",
            fullText: true,
            type: "error",
          }).fire();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle title="PagoReserva">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <motion.div variants={varBounceIn}>
              {mensajeError !== "" ? (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  marginBottom={5}
                >
                  <CancelOutlinedIcon color="error" fontSize="large" />

                  {mensajeError?.status ? (
                    <Stack>
                      <Grid container>
                        <Grid item xs={5} md={5}>
                          <Stack alignItems="center" justifyContent="center">
                            <Typography variant="h6" color="primary">
                              Estado de la transacción
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={2} md={2}></Grid>
                        <Grid item xs={5} md={5}>
                          <Stack spacing={2}>
                            <Typography variant="body1" color="primary">
                              {mensajeError?.status.message}
                            </Typography>
                            <Typography variant="body1" color="primary">
                              {dayjs(mensajeError?.status.date).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  ) : (
                    <Typography variant="h6" color="primary">
                      {mensajeError}
                    </Typography>
                  )}
                </Stack>
              ) : cargandoData ? (
                <Skeleton width="100%" height={500} />
              ) : mensajeOK ? (
                <Stack spacing={2}>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    marginBottom={5}
                  >
                    <CheckIcon color="success" fontSize="large" />
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} md={5}>
                      <Stack alignItems="center" justifyContent="center">
                        <Typography variant="h6" color="primary">
                          Estado de la transacción
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={2} md={2}></Grid>
                    <Grid item xs={5} md={5}>
                      <Stack spacing={2}>
                        <Typography variant="body1" color="primary">
                          {mensajeOK?.status.message}
                        </Typography>
                        <Typography variant="body1" color="primary">
                          {dayjs(mensajeOK?.status.date).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5} md={5}>
                      <Stack alignItems="center" justifyContent="center">
                        <Typography variant="h6" color="primary">
                          Código de autorización
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={2} md={2}></Grid>
                    <Grid item xs={5} md={5}>
                      <Typography variant="body1" color="primary">
                        {mensajeOK?.authorization}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              ) : mensajeError ? (
                <Stack spacing={2}>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    marginBottom={5}
                  >
                    <CancelOutlinedIcon color="error" fontSize="large" />
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} md={5}>
                      <Stack alignItems="center" justifyContent="center">
                        <Typography variant="h6" color="primary">
                          Estado de la transacción
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={2} md={2}></Grid>
                    <Grid item xs={5} md={5}>
                      <Stack spacing={2}>
                        <Typography variant="body1" color="primary">
                          {mensajeError?.status.message}
                        </Typography>
                        <Typography variant="body1" color="primary">
                          {dayjs(mensajeError?.status.date).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              ) : (
                <Skeleton />
              )}
            </motion.div>

            <Button
              style={{ marginTop: 50 }}
              to="/dashboard/inicio"
              size="large"
              variant="contained"
              component={RouterLink}
            >
              Ir a reservar
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
