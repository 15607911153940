import { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { LoadingButton } from "@mui/lab";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { validateRut, deconstructRut } from "@fdograph/rut-utilities";
import { usuariosApi } from "src/services/usuariosApi";
import { AlertaModal, AlertaToast } from "../../AlertaSwall";
import { Box } from "@mui/material";
import { DropZone } from "src/components/Dropzone";
import { readFile } from "src/utils/excelHelpers";
import { db } from "src/utils/firebaseInit";
import { collection, getDocs, query, where } from "firebase/firestore";

const FORMAT_ERR_MSG =
  "Se produjo un error. El formato del documento debe ser el que se encuentra en la plantilla.";
const FAILED_ERR_MSG =
  "No se encontró información para leer o el archivo está vacío.";
const MSG = "Arrastra tu archivo o búscalo en tu computador.";
const ACCEPTED_FORMATS =
  ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";

export default function DialogCrearUsuarioConvenio(props) {
  const { open, setOpen } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [desactivar, setDesactivar] = useState(false);
  const [idEmpresaConvenio, setIdEmpresaConvenio] = useState("");
  const [dataDropzone, setDropzone] = useState({
    quantityDataDropzoneWithErrors: 0,
    quantityDataDropzoneWithoutErrors: 0,
    totalPriceListss: 0,
    dataDropzoneWithoutErrors: [],
    enableSave: false,
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: [],
  });
  const [crearUsuario] = usuariosApi.useAddConvenioMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    rut: Yup.string().test("rut test", "Rut no válido", (value) =>
      validateRut(value)
    ),
    nombre: Yup.string().required("Contraseña requerida"),
    direccion: Yup.string().required("Dirección requerida"),
    correo: Yup.string().email("Correo no válido").required("Correo requerido"),
    telefono: Yup.string().test(
      "telefono test",
      "Teléfono no válido",
      (value) => matchIsValidTel(value)
    ),
  });

  const formik = useFormik({
    initialValues: {
      rut: "",
      nombre: "",
      correo: "",
      telefono: "+56",
      direccion: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setTouched,
    setFieldValue,
  } = formik;

  const guardarDatos = () => {
    setIsLoading(true);
    const newUser = {
      rut:
        deconstructRut(getFieldProps("rut").value.toUpperCase()).digits +
        deconstructRut(getFieldProps("rut").value.toUpperCase()).verifier,
      displayName: getFieldProps("nombre").value,
      email: getFieldProps("correo").value,
      phoneNumber: getFieldProps("telefono").value,
      direccion: getFieldProps("direccion").value,
    };
    AlertaModal({
      title: "agregar al usuario",
      confirmButtonText: "Sí, agregar",
      target: document.getElementById("dialogCrearUsuario"),
    })
      .fire()
      .then((result) => {
        if (result.isConfirmed) {
          crearUsuario({
            ...newUser,
            users: dataDropzone.data,
            uuidEmpresa: idEmpresaConvenio,
          })
            .unwrap()
            .then(() => {
              AlertaToast({
                element: "usuario",
                action: "creado",
              }).fire();
              setIsLoading(false);
              handleClose();
            })
            .catch(() => {
              AlertaToast({
                action: "Error en el servidor o el usuario ya existe",
                fullText: true,
                type: "error",
                target: document.getElementById("dialogCrearUsuario"),
              }).fire();
              setIsLoading(false);
            });
        }
      })
      .finally(() => {
        setIsLoading(true);
      });
  };

  const handlePhoneNumber = (v) => {
    setFieldValue("telefono", v);
  };

  const onDrop = (acceptedFiles) => {
    const reader = new FileReader();
    reader.onloadstart = (e) => {
      setDropzone((prevState) => ({
        ...prevState,
        isSuccess: false,
        isLoading: true,
      }));
    };
    reader.onload = async (e) => {
      const content = readFile(e.target.result, [
        "Rut",
        "Nombre",
        "Dirección",
        "Telefono",
        "Correo",
      ]);

      const dataError = [];
      const formatedFileData = [];

      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;

      // eslint-disable-next-line array-callback-return
      content.map((data) => {
        if (validateRut(data["Rut"]) && emailRegex.test(data["Correo"])) {
          formatedFileData.push({
            rut: data["Rut"],
            nombre: data["Nombre"],
            direccion: data["Dirección"],
            telefono: data["Telefono"],
            correo: data["Correo"],
          });
        } else {
          dataError.push({
            rut: data["Rut"],
            nombre: data["Nombre"],
            direccion: data["Dirección"],
            telefono: data["Telefono"],
            correo: data["Correo"],
          });
        }
      });

      if (formatedFileData.length >= 1) {
        setDropzone((prevState) => ({
          ...prevState,
          data: formatedFileData,
          isLoading: false,
          isSuccess: true,
          quantityPriceListsWithErrors: formatedFileData.length,
        }));
      } else {
        setDropzone((prevState) => ({
          ...prevState,
          data: formatedFileData,
          dataDropzoneWithoutErrors: dataError,
          isLoading: false,
          isError: true,
        }));
      }
    };
    reader.onloadend = (e) => {
      setDropzone((prevState) => ({ ...prevState, isLoading: false }));
    };
    reader.readAsArrayBuffer(acceptedFiles);
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogCrearUsuario"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Creación Usuarios con Convenio
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Complete la información básica de la empresa con convenio
            </DialogContentText>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  required
                  margin="dense"
                  id="rut"
                  label="Rut Empresa Convenio"
                  fullWidth
                  {...getFieldProps("rut")}
                  error={Boolean(touched.rut && errors.rut)}
                  helperText={touched.rut && errors.rut}
                  onBlur={async (e) => {
                    const q = query(
                      collection(db, "empresasConvenio"),
                      where("rut", "==", e.target.value)
                    );

                    const querySnapshot = await getDocs(q);

                    if (querySnapshot.empty) {
                      setDesactivar(false);
                      setFieldValue("telefono", "+56");
                      setFieldValue("correo", "");
                      setFieldValue("direccion", "");
                      setFieldValue("nombre", "");
                      setIdEmpresaConvenio("");
                    } else {
                      let elementData = null;
                      querySnapshot.forEach((doc) => {
                        elementData = doc.data();
                        setIdEmpresaConvenio(doc.id);
                      });

                      if (elementData) {
                        setFieldValue("telefono", String(elementData.telefono));
                        setFieldValue("correo", String(elementData.correo));
                        setFieldValue(
                          "direccion",
                          String(elementData.direccion)
                        );
                        setFieldValue("nombre", String(elementData.nombre));
                        setDesactivar(true);
                      } else {
                        setDesactivar(false);
                        setFieldValue("telefono", "+56");
                        setFieldValue("correo", "");
                        setFieldValue("direccion", "");
                        setFieldValue("nombre", "");
                        setIdEmpresaConvenio("");
                      }
                    }
                  }}
                />
                <TextField
                  disabled={desactivar}
                  required
                  margin="dense"
                  id="nombre"
                  label="Nombre Empresa Convenio"
                  fullWidth
                  {...getFieldProps("nombre")}
                  error={Boolean(touched.nombre && errors.nombre)}
                  helperText={touched.nombre && errors.nombre}
                />
                <TextField
                  disabled={desactivar}
                  required
                  style={{ paddingRight: 10 }}
                  margin="dense"
                  id="direccion"
                  label="Dirección Empresa Convenio"
                  fullWidth
                  {...getFieldProps("direccion")}
                  error={Boolean(touched.direccion && errors.direccion)}
                  helperText={touched.direccion && errors.direccion}
                />
                <TextField
                  required
                  disabled={desactivar}
                  style={{ paddingRight: 10 }}
                  margin="dense"
                  id="correo"
                  label="Correo Empresa Convenio"
                  type="email"
                  fullWidth
                  {...getFieldProps("correo")}
                  error={Boolean(touched.correo && errors.correo)}
                  helperText={touched.correo && errors.correo}
                />
                <MuiTelInput
                  disabled={desactivar}
                  required
                  margin="dense"
                  id="telefono"
                  label="Teléfono Empresa Convenio"
                  forceCallingCode
                  defaultCountry="CL"
                  preferredCountries={["CL"]}
                  fullWidth
                  onFocus={() =>
                    setTouched({ ...touched, telefono: true }, true)
                  }
                  value={getFieldProps("telefono").value}
                  onChange={(e) => handlePhoneNumber(e)}
                  onBlur={getFieldProps("telefono").onBlur}
                  error={Boolean(touched.telefono && errors.telefono)}
                  helperText={touched.telefono && errors.telefono}
                />

                <Button
                  variant="contained"
                  href="/static/plantillas/Convenios.xlsx"
                >
                  Descargar Plantilla Usuario Convenios
                </Button>

                <Box
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  minWidth="90%"
                >
                  <DropZone
                    handleSubmit={onDrop}
                    messageDefault={MSG}
                    messageFormatError={FORMAT_ERR_MSG}
                    messageError={FAILED_ERR_MSG}
                    messageSuccess={<>{"El archivo se cargó con éxito"}</>}
                    acceptedFileFormats={ACCEPTED_FORMATS}
                    isLoadingFile={dataDropzone.isLoading}
                    isUploadSuccess={dataDropzone.isSuccess}
                    isUploadFailed={dataDropzone.isError}
                  />
                </Box>

                <DialogActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                    disabled={isLoading}
                  >
                    Cerrar
                  </Button>
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    Agregar
                  </LoadingButton>
                </DialogActions>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
