import { Link as RouterLink, useNavigate } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import Cookies from "universal-cookie";

import { MotionContainer, varBounceIn } from "../components/animate";
import Page from "../components/Page";
import { useEffect, useState } from "react";
import {
  Stack,
  Box,
  Button,
  Container,
  Typography,
  Skeleton,
  Grid,
} from "@mui/material";
import { AlertaToast } from "src/components/AlertaSwall";
import {
  CheckCircleOutline as CheckIcon,
  CancelOutlined as CancelOutlinedIcon,
} from "@mui/icons-material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import dayjs from "dayjs";
import { getnetApi } from "src/services/getnetApi";

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function PagoMembresia() {
  const [mensajeError, setMensajeError] = useState("");
  const [mensajeOK, setMensajeOK] = useState(null);

  const navigate = useNavigate();

  const cookies = new Cookies();

  const uuiPago = cookies.get("uuiPago");
  const uuidUser = cookies.get("uuidUser");
  const requestId = cookies.get("requestId");

  const [cargandoData, setCargandoData] = useState(false);

  const [statusGetnet] = getnetApi.useStatusMembresiaMutation();

  useEffect(() => {
    setCargandoData(true);

    if (!uuiPago || !uuidUser || !requestId) {
      setCargandoData(false);
      setMensajeError("Se han eliminado las cookies.");
    } else {
      statusGetnet({ requestId, uuiPago, uuidUser })
        .unwrap()
        .then(async (response2) => {
          if (response2.status.reason === "00") {
            let exist = null;

            const pagoRef = doc(db, `usuarios/${uuidUser}/pagos`, uuiPago);
            const docPago = await getDoc(pagoRef);

            if (docPago.exists()) {
              exist = docPago.data().estado;
            } else {
              // docSnap.data() will be undefined in this case
              console.log("No such document!");
            }

            if (exist === "PAGADO") {
              cookies.remove("uuiPago");
              cookies.remove("uuidUser");
              cookies.remove("requestId");
              navigate("/");
            } else if (exist === "ANULADO") {
              cookies.remove("uuiPago");
              cookies.remove("uuidUser");
              cookies.remove("requestId");
              navigate("/");
            } else {
              try {
                await updateDoc(pagoRef, {
                  estado: "PAGADO",
                  pagado: true,
                });
                cookies.remove("uuiPago");
                cookies.remove("uuidUser");
                cookies.remove("requestId");
              } catch (error) {
                AlertaToast({
                  action:
                    "Error al reservar la cancha. Comuniquese con nuestro equipo.",
                  fullText: true,
                  type: "error",
                }).fire();
              }
            }

            setMensajeOK(response2.payment[0]);
          } else {
            setMensajeError(response2);
          }
          setCargandoData(false);
        })
        .catch(() => {
          setCargandoData(false);
          AlertaToast({
            action: "Error al validar el estado.",
            fullText: true,
            type: "error",
          }).fire();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle title="PagoReserva">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <motion.div variants={varBounceIn}>
              {mensajeError !== "" ? (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  marginBottom={5}
                >
                  <CancelOutlinedIcon color="error" fontSize="large" />

                  {mensajeError?.status ? (
                    <Stack>
                      <Grid container>
                        <Grid item xs={5} md={5}>
                          <Stack alignItems="center" justifyContent="center">
                            <Typography variant="h6" color="primary">
                              Estado de la transacción
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={2} md={2}></Grid>
                        <Grid item xs={5} md={5}>
                          <Stack spacing={2}>
                            <Typography variant="body1" color="primary">
                              {mensajeError?.status.message}
                            </Typography>
                            <Typography variant="body1" color="primary">
                              {dayjs(mensajeError?.status.date).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  ) : (
                    <Typography variant="h6" color="primary">
                      {mensajeError}
                    </Typography>
                  )}
                </Stack>
              ) : cargandoData ? (
                <Skeleton width="100%" height={500} />
              ) : mensajeOK ? (
                <Stack spacing={2}>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    marginBottom={5}
                  >
                    <CheckIcon color="success" fontSize="large" />
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} md={5}>
                      <Stack alignItems="center" justifyContent="center">
                        <Typography variant="h6" color="primary">
                          Estado de la transacción
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={2} md={2}></Grid>
                    <Grid item xs={5} md={5}>
                      <Stack spacing={2}>
                        <Typography variant="body1" color="primary">
                          {mensajeOK?.status.message}
                        </Typography>
                        <Typography variant="body1" color="primary">
                          {dayjs(mensajeOK?.status.date).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5} md={5}>
                      <Stack alignItems="center" justifyContent="center">
                        <Typography variant="h6" color="primary">
                          Código de autorización
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={2} md={2}></Grid>
                    <Grid item xs={5} md={5}>
                      <Typography variant="body1" color="primary">
                        {mensajeOK?.authorization}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              ) : mensajeError ? (
                <Stack spacing={2}>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    marginBottom={5}
                  >
                    <CancelOutlinedIcon color="error" fontSize="large" />
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} md={5}>
                      <Stack alignItems="center" justifyContent="center">
                        <Typography variant="h6" color="primary">
                          Estado de la transacción
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={2} md={2}></Grid>
                    <Grid item xs={5} md={5}>
                      <Stack spacing={2}>
                        <Typography variant="body1" color="primary">
                          {mensajeError?.status.message}
                        </Typography>
                        <Typography variant="body1" color="primary">
                          {dayjs(mensajeError?.status.date).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              ) : (
                <Skeleton />
              )}
            </motion.div>

            <Button
              style={{ marginTop: 50 }}
              to="/pagos"
              size="large"
              variant="contained"
              component={RouterLink}
            >
              OK
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
