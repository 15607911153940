import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { LoadingButton } from "@mui/lab";
import { AlertaModal, AlertaToast } from "../../AlertaSwall";
import { addDoc, collection } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

export default function DialogCrearProducto(props) {
  const { open, setOpen, newID } = props;

  const [isLoading, setIsLoading] = useState(false);
  const { id: uuidUsuarioCreador, user: usuarioObtenido } = useSelector(
    (state) => state.auth.user
  );
  const handleClose = () => {
    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    nombre: Yup.string().required("Nombre requerido"),
    valor: Yup.string().required("Valor requerido"),
    cantidadActual: Yup.string().required("Cantidad actual requerida"),
  });

  const formik = useFormik({
    initialValues: {
      idProducto: newID,
      nombre: "",
      valor: "",
      cantidadActual: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      guardarDatos();
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const guardarDatos = () => {
    setIsLoading(true);

    const newUser = {
      idProducto: Number(newID),
      nombre: getFieldProps("nombre").value,
      valor: Number(getFieldProps("valor").value),
      cantidad: Number(getFieldProps("cantidadActual").value),
    };

    AlertaModal({
      title: "agregar el producto",
      confirmButtonText: "Sí, agregar",
      target: document.getElementById("dialogCrearProducto"),
    })
      .fire()
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const data = await addDoc(collection(db, `productos`), newUser);
            await addDoc(
              collection(db, `productos/${data.id}/historialCompras`),
              {
                fecha: dayjs().format("YYYY-MM-DD"),
                nombreUsuario: usuarioObtenido.nombre,
                uuidUsuario: uuidUsuarioCreador,
                cantidadAnterior: 0,
                nuevaCantidad: newUser.cantidad,
              }
            );
            AlertaToast({
              element: "producto",
              action: "creado",
            }).fire();
            setIsLoading(false);
            handleClose();
          } catch (error) {
            AlertaToast({
              action: "Error al crear el producto",
              fullText: true,
              type: "error",
              target: document.getElementById("dialogCrearProducto"),
            }).fire();
            setIsLoading(false);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          id="dialogCrearProducto"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Creación de Producto</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Complete la información del producto
            </DialogContentText>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  margin="dense"
                  id="idProducto"
                  label="ID Producto"
                  fullWidth
                  {...getFieldProps("idProducto")}
                  disabled
                />
                <TextField
                  required
                  margin="dense"
                  id="nombre"
                  label="Nombre"
                  fullWidth
                  {...getFieldProps("nombre")}
                  error={Boolean(touched.nombre && errors.nombre)}
                  helperText={touched.nombre && errors.nombre}
                />
                <TextField
                  required
                  style={{ paddingRight: 10 }}
                  margin="dense"
                  id="valor"
                  label="Valor"
                  type="number"
                  fullWidth
                  {...getFieldProps("valor")}
                  error={Boolean(touched.valor && errors.valor)}
                  helperText={touched.valor && errors.valor}
                />
                <TextField
                  required
                  style={{ paddingRight: 10 }}
                  margin="dense"
                  id="cantidadActual"
                  label="Cantidad actual de productos"
                  type="number"
                  fullWidth
                  {...getFieldProps("cantidadActual")}
                  error={Boolean(
                    touched.cantidadActual && errors.cantidadActual
                  )}
                  helperText={touched.cantidadActual && errors.cantidadActual}
                />

                <DialogActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                    disabled={isLoading}
                  >
                    Cerrar
                  </Button>
                  <LoadingButton
                    loading={false}
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    Agregar
                  </LoadingButton>
                </DialogActions>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
