import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { ID_PADEL } from "src/utils/urlBack_End";

const baseQuery = (baseUrl = "/") =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      if (ID_PADEL || token) {
        headers.set("authorization", `Bearer ${ID_PADEL}`);
      }

      return headers;
    },
  });

export default baseQuery;
