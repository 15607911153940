import PropTypes from "prop-types";
import { connect } from "react-redux";

const ShowForPermissionComponent = ({
  userPermissions,
  permission,
  children,
}) => {
  const couldShow = userPermissions.some((item) => {
    return (
      item.replace(/ /g, "").toLowerCase() ===
        permission.replace(/ /g, "").toLowerCase() && item
    );
  });
  return couldShow ? children : null;
};

ShowForPermissionComponent.propTypes = {
  permission: PropTypes.string.isRequired,
  userPermissions: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  userPermissions: state.auth.user?.permisos || [],
});

export const ValidarPermisos = connect(mapStateToProps)(
  ShowForPermissionComponent
);
