import { useEffect, useState } from "react";
import {
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Card,
  Typography,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "src/utils/firebaseInit";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { ID_EMPRESA } from "src/utils/urlBack_End";
import { AlertaToast } from "src/components/AlertaSwall";
import uploadToS3 from "src/utils/uploadBase64S3";
import { reconocimientoApi } from "src/services/reconocimientoApi";
import DialogTomarFoto from "src/components/gestionUsuarios/dialog/DialogTomarFoto";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";

export default function DialogVerReserva(props) {
  const { open, setOpen, reserva, canchaName, cancha, setReserva } = props;

  const [usuario, setUsuario] = useState(null);
  const [isLoadingDatoReserva, setIsLoadingDatoReserva] = useState(false);
  const [datoEmpresa, setDatoEmpresa] = useState(null);

  const [tomarFotoFoto, setTomarFotoFoto] = useState(false);
  const [imagenFoto, setImagenFoto] = useState(null);

  const [validarFotos] = reconocimientoApi.useCreateMutation();

  useEffect(() => {
    const docRef = doc(db, `/canchas/${cancha.id}/reservas/${reserva.id}`);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        setReserva({ id: docSnap.id, ...data });
      }
    });
  }, []);

  useEffect(() => {
    setIsLoadingDatoReserva(true);
    const docRef = doc(db, `usuarios/${reserva.uuidUsuarioReserva}`);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data) {
          if (Array.isArray(data.empresas) && data.empresas.length) {
            const datosEmpresasAux = data.empresas;
            let existEmpresaAux = datosEmpresasAux.filter(
              (e) => e.tipoUsuario === "Soci@" && e.uuidEmpresa === ID_EMPRESA
            )[0];

            if (existEmpresaAux) {
              setDatoEmpresa(data.empresas);
            }
          }
          setUsuario(data);
        }
      }
      setIsLoadingDatoReserva(false);
    });
  }, [reserva]);

  const color = "primary";

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      id="dialogLeerQR"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Reserva</DialogTitle>
      {!isLoadingDatoReserva && (
        <Stack>
          <Card
            sx={{
              border: 1,
              borderColor: (theme) => theme.palette[color].dark,
              padding: 5,
              boxShadow: 0,
              textAlign: "center",
              color: (theme) => theme.palette[color].dark,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={5} md={5}>
                <Stack>
                  <Typography variant="h6">Número Ticket</Typography>
                  <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    {reserva.numeroTicket}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={5} md={5}>
                <Stack>
                  <Typography variant="h6">Fecha</Typography>
                  <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    {dayjs(reserva.fecha).format("DD/MM/YYYY")}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={5} md={5}>
                <Stack>
                  <Typography variant="h6">Cancha</Typography>
                  <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    {canchaName}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={5} md={5}>
                <Stack>
                  <Typography variant="h6">Tipo Pago</Typography>
                  <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    {reserva.tipoPago}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Divider style={{ padding: 5 }} />

            {reserva && reserva.participantes && (
              <Stack spacing={2}>
                <Stack alignItems="center" justifyContent="center">
                  <Typography variant="h6" color="primary">
                    Participantes
                  </Typography>
                </Stack>

                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {reserva.participantes.map((element, idx) => (
                    <ListItem key={idx} disablePadding>
                      <ListItemIcon>
                        <AccountCircleIcon />
                      </ListItemIcon>
                      <ListItemText primary={element.nombre} />
                    </ListItem>
                  ))}
                </List>
              </Stack>
            )}

            <Divider style={{ padding: 5 }} />

            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12} md={12}>
                <Stack>
                  <Typography variant="h5">Tarjeta de Ingreso</Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={5} md={5}>
                <Stack>
                  <Typography variant="h6">Hora</Typography>
                  <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    {reserva.horas.toString()}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={5} md={5}></Grid>
            </Grid>

            {usuario && (
              <Grid container marginTop={2}>
                <Grid item xs={5} md={5}>
                  <Stack>
                    <Typography variant="h6">Usuario</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      {usuario.nombre}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={5} md={5}></Grid>
              </Grid>
            )}
          </Card>
        </Stack>
      )}
      <DialogActions>
        {reserva.estadoActual === "FINALIZADO" ? (
          datoEmpresa !== null ? (
            <>
              {imagenFoto === null ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setTomarFotoFoto(true);
                  }}
                >
                  Tomar Foto Cara
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    if (imagenFoto === null) {
                      AlertaToast({
                        action: "Ingresar Foto",
                        fullText: true,
                        type: "error",
                        target: document.getElementById("dialogLeerQR"),
                      }).fire();
                      return;
                    }
                    const nameObject = `${uuidv4()}.jpeg`;

                    const url = `${usuario.rut}/CARA/${nameObject}`;

                    const dataGet = await uploadToS3(imagenFoto, url);

                    if (dataGet === url) {
                      validarFotos({
                        name_comparacion: nameObject,
                        rut_user: usuario.rut,
                      })
                        .unwrap()
                        .then(async (res) => {
                          if (res && res.porcentaje && res.porcentaje >= 90) {
                            try {
                              await updateDoc(
                                doc(
                                  db,
                                  `canchas/${cancha.id}/reservas`,
                                  reserva.id
                                ),
                                {
                                  estadoActual: "INGRESADO",
                                  estados: arrayUnion("INGRESADO"),
                                }
                              );
                              setImagenFoto(null);
                              setOpen(false);
                              AlertaToast({
                                element: "socio",
                                action: "ingresado",
                              }).fire();
                            } catch (error) {
                              setImagenFoto(null);
                              AlertaToast({
                                action: "Error en el servidor",
                                fullText: true,
                                type: "error",
                                target: document.getElementById("dialogLeerQR"),
                              }).fire();
                            }
                          } else {
                            setImagenFoto(null);
                            AlertaToast({
                              action: "Error al validar la información",
                              fullText: true,
                              type: "error",
                              target: document.getElementById("dialogLeerQR"),
                            }).fire();
                          }
                        })
                        .catch(() => {
                          AlertaToast({
                            action: "Error al validar la información.",
                            fullText: true,
                            type: "error",
                            target: document.getElementById("dialogLeerQR"),
                          }).fire();
                        });
                    } else {
                      AlertaToast({
                        action: "Error al subir la imagen.",
                        fullText: true,
                        type: "error",
                        target: document.getElementById("dialogLeerQR"),
                      }).fire();
                    }
                  }}
                >
                  Validar Foto
                </Button>
              )}
            </>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpen(false)}
        >
          Cerrar
        </Button>
      </DialogActions>

      {tomarFotoFoto && (
        <DialogTomarFoto
          open={tomarFotoFoto}
          setOpen={setTomarFotoFoto}
          setData={setImagenFoto}
          data={imagenFoto}
          titulo="Foto Cara"
        />
      )}
    </Dialog>
  );
}
