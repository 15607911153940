import React, { useState, useEffect, useRef } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <React.Fragment>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    </React.Fragment>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: 200,
    textAlign: "left",
    marginTop: 9,
  },
  tab: {
    minWidth: 200,
    whiteSpace: "pre-wrap",
    textAlign: "left",
    wrapper: {
      alignItems: "flex-start",
    },
  },
}));

export default function TabsComponente(props) {
  const { encabezados, tabs, orientacion } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const prevLen = useRef(encabezados.length);

  // useEffect(() => {
  //   const newValue = Number(localStorage.getItem(id));
  //   setValue(newValue);
  // }, [id, localStorage.getItem(id)]);

  useEffect(() => {
    const len = prevLen.current;
    prevLen.current = encabezados.length;
    if (prevLen.current === len) return;
    setValue(encabezados.length - 1);
  }, [encabezados, tabs]);

  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };

  if (orientacion === "vertical") {
    return (
      <Box sx={{ width: "100%", marginTop: "10px" }}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: "100vh",
          }}>
          <Tabs
            orientation={orientacion}
            variant="scrollable"
            scrollButtons="auto"
            value={
              value < encabezados.length - 1 ? value : encabezados.length - 1
            }
            onChange={handleChange}
            aria-label="basic tabs example"
            className={classes.tabsContainer}>
            {encabezados &&
              Array.isArray(encabezados) &&
              encabezados.map((encabezado, idx) => {
                return (
                  <Tab
                    sx={{ alignItems: "start" }}
                    label={encabezado}
                    key={"tab_" + idx}
                    {...a11yProps(idx)}
                    className={classes.tab}
                  />
                );
              })}
          </Tabs>
          {tabs &&
            Array.isArray(tabs) &&
            tabs.map((tab, idx) => {
              return (
                <TabPanel value={value} index={idx} key={idx}>
                  {tab}
                </TabPanel>
              );
            })}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={{ width: "100%", marginTop: "10px" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            orientation={orientacion}
            value={
              value < encabezados.length - 1 ? value : encabezados.length - 1
            }
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto">
            {encabezados &&
              Array.isArray(encabezados) &&
              encabezados.map((encabezado, idx) => {
                return (
                  <Tab
                    label={encabezado}
                    key={"tab_" + idx}
                    {...a11yProps(idx)}
                  />
                );
              })}
          </Tabs>
        </Box>
        {tabs &&
          Array.isArray(tabs) &&
          tabs.map((tab, idx) => {
            return (
              <TabPanel value={value} index={idx} key={idx}>
                {tab}
              </TabPanel>
            );
          })}
      </Box>
    );
  }
}
