import Swal from "sweetalert2";

export const AlertaModal = ({
  title,
  confirmButtonText,
  confirmButtonColor = "#FF802E",
  cancelButtonColor = "#FF4842",
  cancelButtonText = "Cancelar",
  hasFooter = false,
  fullText = false,
  width = 474,
  target = document.getElementById("root"),
}) =>
  Swal.mixin({
    target: target,
    html: `<p style="padding: 50px 0 35px 0 !important;"> ${
      fullText ? title : `¿Está seguro que desea <b>${title}</b>?`
    }</p>`,
    footer: hasFooter
      ? '<div class="w-full flex items-center justify-center text-center bg-neutral-10 font-medium text-xs text-neutral-70"> ⚠️ Recuerda que esta acción es irreversible</div>'
      : "",
    width,
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor,
    cancelButtonColor,
    confirmButtonText: `<span class="bg-secondary-imed-60 text-neutral-white text-sm leading-6 text-center w-[120px] h-10">${confirmButtonText}</span>`,
    cancelButtonText: `<span class="text-secondary-imed-60 text-sm leading-6 w-[120px] h-10">${cancelButtonText}</span>`,
    reverseButtons: true,
    customClass: {
      container: "p-40",
      footer: "!p-4 !border-0 bg-neutral-10",
      popup: `${hasFooter && "!pb-0"}`,
      cancelButton: "!min-w-[120px]",
      confirmButton: "!min-w-[120px]",
    },
    showClass: {
      popup: "animate-fade-in",
      backdrop: "swal2-backdrop-show",
      icon: "swal2-icon-show",
    },
    hideClass: {
      popup: "animate-fade-out",
      backdrop: "swal2-backdrop-hide",
      icon: "swal2-icon-hide",
    },
  });

export const AlertaToast = ({
  element = "",
  action,
  position = "bottom-right" /* 'bottom-end', */,
  fullText = false,
  width = 360,
  // bgColor = "#70CF98",
  type = "success",
  timeAppearance = 3000,
  target = document.getElementById("root"),
}) => {
  const title = fullText ? action : `${element} ${action} con éxito`;
  let iconHtml = `<svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.6668 7.38674V8.00007C14.666 9.43769 14.2005 10.8365 13.3397 11.988C12.4789 13.1394 11.269 13.9817 9.8904 14.3893C8.51178 14.797 7.03834 14.748 5.68981 14.2498C4.34128 13.7516 3.18993 12.8308 2.40747 11.6248C1.62501 10.4188 1.25336 8.99212 1.34795 7.55762C1.44254 6.12312 1.9983 4.75762 2.93235 3.66479C3.8664 2.57195 5.12869 1.81033 6.53096 1.4935C7.93323 1.17668 9.40034 1.32163 10.7135 1.90674" stroke="#06B851" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M14.6667 2.6665L8 9.33984L6 7.33984" stroke="#06B851" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  `;
  // let background = '#70CF98';
  let progressBarColorClass = "!bg-success-dark";

  const typeIcon = (e) => {
    switch (e) {
      case "error":
        iconHtml = `<svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.00016 14.6668C11.6821 14.6668 14.6668 11.6821 14.6668 8.00016C14.6668 4.31826 11.6821 1.3335 8.00016 1.3335C4.31826 1.3335 1.3335 4.31826 1.3335 8.00016C1.3335 11.6821 4.31826 14.6668 8.00016 14.6668Z" stroke="#E83E3E" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 6L6 10" stroke="#E83E3E" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 6L10 10" stroke="#E83E3E" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        `;
        // background = '#FF7777';
        progressBarColorClass = "!bg-error-dark";
        break;

      case "info":
        iconHtml = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 29.3333C23.3638 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.63616 23.3638 2.66663 16 2.66663C8.63622 2.66663 2.66669 8.63616 2.66669 16C2.66669 23.3638 8.63622 29.3333 16 29.3333Z" stroke="#3183F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 21.3333V16" stroke="#3183F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 10.6666H16.0133" stroke="#3183F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg> 
          `;

        // background = '#3FB2FF';
        progressBarColorClass = "!bg-info-dark";
        break;

      case "warning":
        iconHtml = `
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.72 5.14645L2.42669 23.9998C2.19385 24.403 2.07065 24.8602 2.06935 25.3258C2.06804 25.7914 2.18868 26.2493 2.41926 26.6538C2.64984 27.0583 2.98233 27.3954 3.38364 27.6316C3.78495 27.8677 4.24109 27.9947 4.70669 27.9998H27.2934C27.759 27.9947 28.2151 27.8677 28.6164 27.6316C29.0177 27.3954 29.3502 27.0583 29.5808 26.6538C29.8114 26.2493 29.932 25.7914 29.9307 25.3258C29.9294 24.8602 29.8062 24.403 29.5734 23.9998L18.28 5.14645C18.0423 4.75459 17.7077 4.43061 17.3083 4.20576C16.9089 3.98092 16.4583 3.86279 16 3.86279C15.5417 3.86279 15.0911 3.98092 14.6918 4.20576C14.2924 4.43061 13.9577 4.75459 13.72 5.14645V5.14645Z" stroke="#D18E00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16 12V17.3333" stroke="#D18E00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16 22.6665H16.0133" stroke="#D18E00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `;
        // background = '#FFAD01';
        progressBarColorClass = "!bg-warning-dark";
        break;
      default:
        break;
    }
  };

  typeIcon(type);

  return Swal.mixin({
    toast: true,
    title,
    iconHtml,
    closeButtonHtml: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4L4 12" stroke="#A0AEC0" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 4L12 12" stroke="#A0AEC0" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    `,
    iconColor: "transparent",
    // background,
    position,
    showConfirmButton: false,
    showCloseButton: true,
    width,
    timer: timeAppearance,
    timerProgressBar: true,
    target,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    customClass: {
      title: `title-sweet-alert !text-neutral-70 !text-xs !font-normal ${
        title.length < 45 && "!top-2"
      }`,
      timerProgressBar: `${progressBarColorClass}`,
      popup: "!shadow-select !mt-20",
      closeButton: "!flex !items-start !justify-end",
    },
    showClass: {
      popup: "animate-fade-in-right",
      backdrop: "swal2-backdrop-show",
      icon: "swal2-icon-show",
    },
    hideClass: {
      popup: "animate-fade-out-right",
      backdrop: "swal2-backdrop-hide",
      icon: "swal2-icon-hide",
    },
  });
};

export const AlertaModalInformativa = ({
  title,
  text,
  width,
  icon = "warning",
}) =>
  Swal.mixin({
    icon,
    text,
    // html: `<p style="padding: 50px 0 35px 0 !important;"> ${
    //   fullText ? title : `¿Está seguro que desea <b>${title}</b>?`
    // }</p>`,
    title,
    width: width,
    showCloseButton: false,
    showCancelButton: false,
    confirmButtonColor: "#FF802E",
    confirmButtonText: `<span class="bg-secondary-imed-60 text-neutral-white text-sm leading-6 text-center w-[120px] h-10">Aceptar</span>`,
    customClass: {
      container: "p-40",
      confirmButton: "!min-w-[120px]",
    },
    showClass: {
      popup: "animate-fade-in",
      backdrop: "swal2-backdrop-show",
      icon: "swal2-icon-show",
    },
    hideClass: {
      popup: "animate-fade-out",
      backdrop: "swal2-backdrop-hide",
      icon: "swal2-icon-hide",
    },
  });
