import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { Container, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Page from "../components/Page";
import Permisos from "../utils/Permisos.js";
import Tabla from "src/components/tabla/Tabla";
import { AlertaModal, AlertaToast } from "../components/AlertaSwall.js";
import { Titulo } from "src/components/Titulo";
import BotonAgregar from "src/components/tabla/BotonAgregar.js";
import { db } from "../utils/firebaseInit.js";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
} from "firebase/firestore";
import DialogCrearProducto from "src/components/productos/dialog/DialogCrearProducto";
import DialogEditarProducto from "src/components/productos/dialog/DialogEditarProducto";

export default function Productos() {
  const [openCrear, setOpenCrear] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [idNewProducto, setIdNewProducto] = useState(0);
  const [selectProducto, setSelectProducto] = useState(null);

  const [productos, setProductos] = useState([]);
  const [isLoadingProductos, setIsLoadingProductos] = useState(false);

  useEffect(() => {
    setIsLoadingProductos(true);
    const q = query(collection(db, "productos"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      let idMayor = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data) {
          cities.push({
            id: doc.id,
            ...data,
          });

          if (idMayor < data.idProducto) {
            idMayor = data.idProducto;
          }
        }
      });
      setIdNewProducto(idMayor + 1);
      setProductos(cities);
      setIsLoadingProductos(false);
    });
    return () => unsubscribe();
  }, []);

  const handleViewUser = (row) => {
    console.log(row);
    setSelectProducto(row);
    setOpenEditar(true);
  };

  const eliminarUsuario = (idUsuario) => {
    AlertaModal({
      title: "eliminar producto",
      confirmButtonText: "Sí, eliminar",
    })
      .fire()
      .then(async (response) => {
        if (response.isConfirmed) {
          try {
            await deleteDoc(doc(db, "productos", idUsuario));
            AlertaToast({
              element: "producto",
              action: "eliminado",
            }).fire();
          } catch (error) {
            AlertaToast({
              action: "Error no determinado",
              fullText: true,
              type: "error",
            }).fire();
          }
        }
      });
  };

  return (
    <Page title="Padel Club Rengo | Productos">
      <Container>
        <Titulo>
          <Typography variant="h4">Productos</Typography>
        </Titulo>

        {!isLoadingProductos && productos ? (
          <Stack marginTop={5} spacing={2}>
            <Tabla
              encabezados={[
                {
                  id: "idProducto",
                  label: "ID",
                },
                {
                  id: "nombre",
                  label: "Nombre",
                },
                {
                  id: "valor",
                  label: "Valor",
                },
                {
                  id: "cantidad",
                  label: "Cantidad",
                },
                { id: "", label: "Acciones" },
              ]}
              buscarPor={["nombre"]}
              etiquetaBusqueda="Buscar productos"
              eliminarElemento={(e) => eliminarUsuario(e)}
              permisosEliminar={Permisos.eliminarProductos}
              editarElemento={(e) => {
                handleViewUser(e);
              }}
              permisosEditar={Permisos.editarProductos}
              data={productos}
              botones={[
                <BotonAgregar
                  onClick={() => setOpenCrear(true)}
                  permission={Permisos.agregarProductos}
                />,
              ]}
            />
          </Stack>
        ) : (
          <Box display="flex" justifyContent="center" mt={10}>
            {isLoadingProductos && (
              <Skeleton variant="rectangular" width="100%" height="500px" />
            )}
          </Box>
        )}
        {openCrear && (
          <DialogCrearProducto
            open={openCrear}
            setOpen={setOpenCrear}
            newID={idNewProducto}
          />
        )}
        {selectProducto && (
          <DialogEditarProducto
            open={openEditar}
            setOpen={setOpenEditar}
            producto={selectProducto}
            setProducto={setSelectProducto}
          />
        )}
      </Container>
    </Page>
  );
}
